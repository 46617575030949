.social-media-icons {
	ul {
		li {
			display: inline-block;
			margin-right: $dist/4;

			&:last-of-type {
				margin-right: 0;
			}

			a {
				font-size: $fs;
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border: 1px solid $fc;
				border-radius: 100%;
				transition: border-color $duration linear, color $duration linear;

				&:hover {
					border-color: $fc-light;
				}
			}
		}
	}
}
