.latest-news {
  & > h2 {
    margin-bottom: $dist/1.25;
    font-size: $fs-h2;
    text-transform: uppercase;
    line-height: 1;

    @include breakpoint(null, $bp-s) {
      margin-bottom: $dist/2;
    }
  }

  .latest-news__archive-link {
    background: $box-color;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -$dist *1.5;
    width: $dist;
    height: 300px;
    line-height: 300px;
    text-align: center;

    @include breakpoint($bp-l, $bp-xl) {
      height: 100%;
      line-height: 450px;
    }

    @include breakpoint(null, $bp-l) {
      height: auto;
      line-height: $line-height;
      position: static;
      float: left;
      width: 100%;
      text-align: right;
      padding: $dist/6;
      margin-top: $dist;
      font-size: $fs-h4;
    }
  }

  &__children {
    @extend %cf;
    position: relative;

    @include breakpoint($bp-l, 1900px) {
      width: calc(100% - (#{$dist} * 1.5));
    }
  }

  &__child {
    $grid-conf: (
                    (null, $bp-s, 1, 2),
                    ($bp-s, $bp-m, 2),
                    ($bp-m, null, 3)
    );

    @include resp-gallery-layout($grid-conf);

    &-image {
      position: relative;
      display: block;
      @include image-hover($fa-var-arrow-right);
      margin-bottom: 3px;
    }

    &-more {
      text-align: right;
      margin-top: $dist/1.33;

      @include breakpoint(null, $bp-m) {
        margin-top: $dist/2;
      }

      @include breakpoint(null, $bp-s) {
        text-align: left;
        margin-top: $dist/3;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      image-rendering: pixelated;
    }

    h3 {
      margin-top: $dist/1.33;
      margin-bottom: $dist/1.33;
      font-size: $fs-h3;
      text-transform: uppercase;

      @include breakpoint(null, $bp-m) {
        margin-top: $dist/3;
        margin-bottom: $dist/3;
      }
    }

    p {
      margin-top: -0.25em;
    }
  }
}
