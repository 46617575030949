#silverstripe_userdefinedform {
	.main {
		@extend %cf;

		&__text {
			&:not(.main__text--fullscreen) {
				float: right;
				width: span(6 of 12);
				margin-left: gutter(12);

				@include breakpoint(null, $bp-m) {
					width: 100%;
					margin-left: 0;
					margin-bottom: $dist;
				}

				@include breakpoint(null, $bp-xs) {
					margin-bottom: $dist/2;
				}
			}

			padding: $dist $dist*2;
			background: $box-color;

			@include breakpoint(null, $bp-xl) {
				padding-right: $dist;
			}
		}

		.userform {
			float: right;
			width: span(6 of 12);
			clear: none;
			margin-top: 0;

			@include breakpoint(null, $bp-m) {
				width: 100%;
				float: none;
			}
		}
	}
}
