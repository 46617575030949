.page-slider .homepage-teaser {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.homepage-teaser {
	position: relative;
	@include resp-margin(top,2);
	@include resp-margin(bottom,-2);

	@include breakpoint(null, $bp-m) {
		margin-bottom: $dist;
	}

	&__overlay {
		&--clickable{
			cursor: pointer;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&__content {
		position: absolute;
		left: $dist*2;
		bottom: $dist*3.5;
		color: #ffffff;
		z-index: 500;

		@include breakpoint(null, $bp-l) {
			left: $dist;
			bottom: $dist*2.5;
		}

		@include breakpoint(null, $bp-m) {
			position: static;
			text-align: left;
			margin: 0 $dist/4;
		}
	}

	&__labels {
		margin-bottom: $dist/4;

		@include breakpoint(null, $bp-m) {
			margin-bottom: 0;
		}

		.badge {
			&:last-of-type {
				background: $ac;
				margin-left: $dist/8;
			}
		}
	}

	&__title {
		font-size: $fs-h1;
		text-transform: uppercase;

		&--color-dark {
			span {
				color: $fc;
			}
		}

		&--color-light {
			span {
				color: #ffffff;
			}
		}

		span {
			font-weight: $fw-h-light;
			white-space: nowrap;

			@include breakpoint(null, $bp-m) {
				color: $fc;
				font-size: $fs-h2;
				display: block;
				margin-bottom: $dist/3;
			}

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h3;
				margin-top: $dist/8;
			}
		}
	}

	&__link {
		position: absolute;
		left: $dist*2;
		bottom: $dist*2;
		z-index: 500;

		@include breakpoint(null, $bp-l) {
			bottom: $dist;
			left: $dist;
		}

		@include breakpoint(null, $bp-m) {
			position: static;
			margin: $dist/3 $dist/4 0 $dist/4;
		}

		a {
			@extend %button;
		}
	}
}
