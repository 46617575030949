.cookie-confirm {
	position: fixed;
	z-index: 999999999999;
	background: #ffffff;
	width: 100%;
	max-width: calc(100% - #{$dist/2});
	@include resp-padding(v);
	@include resp-padding(h);
	box-shadow: 5px 5px 20px -10px #000000;
	right: $dist/4;
	bottom: $dist/4;
	max-height: 90%;
	overflow: auto;

	@include breakpoint(null, $bp-m) {
		max-height: 75% !important;
	}

	@include breakpoint($bp-m, null) {
		max-width: 50%;
		right: $dist/2;
		bottom: $dist/2;
	}

	@media screen and (max-height: 750px) {
		right: $dist/8;
		bottom: $dist/8;
		max-width: calc(100% - #{$dist/4});
		padding: $dist/4;
	}

	&__inner {

	}

	&__top {
		& > span {
			display: block;
			font-family: $ff-h;
			font-size: $fs-h3;
			font-weight: $fw-h-bold;
			margin-bottom: 1rem;
			line-height: 1;

			@media screen and (max-height: 750px){
				font-size: $fs;
			}
		}

		& > p {
			line-height: $line-height;

			@media screen and (max-height: 750px){
				display: none !important;
			}
		}

		& > b {
			display: block;
			margin-top: $dist/2;

			& + p {
				margin-top: 0.5em;
			}
		}
	}

	&__bottom {
		margin-top: 1rem;
	}

	&__checkboxes {
		position: relative;

		& > * {
			position: relative;
			font-size: $fs;
			display: block;
			margin-top: 1rem;

			@media screen and (max-height: 750px) {
				margin-top: 0.5rem;
			}

			&:first-of-type {
				margin-top: 0 !important;
			}

			span {
				display: block;
				font-size: $fs-small;
				margin-top: 0.5em;
				color: #acacac;
				padding-left: 2em;
			}
		}
	}

	#cookie-confirm__submit,
	#cookie-confirm__cancel {
		@extend %button;
		margin-top: 1rem;

		@include breakpoint($bp-m, null) {
			margin-top: 1.25rem;
		}
	}

	#cookie-confirm__cancel {
		@extend %button--neutral;
	}
}

#cookie-revoke {
	width: 40px;
	height: 40px;
	line-height: 20px;
	text-align: center;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 99999999;
	padding: 10px;
	background: #ffffff;
	-webkit-box-shadow: 0px 0px 20px -10px #000000;
	box-shadow: 0px 0px 20px -10px #000000;
	cursor: pointer;
}

#cookie-revoke:hover {
	color: $ac;
}