.badge {
	font-size: $fs-small;
	text-transform: uppercase;
	font-weight: $fw-bold;
	padding: 0.125em 0.35em;
	color: #ffffff;
	background: $fc;
	display: inline-block;

	&--absolute {
		position: absolute;
		top: $dist/4;
		right: 1px;
		z-index: 1;
	}

	&--new {
		background: $ac;
	}
}
