.navigation.navigation--main {
	@include resp-margin(bottom);

	.navigation__top-link {
		font-size: $fs-h3;
		font-weight: $fw-bold;
	}

	&.navigation--horizontal {
		.navigation__top-link {
			&:hover {
				& > .navigation__sub {
					top: $fs-h3;
				}
			}
		}
	}
}
