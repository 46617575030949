html .basic-holder {
  .basic-holder__text {
    & + .basic-holder__children {
      margin-top: $dist/2;
      margin-top: calc(#{$dist/2} + 0.25em);
    }
  }

  &__children {
    @extend %cf;
  }

  &__child {
    $grid-conf: (
        (null, $bp-s, 1),
        ($bp-s, $bp-l, 2),
        ($bp-l, null, 3)
    );

    @include resp-gallery-layout($grid-conf);

    &:not(.basic-holder__child--no-link) {
      .basic-holder__child-preview-image a {
        @include image-hover($fa-var-arrow-right)
      }
    }

    &--no-link {
      div a,
      h3 a {
        cursor: default;

        .fa-arrow-right {
          display: none;
        }

        &:hover {
          color: $fc !important;
        }
      }
    }

    &-preview-image {
      border: 1px solid $border-color;
      margin-bottom: $dist/2;

      @include breakpoint(null, $bp-m) {
        margin-bottom: $dist/4;
      }

      img {
        //@include resp-img;
      }
    }

    & > h3 {
      display: block;
      @include resp-margin(bottom, 0.5);
      font-size: $fs-h3;

      @include breakpoint(null, $bp-xs) {
        font-size: $fs-h6;
      }

      a {
        color: $fc;
        text-transform: uppercase;
        transition: color $duration linear;
        font-weight: $fw-medium;

        &:hover {
          color: $ac;
        }

        i {
          color: $ac;
        }
      }
    }

    & > p {
      margin: 0;
    }
  }
}
