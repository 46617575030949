.page-title {
	margin-bottom: $dist * 1.25;
	@extend %cf;

	@include breakpoint(null, $bp-m) {
		margin-bottom: $dist/1.25;
	}

	@include breakpoint(null, $bp-s) {
		margin-bottom: $dist/2;
	}

	h1 {
		line-height: 1;
		font-size: $fs-h2;
		font-weight: $fw-h-light;
		text-transform: uppercase;
		margin-top: -0.25em;
		float: left;

		@include breakpoint(null, $bp-s) {
			float: none;
		}
	}

	.badge {
		margin-bottom: $dist/2;

		@include breakpoint(null, $bp-s) {
			margin-bottom: $dist/3;
		}

		& + h1 {
			margin-top: 0;
		}
	}

	.breadcrumbs {
		margin-bottom: $dist;
		font-size: $fs-small;

		@include breakpoint(null, $bp-m) {
			margin-bottom: $dist/2;
		}

		a {
			transition: color $duration linear;
			margin-right: $dist/1.33;
			white-space: nowrap;

			&:before {
				@include fontawesome($fa-var-arrow-left, $style: light);
				color: $ac;
				font-size: ($fs-smaller-int - 2) / $fs-root-int + rem;
				position: relative;
				top: -1px;
			}

			&:first-of-type {
				&:before {
					@include fontawesome($fa-var-arrow-to-left, $style: light);
					font-size: $fs-smaller;
				}
			}
		}

		strong {
			white-space: nowrap;
		}
	}
}
