@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

// - Offcanvas navigation
.mobile-navigation {
  position: fixed;
  overflow-y: scroll;
  z-index: 9998;
  top: 0;
  bottom: 0;
  width: $mobilenav-width;
  transition: left 0.17s linear;
  left: -$mobilenav-width;
  background: $box-color-dark;
  padding: $dist/1.33;
  padding-right: $dist/2.66;

  &--active,
  &--force-active {
    left: 0;
    margin-left: 0;

    & + .mobile-navigation-pusher {
      transform: translateX($mobilenav-width);

      .mobile-navigation-button i:before {
        content: fa-content($fa-var-times);
      }
    }
  }

  &:not(.mobile-navigation--active) {
    left: -$mobilenav-width !important;
  }

  &__top {
    margin-bottom: $dist;

    a {
      color: #ffffff;

      &:hover {
        color: $ac;
      }
    }

    .shop-account-button {
      margin-bottom: 0.25em;
    }
  }
}

.mobile-navigation-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  opacity: 0;
  visibility: hidden;
  background: #000000;
  transition: all $duration ease;

  //&--active {
  //  opacity: 0.8;
  //  visibility: visible;
  //}
  //
  //&--force-active {
  //  opacity: 0.8;
  //  visibility: visible;
  //}
}

// - mobile nav button
.mobile-navigation-button {
  cursor: pointer;
  font-size: $fs-h2;
  color: $fc;
  transition: color $duration linear;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  float: left;

  &:hover {
    color: $ac;
  }
}

.mobile-navigation-pusher {
  transition: transform 0.15s linear;
}

html.mobile-navigation-active {
  overflow-x: hidden;

  .mobile-navigation-pusher {
    transition: transform 0.2s linear;
  }

  .mobile-navigation {
    transition: all 0.15s linear;
  }
}

.mobile-navigation::-webkit-scrollbar {
  display: none;
}

.mobile-navigation {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  scrollbar-width: none;

  .navigation--mobile {
    width: 100%;
  }

  &__bottom {
    width: 100%;
    margin-top: auto;
  }

  &__bottom-mobile {
    @extend %cf;
    margin-top: $dist/1.33;
    display: none;

    @include breakpoint(null, $bp-m) {
      display: block;
    }

    .social-media-icons {
      float: left;

      ul {
        li {
          a {
            color: $fc-light;
            border-color: $fc-light;

            &:hover {
              color: $ac;
              border-color: $ac;
            }
          }
        }
      }
    }

    .language-switcher {
      float: right;
      padding-right: ($dist/1.33)/2;

      ul {
        li {
          a {
            color: #ffffff;

            &:hover {
              color: $ac;
            }
          }
        }
      }
    }
  }
}

.mobile-navigation-scrollbarhider {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  transition: all $duration linear;
}

@media (max-resolution: 1dppx) {
  html.mobile-navigation-active {
    .mobile-navigation-scrollbarhider {
      content: '';
      display: block;
      top: -10px;
      bottom: -10px;
      left: $mobilenav-width - 17px;
      width: 17px;
      background: $box-color-dark;
      z-index: 9999;
      opacity: 1;
      visibility: visible;
    }
  }
}
