.product-page {
	&__top {
		@extend %cf;
	}

	&__variant-imgs {
		display: none !important;
		opacity: 0 !important;
		visibility: hidden !important;
	}

	&__bottom {
		@include resp-margin(top);
	}

	&__left {
		float: left;

		a {
			@include image-hover();
			display: block;

			img {
				@include resp-img();
			}
		}

		& > a {
			border: 1px solid $border-color;
		}

		ul {
			@extend %cf;

			li {
				border: 1px solid $border-color;
				display: block;
				float: left;
				margin-top: $dist/2;

				&:nth-of-type(4n+4) {
					margin-right: 0 !important;
					float: right;
				}
			}
		}
	}

	&__right {
		float: right;
	}

	&__price {
		.product-price {
			&__value {
				font-size: $fs-h3;
				font-weight: $fw-medium;
			}

			&__explanation {
				font-style: italic;
				font-size: $fs-small;
				color: $fc-light;
			}
		}
	}

	&__vat {
		font-style: italic;
		font-size: $fs-small;
		color: $fc-light;
	}

	.shop-product-instock {
		@include resp-margin(v);

		&--in span {
			background: $valid-color;
		}

		&--soon span {
			background: $warning-color;
		}

		&--out span {
			background: $invalid-color;
		}
	}

	.shop-tabs {
		margin-top: $dist;
	}
}

body.has-sidebar {
	@include breakpoint($bp-l, null) {
		.product-page__left {
			width: span(3 of 9);
			margin-right: gutter(9);

			ul {
				li {
					width: span(1 of 3) + gutter(3) / 3;
					margin-right: gutter(3) / 2;
				}
			}
		}

		.product-page__right {
			width: span(6 of 9);
		}
	}

	@include breakpoint(null, $bp-l) {
		.product-page__left {
			width: span(6 of 12);
			margin-right: gutter(12);

			ul {
				li {
					width: span(2 of 6) + gutter(6) / 3;
					margin-right: gutter(6) / 2;
				}
			}
		}

		.product-page__right {
			width: span(6 of 12);
		}
	}
}

body:not(.has-sidebar) {
	.product-page__left {
		width: span(4 of 12);
		margin-right: gutter(12);

		ul {
			li {
				width: span(1 of 4) + (gutter(8) * 3 / 4);
				margin-right: gutter(8);
			}
		}
	}

	.product-page__right {
		width: span(8 of 12);
	}

	@include breakpoint(null, $bp-m) {
		.product-page__left {
			width: 100%;
		}

		.product-page__right {
			width: 100%;
			margin-top: $dist;
		}
	}
}