.page--3 {
	.main__text {
		padding-left: $dist*3 !important;

		@include breakpoint(null, $bp-xl) {
			padding-left: $dist*2 !important;;
		}

		@include breakpoint(null, $bp-s) {
			padding-left: $dist !important;
		}

		p {
			overflow: visible;
		}

		.text--big {
			position: relative;

			@include breakpoint(null, $bp-s) {
				font-size: $fs-h3;
				font-weight: $fw;
			}

			&:before {
				@include fontawesome($fa-var-map-marker-alt);
				position: absolute;
				top: 0.125em;
				left: -$dist;
				transform: translateX(-100%);
				width: auto !important;

				@include breakpoint(null, $bp-xl) {
					left: -$dist/2;
				}

				@include breakpoint(null, $bp-s) {
					position: static;
					transform: none;
					margin-right: $dist/4;
				}
			}
		}
	}
}
