.login-page {
	@extend %cf;

	h3 {
		font-size: $fs-h3;
		font-family: $ff-h;
		font-weight: $fw-h;
		margin-bottom: $dist;
	}

	form {
		width: 100% !important;
	}

	&__login {
		float: left;
		width: span(4 of 12);
		margin-right: gutter(12);
	}

	&__registration {
		float: left;
		width: span(8 of 12);
	}
}