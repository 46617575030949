.navigation {
	position: relative;
	z-index: 500;

	// - default
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			line-height: 1;
			font-size: $fs;

			&[class*="--current"],
			&[class*="--section"] {
				& > a {
					color: $ac;
				}
			}

			a {
				display: block;
				color: $fc;
				white-space: nowrap;
				text-decoration: none;
				line-height: 1;
				transition: color $duration linear;

				&:hover {
					color: $ac;
				}
			}
		}
	}

	.navigation__sub {
		display: block;
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.1s linear, visibility 0.1s linear;
	}

	// - vertical navigation
	&--vertical {
		.navigation__sub {
			transform: translateX(100%);
			top: 0;
			right: 0;
		}

		ul {
			li {
				&:hover {
					& > .navigation__sub {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	// - horizontal navigation
	&--horizontal {
		.navigation__top {
			font-size: 0;
		}

		.navigation__top {
			display: flex;
			justify-content: space-between;
		}

		.navigation__top-link {
			&:hover {
				& > .navigation__sub {
					opacity: 1;
					visibility: visible;
					top: $fs;
				}
			}

			& > .navigation__sub {
				left: 0;
			}

			.navigation__sub {
				min-width: 100%;

				.navigation__sub {
					transform: translateX(100%);
					top: 0;
					right: 0;
				}

				li {
					&:hover {
						& > .navigation__sub {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			&:nth-last-of-type(-n+2) {
				& > .navigation__sub {
					left: auto;
					right: 0;

					.navigation__sub {
						transform: translateX(-100%);
						right: auto;
						left: 0;
					}
				}
			}
		}
	}
}
