.shop-account-button {
  nav {
    display: none !important;
  }

  &,
  & + a {
    i {
      margin-right: 0.5em;
    }
  }
}