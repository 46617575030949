$sidebarWidthInPx: 270px;

.has-sidebar {
	.outer-wrapper {
		@extend %cf;
	}

	.main {
		float: left;
		width: span(9 of 12)
	}

	.sidebar {
		@extend %cf;
		float: right;
		margin-left: gutter(12);
		width: span(3 of 12);
	}

	@include breakpoint($bp-l, null) {
		.sidebar + * {
			padding-top: $dist;
			clear: both;
		}
	}

	@include breakpoint(null, $bp-l) {
		.main,
		.sidebar {
			float: none;
			width: 100%;
		}

		.sidebar {
			margin-left: 0;
			@include resp-margin(top);
		}
	}

	@include breakpoint($bp-m - $dist*4, $bp-l) {
		.s-widget {
			width: span(6 of 12);
			float: left;

			&:nth-of-type(2n+2) {
				margin-left: gutter(12);
			}

			&:nth-of-type(2n+3) {
				clear: both;
			}
		}
	}
}

.s-widget {
	margin-bottom: $dist;

	&:last-of-type {
		margin-bottom: 0;
	}

	&__inner {
		font-size: 1rem;
	}

	&__title {
		strong {
			font-size: $fs;
			font-family: $ff-h;
			font-weight: $fw-h-bold;
		}
	}

	&__nav {
		ul {
			li {
				padding-left: 1.25em;

				&:before {
					@include fontawesome($fa-var-angle-right);
					margin-left: -1.25em;
					color: $fc-light;
				}

				a {
					color: $fc;
					text-decoration: none;

					&:hover {
						color: $ac;
					}
				}
			}
		}
	}

	&__text {
		.global-sc-dist-right + a {
			color: $fc;
			text-decoration: none;

			&:hover {
				color: $ac;
			}
		}
	}

	&__images {
		li {
			margin-bottom: $dist;

			img {
				@include resp-img;
				max-width: $sidebarWidthInPx;
				margin: 0 auto;
			}

			a {
				display: block;
				max-width: $sidebarWidthInPx;
				margin: 0 auto;
			}
		}
	}
}