#grasenhiller_accountpage {
	#Form_ProfileEditForm {
		margin-bottom: $dist;
	}

	.account {
		& > h3,
		&__addresses > h3 {
			font-size: $fs-h3;
			font-family: $ff-h;
			font-weight: $fw-h-bold;
			margin-bottom: $dist/2;
		}

		&--profile {

		}

		&--addresses {

		}

		&__addresses {
			margin-bottom: $dist;

			.address {
				margin-bottom: $dist/2;
				padding-bottom: $dist/2;
				border-bottom: 1px dashed $border-color;

				&:last-of-type {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}

		&--orders {

		}
	}
}