.masonry {
	@extend %cf;
	border-top: 1px solid $border-color;
	padding-top: $dist*1.5;

	@include breakpoint(null, $bp-m) {
		padding-top: $dist/1.25;
	}

	@include breakpoint(null, $bp-s) {
		padding-top: $dist/2;
	}

	li {
		display: block;
		float: left;
		width: span(4 of 12);

		a {
			@include image-hover();
			display: block;
		}

		img {
			@include resp-img;
		}

		&:nth-of-type(10n+1) {
			width: span(8 of 12);
			margin-bottom: $dist;
			margin-right: gutter(12);
		}

		&:nth-of-type(10n+3) {
			margin-bottom: $dist;
		}

		&:nth-of-type(10n+7) {
			width: span(8 of 12);
			margin-bottom: $dist;
		}

		&:nth-of-type(10n+5) {
			margin-top: -17.9%;
		}

		&:nth-of-type(10n+8) {
			margin-top: -17.9%;
			margin-right: gutter(12);
			margin-bottom: $dist;
		}

		&:nth-of-type(10n+3),
		&:nth-of-type(10n+6) {
			clear: both;
			margin-right: gutter(12);
		}

		&:nth-of-type(10n+4),
		&:nth-of-type(10n+9) {
			margin-right: gutter(12);
		}




		@include breakpoint(900px, 1340px) {
			&:nth-of-type(10n+1),
			&:nth-of-type(10n+3),
			&:nth-of-type(10n+7),
			&:nth-of-type(10n+8) {
				margin-bottom: $dist / 1.33;
			}
		}

		@include breakpoint(560px, 900px) {
			&:nth-of-type(10n+1),
			&:nth-of-type(10n+3),
			&:nth-of-type(10n+7),
			&:nth-of-type(10n+8) {
				margin-bottom: $dist / 2;
			}
		}

		@include breakpoint(null, 560px) {
			&:nth-of-type(10n+1),
			&:nth-of-type(10n+6),
			&:nth-of-type(10n+3),
			&:nth-of-type(10n+7),
			&:nth-of-type(10n+8),
			&:nth-of-type(10n+2),
			&:nth-of-type(10n+5),
			&:nth-of-type(10n+9),
			&:nth-of-type(10n+10) {
				margin-bottom: $dist / 4;
			}

			&:nth-of-type(10n+3),
			&:nth-of-type(10n+4),
			&:nth-of-type(10n+9),
			&:nth-of-type(10n+10) {
				width: span(6 of 12);
			}

			&:nth-of-type(10n+4) {
				margin-right: 0;
			}

			&:nth-of-type(10n+5),
			&:nth-of-type(10n+8) {
				margin-top: 0;
			}

			&:nth-of-type(10n+1),
			&:nth-of-type(10n+5),
			&:nth-of-type(10n+2),
			&:nth-of-type(10n+7),
			&:nth-of-type(10n+6),
			&:nth-of-type(10n+8) {
				width: 100%;
			}
		}
	}
}
