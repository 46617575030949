.order-summary {
	h3 {
		display: block;
		font-size: $fs-h3;
		font-family: $ff-h;
		font-weight: $fw-h-bold;
		margin-bottom: $dist/2;
	}

	&__status {
		margin-bottom: $dist;
		padding-bottom: $dist;
		border-bottom: 1px solid $border-color;

		ul {
			@extend %cf;
			@include resp-padding(v);
			@include resp-padding(h);
			background: $box-color;

			li {
				float: left;
				width: span(6 of 12);
				margin-right: gutter(12);
				margin-top: $dist/4;

				&:nth-of-type(2n+2) {
					margin-right: 0;
				}

				&:nth-of-type(-n+2) {
					margin-top: 0;
				}

				i {
					margin-right: 0.5em;
				}

				span {
					&:first-of-type {
						font-weight: $fw-medium;
					}
				}
			}
		}
	}

	&__addresses,
	&__payment-shipping {
		@extend %cf;
		margin-bottom: $dist;
		padding-bottom: $dist;
		border-bottom: 1px solid $border-color;

		& > * {
			float: left;
			width: span(6 of 12);

			&:first-of-type:not(:last-of-type) {
				margin-right: gutter(12);
			}
		}
	}

	&__invoice-address--same-as-shipping {
		width: 100%;
	}

	&__paypal-link {
		display: inline-block;
		background-image: linear-gradient(100deg, rgb(0, 112, 186), rgb(0, 48, 135));
		font-size: $fs-small;
		font-weight: $fw-medium;
		border-radius: $radius;
		padding: $dist/6 $dist/4;
		color: #FFFFFF;
		line-height: 1;

		&:before {
			@include fontawesome($fa-var-paypal, brands);
			margin-right: 0.25em;
		}

		&:hover {
			background-image: linear-gradient(10deg, rgb(0, 112, 186), rgb(0, 48, 135));
			color: #FFFFFF;
		}
	}

	&__member-note {
		margin-bottom: $dist;
		padding-bottom: $dist;
		border-bottom: 1px solid $border-color;
	}

	&__coupon-code {
		color: $fc-light;
	}

	&__line-items {
		margin-bottom: $dist;

		$widthPrice: 125px;
		$widthQuantity: 50px;
		$widthSum: 125px;
		$widthTitle: calc(100% - #{$widthPrice + $widthQuantity + $widthSum + $dist*1.5});

		& > ul {
			@extend %cf;

			&:first-of-type {
				margin-bottom: $dist/2;
				padding-bottom: $dist/2;
				border-bottom: 2px solid $border-color;

				li {
					display: block;
					float: left;
					font-weight: $fw-bold;
					margin-right: $dist/2;

					&:first-of-type {
						width: $widthTitle;
					}

					&:nth-of-type(2) {
						width: $widthPrice;
					}

					&:nth-of-type(3) {
						width: $widthQuantity;
					}

					&:nth-of-type(4) {
						width: $widthSum;
						margin-right: 0;
						text-align: right;
					}
				}
			}

			&:last-of-type {
				margin-top: $dist/2;
				padding-top: $dist/2;
				border-top: 2px solid $border-color;

				li {
					@extend %cf;

					& > strong {
						display: block;
						float: left;
					}

					& > span {
						display: block;
						float: right;

						& > span {
							display: block;
							text-align: right;
							@extend  %cf;

							& > span {
								display: block;
								float: left;
								text-align: right;

								&:first-of-type {
									width: 5em;
									margin-right: $dist/4;
								}

								&:last-of-type {
									width: $widthSum;
								}
							}
						}
					}

					&:nth-last-of-type(2) {
						&,
						strong {
							font-weight: $fw-bold;
						}
					}

					&:last-of-type {
						color: $fc-light;
						font-size: $fs-small;
					}
				}
			}
		}

		.line-item {
			@extend %cf;
			margin-bottom: $dist/4;
			padding-bottom: $dist/4;
			border-bottom: 1px dashed $border-color;

			&:nth-last-of-type(2) {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}

			li {
				display: block;
				margin-right: $dist/2;
				float: left;

				&:first-of-type {
					width: $widthTitle;
					font-weight: $fw-medium;
				}

				&:nth-of-type(2) {
					width: $widthPrice;
				}

				&:nth-of-type(3) {
					width: $widthQuantity;
				}

				&:nth-of-type(4) {
					width: $widthSum;
					margin-right: 0;
					text-align: right;
				}
			}
		}
	}
}