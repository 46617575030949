.placed-orders {
	$widthItems: 100px;
	$widthTotal: 100px;
	$widthPayed: 50px;
	$widthDispatched: 50px;
	$widthDate: 150px + $dist/2;
	$widthTitle: calc(100% - #{$widthItems + $widthTotal + $widthPayed + $widthDispatched + $widthDate + $dist*2.5});

	& > ul {
		@extend %cf;

		&:first-of-type {
			margin-bottom: $dist/2;
			padding-bottom: $dist/2;
			border-bottom: 2px solid $border-color;

			li {
				font-weight: $fw-bold;
			}
		}

		&:not(:first-of-type) {
			margin-bottom: $dist/4;
			padding-bottom: $dist/4;
			border-bottom: 1px dashed $border-color;
		}

		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		li {
			display: block;
			float: left;
			margin-right: $dist/2;
			text-align: right;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&__nr {
		width: $widthTitle;
		text-align: left !important;
	}

	&__items {
		width: $widthItems;
		text-align: center !important;
	}

	&__total {
		width: $widthTotal;
	}

	&__date {
		width: $widthDate;
		padding-right: $dist/2;
	}

	&__payed {
		width: $widthPayed;
		text-align: center !important;

		&--unpaid {
			color: $invalid-color;
		}
	}

	&__dispatched {
		width: $widthDispatched;
		text-align: center !important;
		margin-right: 0;
	}
}