#app_carpage {
	.request-button {
		color: $ac;
		border-color: $ac;
		float: right;
		margin-top: -$dist/1.75;

		&:hover {
			color: $ac-hover;
			border-color: $ac-hover;
		}

		@include breakpoint(null, $bp-s) {
			float: none;
			margin-top: $dist/2;
		}
	}

	.main__text {
		p {
			column-count: 2;
			column-gap: $dist;

			@include breakpoint(null, $bp-s) {
				column-count: 1;
				column-gap: 0;
			}
		}
	}
}
