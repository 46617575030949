.news-preview {
  @extend %cf;
  @include resp-margin(bottom);

  &:last-of-type {
    margin-bottom: 0;
  }

  @include breakpoint($bp-m, null) {
    &__image {
      width: span(4 of 12);
      float: left;
      margin-right: gutter(12);
    }

    &__text {
      width: span(8 of 12 last);
      float: left;
    }
  }

  @include breakpoint(null, $bp-m) {
    &__image {
      margin-bottom: $dist/2;
    }
  }

  &__image {
    a {
      display: block;

      &:hover {
        text-decoration: none;
        border-bottom: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__text {
    & > h2 {
      margin-bottom: 0;

      a {
        color: $fc;

        &:hover {
          color: $ac;
        }
      }
    }

    & > small {
      display: block;
      @include resp-margin(bottom, 0.5);
    }

    & > p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    & > a {
      display: inline-block;
      @include resp-margin(top, 0.5);
    }
  }
}