.product-price {
	span {
		display: inline-block;
	}

	&__old {
		color: $fc-light;
		text-decoration: line-through;
		margin-right: $dist/4;
	}
}

.basic-holder__child--app_productpage {
	position: relative;

	.product-soldout {
		position: absolute;
		top: $dist/4;
		right: 1px;
		z-index: 1;
		font-size: $fs-small;
		text-transform: uppercase;
		font-weight: $fw-bold;
		padding: 0.125em 0.35em;
		color: #ffffff;
		background: $fc;
	}
}
