html .basic-holder__children {
	.basic-holder__child--app_productpage,
	.basic-holder__child--app_carpage,
	.basic-holder__child--grasenhiller_basicholder {
		& > h3 {
			margin-bottom: 0;
		}
	}
}

.basic-holder__child {
	position: relative;
}

.basic-holder__grand-children {
	margin-top: $dist/4;

	li i {
		color: $ac;
	}
}

.basic-holder__child-preview-image {
	img {
		@include resp-img;
		image-rendering: pixelated;
	}

	a {
		& > span {
			display: block;
			@include aspect-ratio(558,299);

			& > span {
				display: block;
				color: $fc-light;
			}
		}
	}
}

html .basic-holder__child-preview-image {
	a {
		& > span {
			& > span:first-of-type {
				&:after,
				&:before {
					display: none;
				}

				@include center(f);
				width: auto !important;
				height: auto !important;
				top: 50% !important;
				left: 50% !important;
			}
		}
	}
}
