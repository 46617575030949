.two-fields-in-line {
	@extend %cf;

	& > .middleColumn {
		width: 100% !important;
	}

	& > .fieldgroup > .fieldgroup-field {
		float: left;
		width: calc(50% - #{$dist/4});
		margin-top: $dist;

		&:nth-of-type(-n+2) {
			margin-top: 0;
		}

		&:nth-of-type(2n+2) {
			margin-left: $dist/2;

			.fieldholder-small {
				label {
					width: 100px;
				}

				input {
					width: calc(100% - 100px);
				}
			}
		}

		&:nth-of-type(2n+3) {
			clear: both;
		}

		.fieldholder-small {
			@extend %cf;

			label {
				float: left;
				width: 150px;
				font-weight: $fw;
				padding: $dist/4 0;
				line-height: $line-height;
			}

			input {
				float: left;
				width: calc(100% - 150px);
			}
		}
	}
}

.fieldgroup {
	.fieldholder-small-label {
		display: block;
		font-weight: $fw-bold;
		margin-bottom: $dist/2;
	}
}

#Form_UpdateCartForm {
	.middleColumn {
		width: 100%;
	}

	.field.checkbox {
		padding-left: 0;
	}
}

#Form_RegistrationForm_Password_Holder {
	& > .middleColumn {
		width: 100%;
	}
}

#Form_OrderSummaryForm {
	.field > label.left,
	.field > legend.left {
		width: 180px;
	}

	.middleColumn {
		width: calc(100% - 180px);
	}

	.description {
		text-align: right;

		.button {
			margin-top: 0.5rem;
			font-weight: $fw-bold;
		}
	}
}

#MemberLoginForm_LoginForm_Remember_Holder,
#Form_CheckoutForm_AcceptDataProcessing_Holder {
	&,
	label {
		padding-left: 0;
	}
}

#Form_CheckoutForm_AcceptDataProcessing_Holder {
	label {
		padding-left: 1.5em;
	}
}