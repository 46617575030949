.footer {
	margin-top: $dist;
	background: $box-color-dark;

	&__top {
		background: $box-color;
		padding-bottom: $dist/1.33;

		@include breakpoint(null, $bp-s) {
			padding-bottom: $dist/4;
		}
	}

	&__bottom {
		padding: $dist/4 0;
		color: #ffffff;
		text-align: right;
	}

	&__cars {
		@extend %cf;

		* {
			font-size: $fs-small;
		}

		& > ul {
			$grid-conf: (
				(null, $bp-s, 1),
				($bp-s, $bp-m, 2),
				($bp-m, $bp-l, 4),
				($bp-l, null, 6)
			);

			@include resp-gallery-layout($grid-conf);

			& > li {
				margin-bottom: $dist/2;
				margin-top: -0.25em;

				&:first-of-type {
					margin-top: 0;

					span {
						display: inline-block;
						color: #ffffff;
						text-transform: uppercase;
						font-weight: $fw-bold;
						background: $fc;
						padding: 0 0.25em;

						a {
							color: #FFFFFF;

							&:hover {
								color: $ac;
							}
						}
					}
				}

				li {
					font-size: ($fs-small-int + 0.5) / $fs-root-int + rem;

					a {
						font-size: ($fs-small-int + 0.5) / $fs-root-int + rem;
					}
				}
			}
		}

		& + strong {
			display: block;
			text-align: right;
			text-transform: uppercase;

			@include breakpoint(null, $bp-s) {
				text-align: left;
				margin-top: $dist/2;
			}
		}
	}
}
