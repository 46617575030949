.video {
	background: #000000;

	i.fa-youtube {
		font-size: $fs-h1;
		color: #ffffff;
		transition: $duration;
		@include center(f);
	}

	&:hover {
		i.fa-youtube {
			color: $ac;
		}
	}

	video {
		width: 100%;
		height: auto;
		max-height: 850px;

		@media
		(-webkit-min-device-pixel-ratio: 2),
		(min-resolution: 192dpi) {
			max-height: 100%;
		}
	}
}
