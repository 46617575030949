.navigation.navigation--mobile {
	ul {
		li {
			@extend %cf;
			margin-bottom: $dist/1.33;

			&:hover {
				& > .navigation__sub {
					display: none;
					transform: translateX(0);
				}
			}

			a {
				color: #ffffff;
				text-transform: uppercase;
				transition: color $duration linear;
			}
		}
	}

	.navigation__sub {
		clear: both;
		position: static !important;
		display: none;
		opacity: 1 !important;
		visibility: visible !important;
		transform: none !important;
		padding-top: $dist/1.33;
		padding-left: $dist/1.33;

		&--active {
			display: block !important;
		}

		.navigation__sub-link {
			@extend %cf;

			&--has-sub {
				& > a {
					float: left;
					width: calc(100% - 1em - #{$dist/2});
				}

				& > .navigation__sub-toggle {
					padding: $dist/4;
					width: calc(1em + #{$dist/2});
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.navigation__sub-toggle {
		display: block;
		float: left;
		cursor: pointer;
		color: $fc-light;
		line-height: 1;
		text-align: right;
		transition: color $duration linear;

		&:hover {
			color: $ac;
		}

		&--active {
			i:before {
				content: fa-content($fa-var-minus);
			}
		}
	}

	// - mobile navigation styling | level 1
	.navigation__top-link {
		& > a {
			position: relative;
			font-size: $fs-h4;
		}

		&--current,
		&--section {
			& > a {
				&:before {
					position: absolute;
					top: 50%;
					left: -$dist/1.33;
					display: block;
					content: '';
					margin-top: -1px;
					width: $dist/2;
					height: 2px;
					background: $ac;
				}
			}
		}

		&--has-sub {
			& > a {
				float: left;
				width: calc(100% - 1em - #{$dist/2});
			}
		}

		& > .navigation__sub-toggle {
			width: calc(1em + #{$dist/2});
		}

		// - level 2
		& > .navigation__sub {
			a {
				font-size: $fs;
				font-weight: $fw-bold;
			}

			& > li {
				// - level 3
				& > .navigation__sub {
					& > li {
						& > a { }

						& > .navigation__sub-toggle { }

						// - level 4
						& > .navigation__sub {
							& > li {
								& > a { }
							}
						}
					}
				}
			}
		}
	}
}

.navigation.navigation--mobile-footer {
	ul {
		li {
			margin-bottom: ($dist/1.33)/2;

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				color: $fc-light;
			}
		}
	}
}
