.shop-product {
	& > a {
		border: 1px solid $border-color;
		position: relative;
		display: block;
		overflow: hidden;
		text-decoration: none !important;

		&:not([href="javascript:void(0);"]) {
			@include image-hover($fa-var-long-arrow-right);
		}

		&[href="javascript:void(0);"] {
			cursor: default;
		}

		img {
			@include resp-img;
		}
	}

	& > h3 {
		display: block;
		margin-top: $dist/2;
		font-size: $fs-h3;

		@include breakpoint(null, $bp-xs) {
			font-size: $fs-h6;
		}

		a {
			color: $fc;
			text-transform: uppercase;
			transition: color $duration linear;
			font-weight: $fw-medium;

			&:hover {
				color: $ac;
			}

			i {
				color: $ac;
			}

			&[href="javascript:void(0);"] {
				cursor: default;

				&:hover {
					color: $fc;
				}
			}
		}
	}

	.shop-product-instock {
		position: absolute;
		right: $dist/4;
		bottom: $dist/4;
		z-index: 5;
	}
}