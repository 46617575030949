.shop-products {
	@extend %cf;

	&__headline {
		font-family: $ff-h;
		font-size: $fs-h2;
		font-weight: $fw-h-bold;
		color: $hc;
		@include resp-margin(bottom);
	}
}

body:not(.has-sidebar) {
	.shop-products {
		.shop-product {
			$gridConf: (
				(null, $bp-xs, 1),
				($bp-xs, $bp-m, 3),
				($bp-m, null, 3)
			);

			@include resp-gallery-layout($gridConf);
		}
	}
}

body.has-sidebar {
	.shop-products {
		.shop-product {
			$gridConf: (
				(null, $bp-xs, 1),
				($bp-xs, $bp-m, 2),
				($bp-m, $bp-l, 3),
				($bp-l, null, 3, 1, 9)
			);

			@include resp-gallery-layout($gridConf);
		}
	}
}