.rims {
	margin-top: $dist *1.5;

	@include breakpoint(null, $bp-m) {
		margin-top: $dist;
	}

	@include breakpoint(null, $bp-s) {
		margin-top: $dist/2;
	}

	& > h3 {
		text-transform: uppercase;
		font-weight: $fw-h;
		font-size: $fs-h4;
	}

	&__select {
		@include resp-margin(top,0.5);
		margin-bottom: $dist/2;
		position: relative;
		font-size: 0;

		&:after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			content: '';
			height: 1px;
			background: $border-color;
			z-index: 10;
		}

		li {
			position: relative;
			z-index: 15;
			cursor: pointer;
			display: inline-block;
			padding-bottom: $dist/4;
			border-bottom: 1px solid $border-color;
			text-transform: uppercase;
			transition: border-color 0.2s linear;
			margin-right: $dist/2;

			&:hover,
			&.active {
				border-color: $ac;

				span {
					color: $ac;
				}
			}

			span {
				font-size: $fs;
			}
		}
	}

	&__transparent,
	&__solid {
		display: none;

		&.active {
			display: block;
		}

		.colorColHead {
			@extend %cf;
			margin-bottom: $dist/2;

			&Left {
				float: left;
				width: 277px;
				margin-right: $dist/2;
			}

			&Right {
				float: right;
				width: calc(100% - #{$dist/2 + 277px});
			}
		}

		.colorCols {
			@extend %cf;
		}

		.colorCol {
			$grid-conf: (
				(null, $bp-s, 3),
				($bp-s, $bp-m, 4),
				($bp-m, null, 6)
			);

			@include resp-gallery-layout($grid-conf);
		}

		.colorImgContainer {
			position: relative;

			img {
				@include resp-img;
				position: relative;
				z-index: 10;

				&:last-of-type {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 5;
				}
			}

			&:hover {
				img:last-of-type {
					z-index: 15;
				}
			}
		}
	}
}
