@import "../../../node_modules/susy/sass/_susy.scss";
@import "../../../vendor/grasenhiller/silverstripe-tweaks/client/libraries/fontawesome/scss/_variables";
@import "globals/_variables";
@import "globals/_extends";
@import "globals/_mixins";
@import "globals/_text-styling";

// - Set box-sizing to border-box
*,
*:after,
*:before {
	box-sizing: border-box;
}

// - Fix small gutters of images
img,
video {
	vertical-align: top;
}

// - Remove focus
*:focus {
	outline: 0;
}

// - IE + Edge ... whoop whoop
main,
.main {
	display: block;
}

// - Set page bg color
html {
	background: $box-color-dark;
}

body {
	background: $page-bg;
}

.outer-wrapper {
	@extend %outer-container;
}

.main__text {
	& + * {
		margin-top: $dist/1.33;
	}
}

.ytvideo-popup {
	@include breakpoint($bp-m, null) {
		padding: 65px !important;
	}

	background: $fc !important;
}

.audio {
	display: none !important;
}

.lb-caption {
	display: none !important;
	opacity: 0 !important;
	visibility: hidden !important;
}

.line-item,
.cart-form__product {
	vertical-align: middle;

	img {
		display: inline-block;
		width: 64px;
		height: 64px;
		margin-right: 0.5em;
		vertical-align: middle;
	}
}

@import "partials/_badge.scss";
@import "partials/_basic-holder-child.scss";
@import "partials/_brands-filter.scss";
@import "partials/_cookie-confirm.scss";
@import "partials/_featherlight.scss";
@import "partials/_footer.scss";
@import "partials/_form.scss";
@import "partials/_header.scss";
@import "partials/_homepage-teaser.scss";
@import "partials/_language-switcher.scss";
@import "partials/_latest-news.scss";
@import "partials/_loader.scss";
@import "partials/_masonry.scss";
@import "partials/_mobile-navigation.scss";
@import "partials/_navigation-footer.scss";
@import "partials/_navigation-main.scss";
@import "partials/_navigation-mobile.scss";
@import "partials/_navigation.scss";
@import "partials/_newsletter.scss";
@import "partials/_page-media.scss";
@import "partials/_page-slider.scss";
@import "partials/_page-title.scss";
@import "partials/_pagination.scss";
@import "partials/_product-page-basic-holder.scss";
@import "partials/_rims.scss";
@import "partials/_sidebar.scss";
@import "partials/_social-media-icons.scss";
@import "partials/_totop.scss";
@import "partials/_tuning-parts-categories.scss";
@import "partials/_tuning-popup.scss";
@import "partials/_userforms.scss";
@import "shop-partials/_add-to-cart-form.scss";
@import "shop-partials/_address.scss";
@import "shop-partials/_cartform.scss";
@import "shop-partials/_categories.scss";
@import "shop-partials/_category.scss";
@import "shop-partials/_checkoutform.scss";
@import "shop-partials/_forms.scss";
@import "shop-partials/_general.scss";
@import "shop-partials/_instock.scss";
@import "shop-partials/_one-time-messages.scss";
@import "shop-partials/_order-summary.scss";
@import "shop-partials/_placed-orders.scss";
@import "shop-partials/_product.scss";
@import "shop-partials/_products.scss";
@import "shop-partials/_shop-btns.scss";
@import "shop-partials/_shop-global-notice.scss";
@import "shop-partials/_tabs.scss";
@import "pages/_accountpage.scss";
@import "pages/_basic-holder.scss";
@import "pages/_carpage.scss";
@import "pages/_cartpage.scss";
@import "pages/_categorypage.scss";
@import "pages/_checkoutpage.scss";
@import "pages/_contact.scss";
@import "pages/_homepage.scss";
@import "pages/_login-page.scss";
@import "pages/_news-holder.scss";
@import "pages/_news-page.scss";
@import "pages/_page.scss";
@import "pages/_product-page.scss";
@import "pages/_productpage.scss";
@import "pages/_shopholder.scss";
@import "pages/_userdefinedform.scss";
