.pagination {
	text-align: center;
	@include resp-padding(top);
	font-size: 0;

	&:before {
		display: block;
		content: '';
		border-top: 1px solid $border-color;
		@include resp-margin(bottom);
	}

	& > :last-child {
		margin-right: 0;
	}

	a,
	span {
		font-size: $fs;
		display: inline-block;
		width: $dist;
		height: $dist;
		line-height: $dist;
		background: #FFFFFF;
		border-radius: 100%;
		border: 1px solid $fc;
		transition: all $duration linear;
		margin-right: $dist/4;
	}

	strong {
		display: inline-block;
		font-size: $fs;
		line-height: $dist;
		margin: 0 $dist/2 0 $dist/4;
	}

	a {
		color: $fc;
		text-decoration: none;
		transition: all $duration;

		&:hover {
			border-color: $border-color;
			color: $ac;
		}
	}

	span.pagination__current {
		background: $hc;
		color: #FFFFFF;
	}
}
