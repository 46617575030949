.navigation.navigation--footer {
	padding: $dist 0;

	@include breakpoint(null, $bp-m) {
		padding: $dist/1.5 0;
	}

	@include breakpoint(null, $bp-s) {
		padding: $dist/2 0;
	}

	ul {
		justify-content: normal;
		display: inline-flex;

		@include breakpoint(null, $bp-s) {
			display: block !important;
		}

		li {
			margin-right: $dist;

			@include breakpoint(null, $bp-s) {
				margin-right: 0;
				margin-bottom: $dist/4;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}

			a {
				text-transform: uppercase;
			}
		}
	}
}
