#Form_AddToCartForm {
	.field .message {
		margin-top: $dist / 3;
	}

	.system-message + *,
	.message:not([style*="display: none"]) + * {
		margin-top: $dist/1.5 !important;
	}

	#Form_ItemEditForm_StockNotice {
		margin-bottom: $dist/1.5 !important;
	}

	.btn-toolbar {
		& > span {
			display: inline-block;
			font-size: $fs-small;
			color: $fc-light;
			margin-left: 0.5em;
			position: relative;
			top: -0.125em;
		}
	}
}