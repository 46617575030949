.shop-categories {
	@extend %cf;
}

body:not(.has-sidebar) {
	.shop-categories {
		.shop-category {
			$gridConf: (
				(null, $bp-xs, 1, 2),
				($bp-xs, $bp-m, 2),
				($bp-m, null, 3)
			);

			@include resp-gallery-layout($gridConf);
		}
	}
}

body.has-sidebar {
	.shop-categories {
		.shop-category {
			$gridConf: (
				(null, $bp-xs, 1, 2),
				($bp-xs, $bp-m, 2),
				($bp-m, $bp-l, 3),
				($bp-l, null, 3, 1, 9)
			);


			@include resp-gallery-layout($gridConf);
		}
	}
}