.tuning-part-popup {
	&--prev,
	&--next {
		position: absolute;
		top: 50%;
		@include center(v);
		color: $fc-light;
		font-size: $fs-h3;
		transition: color $duration linear;

		&:hover {
			color: $ac;
		}
	}

	&--prev {
		left: -#{$dist*3 - $dist/2};

		@include breakpoint(null, $bp-m) {
			left: -#{$dist/1.5 - $dist/2};
		}

		@include breakpoint(null, $bp-xs) {
			left: -#{$dist/4};
		}
	}

	&--next {
		right: -#{$dist*3 - $dist/2};

		@include breakpoint(null, $bp-m) {
			right: -#{$dist/1.5 - $dist/2};
		}

		@include breakpoint(null, $bp-xs) {
			right: -#{$dist/4};
		}
	}

	&__header {
		@extend %cf;
		margin-bottom: $dist;

		@include breakpoint(null, $bp-xs) {
			margin-bottom: $dist/2;
		}

		li {
			display: block;
			float: right;

			@include breakpoint($bp-m, null) {
				max-width: 730px;
			}

			img {
				@include resp-img;
			}
		}
	}

	&__first-img {
		border-bottom: 1px solid $border-color;
		width: 50%;

		@include breakpoint(null, $bp-m) {
			width: 100%;
		}
	}

	&__second-img {
		width: 50%;

		@include breakpoint(null, $bp-m) {
			width: 100%;
		}
	}

	&__content {
		margin: 0 $dist*3;
		padding-bottom: $dist*1.5;
		position: relative;

		@include breakpoint(null, $bp-m) {
			padding-bottom: $dist/1.5;
			margin: 0 $dist/1.5;
		}

		@include breakpoint(null, $bp-xs) {
			margin: 0 $dist/2;
		}

		& > h2 {
			font-size: $fs-h2;
			text-transform: uppercase;

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h3;
			}
		}

		& > span {
			display: block;
			font-size: $fs-h3;
			color: $fc-light;
			margin-bottom: $dist;

			@include breakpoint(null, $bp-m) {
				margin-bottom: $dist/2;
			}

			@include breakpoint(null, $bp-xs) {
				font-size: $fs;
			}
		}
	}

	&__colors {
		margin-top: $dist *1.5;
		@extend %cf;

		@include breakpoint(null, $bp-m) {
			margin-top: $dist;
		}

		@include breakpoint(null, $bp-s) {
			margin-top: $dist/2;
		}

		li {
			display: block;
			float: left;
			width: 49.5%;
			margin-right: 1%;
			margin-top: $dist*1.5;
			@extend %cf;

			@include breakpoint(null, $bp-m) {
				width: 100%;
				margin-right: 0;
			}

			@include breakpoint(null, $bp-m) {
				margin-top: $dist/2 !important;
			}

			&:first-of-type {
				@include breakpoint(null, $bp-m) {
					margin-top: 0 !important;
				}
			}

			&:nth-of-type(-n+2) {
				margin-top: 0;

				@include breakpoint(null, $bp-m) {
					margin-top: $dist*1.5;
				}
			}

			&:nth-of-type(2n+2) {
				margin-right: 0;
			}

			&:nth-of-type(2n+3) {
				clear: both;
			}

			img {
				display: block;
				float: left;
				width: 150px;
				height: 150px;
				border-radius: 100%;

				@include breakpoint(null, $bp-xs) {
					width: 100px;
					height: 100px;
				}
			}

			& > span {
				display: block;
				float: left;
				margin-left: $dist/2;
				width: calc(100% - #{150px + $dist});
				color: $fc-light;
				line-height: 150px;

				@include breakpoint(null, $bp-xs) {
					width: calc(100% - (100px + (#{$dist} / 4)));
					line-height: 100px;
					margin-left: $dist/4;
				}

				span {
					display: inline-block;
				}

				strong {
					display: block;
					text-transform: uppercase;
					color: $fc;
				}
			}
		}
	}

	&--small {
		width: 730px;

		@include breakpoint(null, $bp-m) {
			width: 100%;
		}

		.tuning-part-popup__header {
			.tuning-part-popup__first-img {
				width: 100% !important;
			}
		}

		.tuning-part-popup__content {
			margin: 0 $dist*2;

			@include breakpoint(null, $bp-m) {
				margin: 0 $dist;
			}

			@include breakpoint(null, $bp-s) {
				margin: 0 $dist/2;
			}
		}

		.tuning-part-popup--prev {
			left: -#{$dist*2 - $dist/2};

			@include breakpoint(null, $bp-m) {
				left: -#{$dist - $dist/2};
			}

			@include breakpoint(null, $bp-xs) {
				left: -#{$dist/4};
			}
		}

		.tuning-part-popup--next {
			right: -#{$dist*2 - $dist/2};

			@include breakpoint(null, $bp-m) {
				right: -#{$dist - $dist/2};
			}

			@include breakpoint(null, $bp-xs) {
				right: -#{$dist/4};
			}
		}

		.tuning-part-popup__colors {
			li {
				width: 100%;
				margin-right: 0;
				margin-top: $dist;

				&:first-of-type {
					margin-top: 0;
				}

				&:nth-of-type(2) {
					margin-top: $dist;
				}
			}
		}
	}
}
