$widthPrice: 100px;
$widthQuantity: 100px;
$widthSum: 100px;
$widthDelete: $dist;
$widthTitle: calc(100% - #{$widthPrice + $widthQuantity + $widthSum + $widthDelete + $dist*2});

#Form_UpdateCartForm_action_doUpdateCart {
	float: left;
	@extend %button;
	@extend %button--neutral;
}

#Form_UpdateCartForm_action_goToCheckout {
	float: right;
}

#Form_UpdateCartForm {
	.btn-toolbar {
		@extend %cf;
		margin-top: $dist;
	}

	& > fieldset {
		& > ul {
			@extend %cf;

			li {
				display: block;
				float: left;
				font-weight: $fw-bold;
				line-height: 1;
				margin-right: $dist/2;

				&:last-child {
					margin-right: 0;
				}
			}

			// - header
			&:first-of-type {
				margin-bottom: $dist/2;
				padding-bottom: $dist/2;
				border-bottom: 2px solid $border-color;

				li {
					&:first-child {
						width: $widthTitle;
					}

					&:nth-child(2) {
						width: $widthPrice;
					}

					&:nth-child(3) {
						width: $widthQuantity;
					}

					&:nth-child(4) {
						width: $widthSum;
					}

					&:nth-child(5) {
						width: $widthDelete;
						text-align: center;
					}
				}
			}

			// - footer
			&:last-of-type {
				margin-top: $dist/2;
				padding-top: $dist/2;
				border-top: 2px solid $border-color;

				li {
					&:first-of-type {
						width: calc(100% - #{$widthPrice + $widthQuantity + $widthSum + $widthDelete + $dist*2});
					}

					&:last-of-type {
						float: right;
						width: $widthPrice + $widthQuantity + $widthSum + $widthDelete + $dist*1.5;
					}
				}
			}
		}

		// - last item
		& > div:nth-last-of-type(2) {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		// - body / item
		.cart-form__item {
			@extend %cf;
			margin-bottom: $dist/2;
			padding-bottom: $dist/2;
			border-bottom: 1px dashed $border-color;

			& > * {
				float: left;
				margin-right: $dist/2;
				margin-bottom: 0;

				&:not(.numeric) {
					line-height: $line-height;
					padding-top: $dist/4 + 1px;
					padding-bottom: $dist/4 + 1px;
				}

				&.numeric {
					padding-right: $dist/2;
				}

				&.checkbox {
					label {
						margin: 0;
						padding: 0;
						text-align: center;

						&:before {
							margin-right: 0;
						}
					}
				}

				&:first-child {
					width: $widthTitle;
					font-weight: $fw-medium;
				}

				&:nth-child(2) {
					width: $widthPrice;
				}

				&:nth-child(3) {
					width: $widthQuantity;
				}

				&:nth-child(4) {
					width: $widthSum;
				}

				&:nth-child(5) {
					width: $widthDelete;
					text-align: center;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}