#grasenhiller_newspage {
  .page-slider {
    margin-bottom: $dist/2;
  }

  .page-title {
    margin-bottom: 0;

    h1 {
      float: none;
    }
  }

  main {
    .page-title {
      margin-bottom: $dist*1.25;

      @include breakpoint(null, $bp-m) {
        margin-bottom: $dist/1.25;
      }

      @include breakpoint(null, $bp-s) {
        margin-bottom: $dist/2;
      }
    }
  }
}

.news-page {
  &__inner {
    margin: 0 auto;
    max-width: $inner-max-width;

    .page-title {
      margin-bottom: $dist;

      @include breakpoint(null, $bp-s) {
        margin-bottom: $dist/2;
      }
    }
  }

  &__preview-text {
    @include resp-margin(bottom);

    p {
      font-size: $fs-h3;
    }
  }

  &__text {
    @extend %cf;

    .news-page__image {
      display: block;
      width: span(4 of 12);
      float: right;
      margin-left: $dist/2;
      margin-bottom: $dist/4;
      @include image-hover();

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &__meta {
    margin-top: $dist/2;
    padding-top: $dist/2;
    border-top: 1px solid $border-color;
    color: $fc-light;
    font-size: $fs-small;

    a {
      color: $fc-light;
      text-decoration: none;

      &:hover {
        color: $ac;
      }
    }

    ul {
      @extend %cf;
      margin: $dist/4 0 0 0;
      padding: 0;

      li {
        display: block;

        &.news-page__prev {
          float: left;

          a:before {
            @include fontawesome($fa-var-angle-left);
            position: relative;
            bottom: -1px;
          }
        }

        &.news-page__next {
          float: right;

          a:after {
            @include fontawesome($fa-var-angle-right);
            position: relative;
            bottom: -1px;
          }
        }
      }
    }
  }
}
