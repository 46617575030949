.header {
  position: relative;
  font-size: 0;
  padding: $dist/2;
  background: #ffffff;

  @include breakpoint(null, $bp-xs) {
    padding: $dist/2 $dist/3;
  }

  .header__left {
    position: absolute;
    top: $dist/2;
    left: $dist/2;
    white-space: nowrap;
    @extend %cf;

    @include breakpoint(null, $bp-s) {
      top: 15px;
    }

    @include breakpoint(null, $bp-xs) {
      left: $dist/3;
    }

    small {
      display: block;
      float: left;
      font-weight: $fw-bold;
      text-transform: uppercase;
      font-size: $fs-small;
      line-height: 40px;
      margin-left: $dist/2;

      @include breakpoint(null, $bp-s) {
        display: none;
      }
    }
  }

  .header__logo {
    display: block;
    width: 206px;
    margin: 0 auto;
    text-decoration: none;

    img {
      image-rendering: auto;
    }

    @include breakpoint($bp-m, $bp-l) {
      transform: translateX(-134px);
    }

    @include breakpoint(580px, $bp-m) {
      transform: translateX(-67px);
    }

    @include breakpoint(null, $bp-s) {
      width: 140px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .header__title {
      display: block;
      text-transform: uppercase;
      font-weight: $fw-h-bold;
      font-family: $ff-h;
      font-size: $fs-h2;
      color: $hc;
      line-height: 1;
    }

    .header__slogan {
      position: relative;
      display: block;
      line-height: 1;
      font-size: 0;
      margin-top: $dist/4;

      &:after {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -1px;
        display: block;
        content: '';
        height: 2px;
        background: $border-color;
      }

      span {
        position: relative;
        z-index: 10;
        display: inline-block;
        padding-right: $dist/4;
        text-transform: uppercase;
        font-weight: $fw-bold;
        font-size: $fs-small;
        color: $fc;
        background: #ffffff;
        line-height: 1;
      }
    }
  }

  .header__right {
    position: absolute;
    top: $dist/2;
    right: $dist/2;
    @extend %cf;

    @include breakpoint(null, $bp-s) {
      top: 14px;
    }

    @include breakpoint(null, $bp-xs) {
      right: $dist/3;
    }

    &-shop {
      width: 134px;
      float: left;
      margin: 0 $dist/1.33 0 0;
      font-size: $fs;

      @include breakpoint(null, $bp-m) {
        margin: 0;
      }

      @include breakpoint(null, 580px) {
        display: none;
      }
    }
  }

  .social-media-icons {
    float: left;

    @include breakpoint(null, $bp-m) {
      display: none;
    }
  }

  .language-switcher {
    float: left;
    margin-left: $dist/1.33;
  }
}

.page--no-slider {
  .header {
    border-bottom: 1px solid $border-color;
    margin-bottom: $dist/1.25;

    @include breakpoint(null, $bp-m) {
      margin-bottom: $dist/2;
    }
  }
}
