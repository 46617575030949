#app_productpage {
	.product-price {
		font-weight: $fw-bold;
		font-size: $fs-h5;
		margin-top: -0.25em;
		margin-bottom: $dist/4;
	}

	.userform {
		margin-top: $dist;

		@include breakpoint($bp-m, null) {
			max-width: $inner-max-width;
		}

		.checkbox {
			label {
				font-weight: $fw-bold;
			}
		}
	}
}
