.newsletter {
	position: relative;
	@include resp-margin(top,2);

	@include breakpoint(null, $bp-m) {
		margin-bottom: $dist;
	}

	& + .footer {
		margin-top: 0;
	}

	&__title,
	&__link {
		position: absolute;
		top: $dist*2;
		right: $dist*2;
		text-align: right;

		@include breakpoint(null, $bp-l) {
			top: $dist;
			right: $dist;
		}

		@include breakpoint(null, $bp-m) {
			position: static;
			text-align: left;
			margin-left: $dist/4;
		}

		span {
			display: block;
			white-space: nowrap;
			font-size: $fs-h1;
			font-weight: $fw-h-light;
			color: #FFFFFF;
			text-transform: uppercase;
			line-height: 1;
			margin-top: -0.25em;

			@include breakpoint(null, $bp-m) {
				font-size: $fs-h2;
				color: $fc;
				margin-bottom: $dist/3;
			}

			@include breakpoint(null, $bp-xs) {
				font-size: $fs-h3;
			}
		}
	}

	&__link {
		top: $dist*3.5;

		@include breakpoint(null, $bp-l) {
			top: $dist*2.5;
		}

		@include breakpoint(null, $bp-m) {
			margin-top: $dist/3;
		}
	}

	img {
		@include resp-img;
	}
}
