.shop-tabs {
	@extend %cf;

	&__menu {
		float: left;

		ul {
			li {
				border-bottom: 1px solid $border-color;
				padding: $dist/4 0;
				cursor: pointer;
				transition: all $duration linear;

				&:hover {
					background: $box-color;
					padding-left: $dist/4;
				}

				&:first-of-type {
					border-top: 1px solid $border-color;
				}

				&.shop-tabs--active {
					font-weight: $fw-bold;
				}
			}
		}
	}

	&__content {
		float: left;
		padding-left: $dist;
		border-left: 1px solid $border-color;

		& > div {
			display: none;

			&.shop-tabs--active {
				display: block;
			}
		}
	}
}

body.has-sidebar {
	@include breakpoint($bp-l, null) {
		.shop-tabs {
			&__menu {
				width: span(3 of 9);
				margin-right: gutter(9);
			}

			&__content {
				width: span(6 of 9);
			}
		}
	}

	@include breakpoint(null, $bp-l) {
		.shop-tabs {
			&__menu {
				width: span(3 of 12);
				margin-right: gutter(12);
			}

			&__content {
				width: span(9 of 12);
			}
		}
	}
}

body:not(.has-sidebar) {
	.shop-tabs {
		&__menu {
			width: span(3 of 12);
			margin-right: gutter(12);
		}

		&__content {
			width: span(9 of 12);
		}
	}
}