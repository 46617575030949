.tuning-parts-categories {
	margin-top: $dist;

	@include breakpoint(null, $bp-m) {
		margin-top: $dist * 0.75;
	}

	@include breakpoint(null, $bp-s) {
		margin-top: $dist/1.5;
	}

	.sticky-wrapper {
		margin-bottom: $dist;
		background: #ffffff;
		@include breakpoint(null, $bp-m) {
			display: none !important;
		}

		.tuning-parts-categories__tabs {
			margin-bottom: 0;
			background: #ffffff;
			z-index: 50 !important;
		}
	}

	&__tabs {
		margin-bottom: $dist;
		background: #ffffff;

		@include breakpoint(null, $bp-m) {
			display: none !important;
		}

		ul {
			position: relative;
			font-size: 0;
			margin-top: -$dist/2;
			margin-left: -$dist;

			&:after {
				position: absolute;
				left: $dist;
				right: 0;
				display: block;
				content: '';
				height: 1px;
				background: $border-color;
			}

			li {
				cursor: pointer;
				position: relative;
				z-index: 10;
				display: inline-block;
				text-transform: uppercase;
				font-size: $fs-h3;
				padding-bottom: $dist/2;
				padding-top: $dist/2;
				border-bottom: 1px solid $border-color;
				margin-bottom: -1px;
				margin-left: $dist;
				margin-top: $dist/2;
				transition: border-color $duration linear;

				@include breakpoint(null, $bp-l) {
					width: calc(50% - #{$dist});
				}

				@include breakpoint(null, $bp-m) {
					width: calc(100% - #{$dist});
				}

				@include breakpoint(null, $bp-s) {
					font-size: $fs-h6;
					padding-bottom: $dist/4;
				}

				&.active {
					border-color: $ac;
					font-weight: $fw-bold;
				}

				&:hover {
					border-color: $ac;
				}
			}
		}
	}

	&__content {
		& > div {
			display: block;
			@include resp-margin(bottom, 2);
			min-height: 400px;

			& > h3 {
				font-weight: $fw-light;
				text-transform: uppercase;
				font-size: $fs-h2;
				@include resp-margin(bottom);
			}

			ul {
				@extend %cf;

				li {
					font-size: $fs-h3;

					@include breakpoint(null, $bp-s) {
						font-size: $fs-h6;
					}

					$grid-conf: (
						(null, $bp-s, 1),
						($bp-s, $bp-l, 2),
						($bp-l, null, 3)
					);

					@include resp-gallery-layout($grid-conf);

					img {
						@include resp-img;
						image-rendering: pixelated;
					}

					a {
						&:first-of-type {
							@include image-hover($fa-var-arrow-right);
							border: 1px solid $border-color;
						}

						&:last-of-type {
							display: block;
							margin-top: $dist/2;
							font-weight: $fw-bold;
							transition: color $duration linear;

							&:hover {
								color: $ac;
							}

							&:after {
								@include fontawesome($fa-var-arrow-right);
								margin-left: 0.25em;
								color: $ac;
							}
						}
					}

					span {
						display: block;
						color: $fc-light;
						margin-top: $dist/6;
					}
				}
			}
		}
	}
}
