.shop-product-instock {
	span {
		display: inline-block;
		font-size: $fs-small;
		font-weight: $fw-medium;
		border-radius: $radius;
		line-height: 1;
		padding: $dist/6 $dist/4;
		color: #FFFFFF;
		text-transform: uppercase;
	}

	&--soon span {
		background: rgba($warning-color, 1);
	}

	&--in span {
		background: rgba($valid-color, 1);
	}

	&--soon2 span {
		background: rgba($ac, 1) !important;
	}

	&--out span {
		background: rgba($fc, 1);
	}
}