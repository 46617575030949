.page-slider {
  position: relative;
  margin-bottom: $dist*1.25;

  @include breakpoint(null, $bp-l) {
    margin-bottom: $dist/2;
  }

  @include breakpoint(null, $bp-s) {
    margin-bottom: $dist/3;
  }

  &__arrows {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .outer-wrapper {
      font-size: 0;
      text-align: right;
      margin-bottom: $dist/1.33;

      @include breakpoint(null, $bp-s) {
        margin-bottom: $dist/2;
      }
    }
  }

  &--carousel {
    .slick-slide {
      padding-right: $dist/4;

      img {
        border: 1px solid $border-color;
      }
    }
  }

  .slick-arrow {
    display: inline-block;
    color: $fc;
    cursor: pointer;
    font-size: $fs;
    width: $dist;
    height: $dist;
    line-height: $dist;
    border-radius: 100%;
    border: 1px solid $border-color;
    transition: all $duration linear;

    &:first-of-type {
      margin-right: $dist / 1.33 - 4px;

      @include breakpoint(null, $bp-s) {
        margin-right: $dist/4;
      }
    }

    &:hover {
      color: $ac;
    }
  }

  .slick-dots {
    position: absolute;
    z-index: 20;
    bottom: $dist/2;
    left: 0;
    right: 0;
    font-size: 0;
    text-align: center;

    li {
      display: inline-block;
      margin-right: 2px;

      &:last-of-type {
        margin-right: 0;

        &:first-of-type {
          display: none;
        }
      }

      button {
        cursor: pointer;
        display: block;
        width: $dist/3;
        height: $dist/3;
        background: $box-color;
        border-radius: 100%;
        font-size: 0;
        border: 0;
        box-shadow: none;

        &:hover {
          background: $box-color-dark;
        }
      }

      &.slick-active {
        button {
          background: $hc;

          &:hover {
            background: darken($hc, 10%);
          }
        }
      }
    }
  }

  &__slide {
    position: relative;
    max-height: 600px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__caption {
    z-index: 10;
    left: 0;
    right: 0;

    &--position {
      &-center {
        @include center(v);
        text-align: center;
      }

      &-top-left {
        position: absolute;
        top: $dist*2;

        h3 {
          margin-top: -0.25em;
        }

        @include breakpoint(null, $bp-l) {
          top: $dist;
        }
      }
    }
  }

  &__caption-content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $max-width;

    & > h3 {
      font-size: $fs-h1;
      font-weight: $fw-h;
      font-family: $ff-h;
      color: #ffffff;

      @include breakpoint(null, $bp-m) {
        font-size: $fs-h2;
      }

      span {
        color: $hc;
      }
    }

    & > p {
      @include resp-margin(top);
      font-size: $fs-h3;
      font-weight: $fw-bold;
      color: #ffffff;
      max-width: $max-width/1.5;

      span {
        box-decoration-break: clone;
      }
    }

    & > a {
      @include resp-margin(top);
      display: inline-block;
      color: $ac;
    }

    &.page-slider__caption-content--font-color-light {
      & > h3,
      & > h3 span,
      & > p,
      & > a {
        color: #ffffff;
      }
    }
  }
}

#app_homepage {
  .page-slider {
    &__slide {
      max-height: 100%;
    }
  }
}
