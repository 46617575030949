html > body {
	.featherlight {
		background: #2c2e36;
		background: rgba(#2c2e36, 0);
	}

	.featherlight:last-of-type {
		background: rgba(#2c2e36, 0.9);
	}

	.featherlight .featherlight-content {
		padding: 0;
		border-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		max-height: 95%;
		background: transparent;
		overflow-x: hidden;

		@include breakpoint($bp-m, null) {
			max-width: 1460px;
		}

		@include breakpoint(null, $bp-m) {
			width: 100%;
		}
	}

	.featherlight-inner {
		background: #FFFFFF;
	}

	.featherlight .featherlight-close-icon {
		font-size: 0;
		line-height: 0;
		background: transparent;
		top: $dist/2;
		right: $dist/2;
		text-align: right;

		&:before {
			transition: color $duration linear;
			@include fontawesome($fa-var-times);
			color: $ac;
			font-size: $fs-h2;
			position: relative;
			right: -0.225em;
		}

		&:hover {
			&:before {
				color: $ac-hover;
			}
		}
	}

	/* handling phones and small screens */
	@media only screen and (max-width: 1520px) {
		.featherlight .featherlight-content {
			/* dimensions: maximize lightbox with for small screens */
			margin-left: 0;
			margin-right: 0;
			max-height: 98%;
			padding: 10px 10px 0;
			border-bottom: 10px solid transparent;
		}
	}
}
