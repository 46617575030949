.language-switcher {
	&__link {
		a {
			font-size: $fs-small;
			display: block;
			line-height: 20px;
			transition: color $duration linear;
			font-weight: $fw-medium;
		}

		&--current,
		&_hover {
			a {
				color: $ac;
			}
		}
	}
}
