#silverstripe_userdefinedform .main, .product-page__left ul, .product-page__top, .news-page__meta ul, .news-page__text, .news-preview, .login-page, #grasenhiller_checkoutpage .main, html .basic-holder__children, .shop-tabs, .shop-products, .placed-orders > ul, .order-summary__line-items .line-item, .order-summary__line-items > ul:last-of-type li > span > span, .order-summary__line-items > ul:last-of-type li, .order-summary__line-items > ul, .order-summary__addresses, .order-summary__payment-shipping, .order-summary__status ul, .two-fields-in-line > .fieldgroup > .fieldgroup-field .fieldholder-small, .two-fields-in-line, #Form_CheckoutForm .btn-toolbar, .shop-categories, #Form_UpdateCartForm > fieldset .cart-form__item, #Form_UpdateCartForm > fieldset > ul, #Form_UpdateCartForm .btn-toolbar, html .userform .step-navigation ul, .tuning-part-popup__colors li, .tuning-part-popup__colors, .tuning-part-popup__header, .tuning-parts-categories__content > div ul, .has-sidebar .sidebar, .has-sidebar .outer-wrapper, .rims__transparent .colorCols, .rims__solid .colorCols, .rims__transparent .colorColHead, .rims__solid .colorColHead, .page-title, .page-media__files li, .page-media__images, .navigation.navigation--mobile .navigation__sub .navigation__sub-link, .navigation.navigation--mobile ul li, .mobile-navigation__bottom-mobile, .masonry, .latest-news__children, .header .header__right, .header .header__left, form .field .middleColumn, form .field, .footer__cars, .responsive-iframe-container, .cf {
  *zoom: 1;
}
#silverstripe_userdefinedform .main:before, .product-page__left ul:before, .product-page__top:before, .news-page__meta ul:before, .news-page__text:before, .news-preview:before, .login-page:before, #grasenhiller_checkoutpage .main:before, html .basic-holder__children:before, .shop-tabs:before, .shop-products:before, .placed-orders > ul:before, .order-summary__line-items .line-item:before, .order-summary__line-items > ul:last-of-type li > span > span:before, .order-summary__line-items > ul:last-of-type li:before, .order-summary__line-items > ul:before, .order-summary__addresses:before, .order-summary__payment-shipping:before, .order-summary__status ul:before, .two-fields-in-line > .fieldgroup > .fieldgroup-field .fieldholder-small:before, .two-fields-in-line:before, #Form_CheckoutForm .btn-toolbar:before, .shop-categories:before, #Form_UpdateCartForm > fieldset .cart-form__item:before, #Form_UpdateCartForm > fieldset > ul:before, #Form_UpdateCartForm .btn-toolbar:before, html .userform .step-navigation ul:before, .tuning-part-popup__colors li:before, .tuning-part-popup__colors:before, .tuning-part-popup__header:before, .tuning-parts-categories__content > div ul:before, .has-sidebar .sidebar:before, .has-sidebar .outer-wrapper:before, .rims__transparent .colorCols:before, .rims__solid .colorCols:before, .rims__transparent .colorColHead:before, .rims__solid .colorColHead:before, .page-title:before, .page-media__files li:before, .page-media__images:before, .navigation.navigation--mobile .navigation__sub .navigation__sub-link:before, .navigation.navigation--mobile ul li:before, .mobile-navigation__bottom-mobile:before, .masonry:before, .latest-news__children:before, .header .header__right:before, .header .header__left:before, form .field .middleColumn:before, form .field:before, .footer__cars:before, .responsive-iframe-container:before, .cf:before {
  content: "";
  display: table;
}
#silverstripe_userdefinedform .main:after, .product-page__left ul:after, .product-page__top:after, .news-page__meta ul:after, .news-page__text:after, .news-preview:after, .login-page:after, #grasenhiller_checkoutpage .main:after, html .basic-holder__children:after, .shop-tabs:after, .shop-products:after, .placed-orders > ul:after, .order-summary__line-items .line-item:after, .order-summary__line-items > ul:last-of-type li > span > span:after, .order-summary__line-items > ul:last-of-type li:after, .order-summary__line-items > ul:after, .order-summary__addresses:after, .order-summary__payment-shipping:after, .order-summary__status ul:after, .two-fields-in-line > .fieldgroup > .fieldgroup-field .fieldholder-small:after, .two-fields-in-line:after, #Form_CheckoutForm .btn-toolbar:after, .shop-categories:after, #Form_UpdateCartForm > fieldset .cart-form__item:after, #Form_UpdateCartForm > fieldset > ul:after, #Form_UpdateCartForm .btn-toolbar:after, html .userform .step-navigation ul:after, .tuning-part-popup__colors li:after, .tuning-part-popup__colors:after, .tuning-part-popup__header:after, .tuning-parts-categories__content > div ul:after, .has-sidebar .sidebar:after, .has-sidebar .outer-wrapper:after, .rims__transparent .colorCols:after, .rims__solid .colorCols:after, .rims__transparent .colorColHead:after, .rims__solid .colorColHead:after, .page-title:after, .page-media__files li:after, .page-media__images:after, .navigation.navigation--mobile .navigation__sub .navigation__sub-link:after, .navigation.navigation--mobile ul li:after, .mobile-navigation__bottom-mobile:after, .masonry:after, .latest-news__children:after, .header .header__right:after, .header .header__left:after, form .field .middleColumn:after, form .field:after, .footer__cars:after, .responsive-iframe-container:after, .cf:after {
  content: "";
  display: table;
  clear: both;
}

.outer-wrapper {
  width: calc(100% - 20px);
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
}

#Form_CheckoutForm #Form_CheckoutForm_action_goBackToEdit, #Form_UpdateCartForm_action_doUpdateCart, html .userform .step-navigation ul li button, .homepage-teaser__link a, input[type=submit],
input[type=reset], .cookie-confirm #cookie-confirm__submit,
.cookie-confirm #cookie-confirm__cancel, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button-2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button-2, .formatted-text .text--button-2,
[class*=__text] .text--button-2, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button, .formatted-text .text--button,
[class*=__text] .text--button, .button {
  display: inline-block;
  color: #e2001a;
  padding: 9px 30px;
  transition: all linear 0.2s;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #e2001a;
  border-radius: 100px;
  line-height: 1.35em;
  font-weight: 400;
  background: #FFFFFF;
}
#Form_CheckoutForm #Form_CheckoutForm_action_goBackToEdit:hover, #Form_UpdateCartForm_action_doUpdateCart:hover, html .userform .step-navigation ul li button:hover, .homepage-teaser__link a:hover, input[type=submit]:hover,
input[type=reset]:hover, .cookie-confirm #cookie-confirm__submit:hover,
.cookie-confirm #cookie-confirm__cancel:hover, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button-2:hover,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button-2:hover, .formatted-text .text--button-2:hover,
[class*=__text] .text--button-2:hover, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button:hover,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button:hover, .formatted-text .text--button:hover,
[class*=__text] .text--button:hover, .button:hover {
  border-color: #af0014;
  color: #af0014;
}
#Form_CheckoutForm #Form_CheckoutForm_action_goBackToEdit:focus, #Form_UpdateCartForm_action_doUpdateCart:focus, html .userform .step-navigation ul li button:focus, .homepage-teaser__link a:focus, input[type=submit]:focus,
input[type=reset]:focus, .cookie-confirm #cookie-confirm__submit:focus,
.cookie-confirm #cookie-confirm__cancel:focus, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button-2:focus,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button-2:focus, .formatted-text .text--button-2:focus,
[class*=__text] .text--button-2:focus, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button:focus,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button:focus, .formatted-text .text--button:focus,
[class*=__text] .text--button:focus, .button:focus {
  outline: none;
  border-style: solid;
  border-width: 1px;
}

.button--small {
  font-size: 0.75rem;
  padding: 5px 15px;
}

.button--white {
  color: #303239;
  border-color: #ffffff;
}
.button--white:hover {
  color: #e6e6e6;
  border-color: #e6e6e6;
}

#Form_CheckoutForm #Form_CheckoutForm_action_goBackToEdit, #Form_UpdateCartForm_action_doUpdateCart, html .userform .step-navigation ul li button, input[type=reset], .cookie-confirm #cookie-confirm__cancel, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button-2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button-2, .formatted-text .text--button-2,
[class*=__text] .text--button-2, .button--neutral {
  color: #303239;
  border-color: #303239;
}
#Form_CheckoutForm #Form_CheckoutForm_action_goBackToEdit:hover, #Form_UpdateCartForm_action_doUpdateCart:hover, html .userform .step-navigation ul li button:hover, input[type=reset]:hover, .cookie-confirm #cookie-confirm__cancel:hover, #Form_RegistrationForm_AcceptDataProcessing_Holder label .text--button-2:hover,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--button-2:hover, .formatted-text .text--button-2:hover,
[class*=__text] .text--button-2:hover, .button--neutral:hover {
  color: #e2001a;
  border-color: #e2001a;
}

#Form_RegistrationForm_AcceptDataProcessing_Holder label ul.list--checklist,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul.list--checklist, .formatted-text ul.list--checklist,
[class*=__text] ul.list--checklist {
  list-style: none;
  margin-left: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul.list--checklist li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul.list--checklist li, .formatted-text ul.list--checklist li,
[class*=__text] ul.list--checklist li {
  display: block;
  padding-left: 1.25em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul.list--checklist li:before,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul.list--checklist li:before, .formatted-text ul.list--checklist li:before,
[class*=__text] ul.list--checklist li:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00c";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1em;
  margin-left: -1.25em;
  float: left;
  text-align: center;
  position: relative;
  top: 3px;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul.list--checklist li:before,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul.list--checklist li:before, .formatted-text ul.list--checklist li:before,
[class*=__text] ul.list--checklist li:before {
  margin-top: 0.125em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--discreet,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--discreet, .formatted-text .text--discreet,
[class*=__text] .text--discreet {
  color: #aaaaaa !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--highlight,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--highlight, .formatted-text .text--highlight,
[class*=__text] .text--highlight {
  color: #303239;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background, .formatted-text .text--background,
[class*=__text] .text--background,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background-2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background-2,
.formatted-text .text--background-2,
[class*=__text] .text--background-2,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background-3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background-3,
.formatted-text .text--background-3,
[class*=__text] .text--background-3 {
  padding: 1.35rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background > *:last-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background > *:last-child, .formatted-text .text--background > *:last-child,
[class*=__text] .text--background > *:last-child,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background-2 > *:last-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background-2 > *:last-child,
.formatted-text .text--background-2 > *:last-child,
[class*=__text] .text--background-2 > *:last-child,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background-3 > *:last-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background-3 > *:last-child,
.formatted-text .text--background-3 > *:last-child,
[class*=__text] .text--background-3 > *:last-child {
  margin-bottom: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background, .formatted-text .text--background,
[class*=__text] .text--background,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--inline-bg,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--inline-bg,
.formatted-text .text--inline-bg,
[class*=__text] .text--inline-bg {
  background: #303239;
  color: #ffffff;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background-2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background-2, .formatted-text .text--background-2,
[class*=__text] .text--background-2,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--inline-bg-2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--inline-bg-2,
.formatted-text .text--inline-bg-2,
[class*=__text] .text--inline-bg-2 {
  background: #e2001a;
  color: #ffffff;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--background-3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--background-3, .formatted-text .text--background-3,
[class*=__text] .text--background-3,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--inline-bg-3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--inline-bg-3,
.formatted-text .text--inline-bg-3,
[class*=__text] .text--inline-bg-3 {
  background: #e9e9e9;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--small,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--small, .formatted-text .text--small,
[class*=__text] .text--small {
  font-size: 0.75rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--medium,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--medium, .formatted-text .text--medium,
[class*=__text] .text--medium {
  font-size: 1.125rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .text--big,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .text--big, .formatted-text .text--big,
[class*=__text] .text--big {
  font-size: 1.875rem;
  font-weight: 300;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p, .formatted-text p,
[class*=__text] p {
  min-height: 1.35em;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow: hidden;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p.text-center,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p.text-center, .formatted-text p.text-center,
[class*=__text] p.text-center {
  text-align: center;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p.text-left,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p.text-left, .formatted-text p.text-left,
[class*=__text] p.text-left {
  text-align: left;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p.text-right,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p.text-right, .formatted-text p.text-right,
[class*=__text] p.text-right {
  text-align: right;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p.text-justify,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p.text-justify, .formatted-text p.text-justify,
[class*=__text] p.text-justify {
  text-align: justify;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label code:not([class*=language-]),
#Form_CheckoutForm_AcceptDataProcessing_Holder label code:not([class*=language-]), .formatted-text code:not([class*=language-]),
[class*=__text] code:not([class*=language-]) {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  font-family: monospace, san-serif;
  font-size: 0.75rem;
  padding: 20px;
  background: #ffffff;
  border-radius: 0px;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label pre:not([class*=language-]),
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre:not([class*=language-]), .formatted-text pre:not([class*=language-]),
[class*=__text] pre:not([class*=language-]) {
  white-space: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  background: #e9e9e9;
  padding: 1.35rem;
  border-radius: 0px;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label pre[class*=language-],
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre[class*=language-], .formatted-text pre[class*=language-],
[class*=__text] pre[class*=language-] {
  padding: 1.35rem;
  margin: 0.75em 0 0 0;
  border-radius: 0px;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img[style*="max-width:"],
#Form_CheckoutForm_AcceptDataProcessing_Holder label img[style*="max-width:"], .formatted-text img[style*="max-width:"],
[class*=__text] img[style*="max-width:"],
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed[style*="max-width:"],
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed[style*="max-width:"],
.formatted-text .embed[style*="max-width:"],
[class*=__text] .embed[style*="max-width:"] {
  display: block;
  width: 100%;
  height: auto;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.center,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.center, .formatted-text img.center,
[class*=__text] img.center, #Form_RegistrationForm_AcceptDataProcessing_Holder label img.leftAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.leftAlone, .formatted-text img.leftAlone,
[class*=__text] img.leftAlone, #Form_RegistrationForm_AcceptDataProcessing_Holder label img.rightAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.rightAlone, .formatted-text img.rightAlone,
[class*=__text] img.rightAlone,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.center,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.center,
.formatted-text .embed.center,
[class*=__text] .embed.center,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.leftAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.leftAlone,
.formatted-text .embed.leftAlone,
[class*=__text] .embed.leftAlone,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.rightAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.rightAlone,
.formatted-text .embed.rightAlone,
[class*=__text] .embed.rightAlone {
  display: block;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.center + *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.center + *, .formatted-text img.center + *,
[class*=__text] img.center + *, #Form_RegistrationForm_AcceptDataProcessing_Holder label img.leftAlone + *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.leftAlone + *, .formatted-text img.leftAlone + *,
[class*=__text] img.leftAlone + *, #Form_RegistrationForm_AcceptDataProcessing_Holder label img.rightAlone + *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.rightAlone + *, .formatted-text img.rightAlone + *,
[class*=__text] img.rightAlone + *,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.center + *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.center + *,
.formatted-text .embed.center + *,
[class*=__text] .embed.center + *,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.leftAlone + *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.leftAlone + *,
.formatted-text .embed.leftAlone + *,
[class*=__text] .embed.leftAlone + *,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.rightAlone + *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.rightAlone + *,
.formatted-text .embed.rightAlone + *,
[class*=__text] .embed.rightAlone + * {
  clear: both;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.center,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.center, .formatted-text img.center,
[class*=__text] img.center,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.center,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.center,
.formatted-text .embed.center,
[class*=__text] .embed.center {
  margin-left: auto;
  margin-right: auto;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.leftAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.leftAlone, .formatted-text img.leftAlone,
[class*=__text] img.leftAlone,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.leftAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.leftAlone,
.formatted-text .embed.leftAlone,
[class*=__text] .embed.leftAlone {
  float: left;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.rightAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.rightAlone, .formatted-text img.rightAlone,
[class*=__text] img.rightAlone,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.rightAlone,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.rightAlone,
.formatted-text .embed.rightAlone,
[class*=__text] .embed.rightAlone {
  float: right;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.left,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.left, .formatted-text img.left,
[class*=__text] img.left,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.left,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.left,
.formatted-text .embed.left,
[class*=__text] .embed.left {
  float: left;
  margin: 1em 1.35em 0.5em 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label img.right,
#Form_CheckoutForm_AcceptDataProcessing_Holder label img.right, .formatted-text img.right,
[class*=__text] img.right,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .embed.right,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .embed.right,
.formatted-text .embed.right,
[class*=__text] .embed.right {
  float: right;
  margin: 1em 0 0.5em 1.35em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1, .formatted-text h1,
[class*=__text] h1 {
  font-size: 3.125rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2, .formatted-text h2,
[class*=__text] h2 {
  font-size: 1.875rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3, .formatted-text h3,
[class*=__text] h3 {
  font-size: 1.125rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4, .formatted-text h4,
[class*=__text] h4 {
  font-size: 1.125rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5, .formatted-text h5,
[class*=__text] h5 {
  font-size: 1.125rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6, .formatted-text h6,
[class*=__text] h6 {
  font-size: 0.9375rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1, .formatted-text h1,
[class*=__text] h1,
#Form_RegistrationForm_AcceptDataProcessing_Holder label h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2,
.formatted-text h2,
[class*=__text] h2,
#Form_RegistrationForm_AcceptDataProcessing_Holder label h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3,
.formatted-text h3,
[class*=__text] h3,
#Form_RegistrationForm_AcceptDataProcessing_Holder label h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4,
.formatted-text h4,
[class*=__text] h4,
#Form_RegistrationForm_AcceptDataProcessing_Holder label h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5,
.formatted-text h5,
[class*=__text] h5,
#Form_RegistrationForm_AcceptDataProcessing_Holder label h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6,
.formatted-text h6,
[class*=__text] h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  margin-top: 1.5em !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label > *:first-child, .formatted-text > *:first-child,
[class*=__text] > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label > *:last-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label > *:last-child, .formatted-text > *:last-child,
[class*=__text] > *:last-child {
  margin-bottom: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p, .formatted-text p,
[class*=__text] p {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p > *:first-child, .formatted-text p > *:first-child,
[class*=__text] p > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p .btn,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p .btn, .formatted-text p .btn,
[class*=__text] p .btn {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + ul, .formatted-text p + ul,
[class*=__text] p + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + ol, .formatted-text p + ol,
[class*=__text] p + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + h1, .formatted-text p + h1,
[class*=__text] p + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + h2, .formatted-text p + h2,
[class*=__text] p + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + h3, .formatted-text p + h3,
[class*=__text] p + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + h4, .formatted-text p + h4,
[class*=__text] p + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + h5, .formatted-text p + h5,
[class*=__text] p + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + h6, .formatted-text p + h6,
[class*=__text] p + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + table, .formatted-text p + table,
[class*=__text] p + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + div, .formatted-text p + div,
[class*=__text] p + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + blockquote, .formatted-text p + blockquote,
[class*=__text] p + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + code, .formatted-text p + code,
[class*=__text] p + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label p + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p + pre, .formatted-text p + pre,
[class*=__text] p + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote, .formatted-text blockquote,
[class*=__text] blockquote {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote > *:first-child, .formatted-text blockquote > *:first-child,
[class*=__text] blockquote > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + ul, .formatted-text blockquote + ul,
[class*=__text] blockquote + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + ol, .formatted-text blockquote + ol,
[class*=__text] blockquote + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + h1, .formatted-text blockquote + h1,
[class*=__text] blockquote + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + h2, .formatted-text blockquote + h2,
[class*=__text] blockquote + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + h3, .formatted-text blockquote + h3,
[class*=__text] blockquote + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + h4, .formatted-text blockquote + h4,
[class*=__text] blockquote + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + h5, .formatted-text blockquote + h5,
[class*=__text] blockquote + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + h6, .formatted-text blockquote + h6,
[class*=__text] blockquote + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + table, .formatted-text blockquote + table,
[class*=__text] blockquote + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + div, .formatted-text blockquote + div,
[class*=__text] blockquote + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + p, .formatted-text blockquote + p,
[class*=__text] blockquote + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + code, .formatted-text blockquote + code,
[class*=__text] blockquote + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote + pre, .formatted-text blockquote + pre,
[class*=__text] blockquote + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code, .formatted-text code,
[class*=__text] code {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label code > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code > *:first-child, .formatted-text code > *:first-child,
[class*=__text] code > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label code + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + ul, .formatted-text code + ul,
[class*=__text] code + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + ol, .formatted-text code + ol,
[class*=__text] code + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + h1, .formatted-text code + h1,
[class*=__text] code + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + h2, .formatted-text code + h2,
[class*=__text] code + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + h3, .formatted-text code + h3,
[class*=__text] code + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + h4, .formatted-text code + h4,
[class*=__text] code + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + h5, .formatted-text code + h5,
[class*=__text] code + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + h6, .formatted-text code + h6,
[class*=__text] code + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + table, .formatted-text code + table,
[class*=__text] code + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + div, .formatted-text code + div,
[class*=__text] code + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + p, .formatted-text code + p,
[class*=__text] code + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + code, .formatted-text code + code,
[class*=__text] code + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label code + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label code + pre, .formatted-text code + pre,
[class*=__text] code + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre, .formatted-text pre,
[class*=__text] pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label pre > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre > *:first-child, .formatted-text pre > *:first-child,
[class*=__text] pre > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label pre + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + ul, .formatted-text pre + ul,
[class*=__text] pre + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + ol, .formatted-text pre + ol,
[class*=__text] pre + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + h1, .formatted-text pre + h1,
[class*=__text] pre + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + h2, .formatted-text pre + h2,
[class*=__text] pre + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + h3, .formatted-text pre + h3,
[class*=__text] pre + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + h4, .formatted-text pre + h4,
[class*=__text] pre + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + h5, .formatted-text pre + h5,
[class*=__text] pre + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + h6, .formatted-text pre + h6,
[class*=__text] pre + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + table, .formatted-text pre + table,
[class*=__text] pre + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + div, .formatted-text pre + div,
[class*=__text] pre + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + p, .formatted-text pre + p,
[class*=__text] pre + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + code, .formatted-text pre + code,
[class*=__text] pre + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label pre + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label pre + pre, .formatted-text pre + pre,
[class*=__text] pre + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + h1, .formatted-text h1 + h1,
[class*=__text] h1 + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + h2, .formatted-text h1 + h2,
[class*=__text] h1 + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + h3, .formatted-text h1 + h3,
[class*=__text] h1 + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + h4, .formatted-text h1 + h4,
[class*=__text] h1 + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + h5, .formatted-text h1 + h5,
[class*=__text] h1 + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + h6, .formatted-text h1 + h6,
[class*=__text] h1 + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + p, .formatted-text h1 + p,
[class*=__text] h1 + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + ul, .formatted-text h1 + ul,
[class*=__text] h1 + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + ol, .formatted-text h1 + ol,
[class*=__text] h1 + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + table, .formatted-text h1 + table,
[class*=__text] h1 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + div, .formatted-text h1 + div,
[class*=__text] h1 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + blockquote, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + code, .formatted-text h1 + code,
[class*=__text] h1 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + pre, .formatted-text h1 + pre,
[class*=__text] h1 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + h1, .formatted-text h2 + h1,
[class*=__text] h2 + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + h2, .formatted-text h2 + h2,
[class*=__text] h2 + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + h3, .formatted-text h2 + h3,
[class*=__text] h2 + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + h4, .formatted-text h2 + h4,
[class*=__text] h2 + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + h5, .formatted-text h2 + h5,
[class*=__text] h2 + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + h6, .formatted-text h2 + h6,
[class*=__text] h2 + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + p, .formatted-text h2 + p,
[class*=__text] h2 + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + ul, .formatted-text h2 + ul,
[class*=__text] h2 + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + ol, .formatted-text h2 + ol,
[class*=__text] h2 + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + table, .formatted-text h2 + table,
[class*=__text] h2 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + div, .formatted-text h2 + div,
[class*=__text] h2 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + blockquote, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + code, .formatted-text h2 + code,
[class*=__text] h2 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + pre, .formatted-text h2 + pre,
[class*=__text] h2 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + h1, .formatted-text h3 + h1,
[class*=__text] h3 + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + h2, .formatted-text h3 + h2,
[class*=__text] h3 + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + h3, .formatted-text h3 + h3,
[class*=__text] h3 + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + h4, .formatted-text h3 + h4,
[class*=__text] h3 + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + h5, .formatted-text h3 + h5,
[class*=__text] h3 + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + h6, .formatted-text h3 + h6,
[class*=__text] h3 + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + p, .formatted-text h3 + p,
[class*=__text] h3 + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + ul, .formatted-text h3 + ul,
[class*=__text] h3 + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + ol, .formatted-text h3 + ol,
[class*=__text] h3 + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + table, .formatted-text h3 + table,
[class*=__text] h3 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + div, .formatted-text h3 + div,
[class*=__text] h3 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + blockquote, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + code, .formatted-text h3 + code,
[class*=__text] h3 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + pre, .formatted-text h3 + pre,
[class*=__text] h3 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + h1, .formatted-text h4 + h1,
[class*=__text] h4 + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + h2, .formatted-text h4 + h2,
[class*=__text] h4 + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + h3, .formatted-text h4 + h3,
[class*=__text] h4 + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + h4, .formatted-text h4 + h4,
[class*=__text] h4 + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + h5, .formatted-text h4 + h5,
[class*=__text] h4 + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + h6, .formatted-text h4 + h6,
[class*=__text] h4 + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + p, .formatted-text h4 + p,
[class*=__text] h4 + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + ul, .formatted-text h4 + ul,
[class*=__text] h4 + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + ol, .formatted-text h4 + ol,
[class*=__text] h4 + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + table, .formatted-text h4 + table,
[class*=__text] h4 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + div, .formatted-text h4 + div,
[class*=__text] h4 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + blockquote, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + code, .formatted-text h4 + code,
[class*=__text] h4 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + pre, .formatted-text h4 + pre,
[class*=__text] h4 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + h1, .formatted-text h5 + h1,
[class*=__text] h5 + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + h2, .formatted-text h5 + h2,
[class*=__text] h5 + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + h3, .formatted-text h5 + h3,
[class*=__text] h5 + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + h4, .formatted-text h5 + h4,
[class*=__text] h5 + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + h5, .formatted-text h5 + h5,
[class*=__text] h5 + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + h6, .formatted-text h5 + h6,
[class*=__text] h5 + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + p, .formatted-text h5 + p,
[class*=__text] h5 + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + ul, .formatted-text h5 + ul,
[class*=__text] h5 + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + ol, .formatted-text h5 + ol,
[class*=__text] h5 + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + table, .formatted-text h5 + table,
[class*=__text] h5 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + div, .formatted-text h5 + div,
[class*=__text] h5 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + blockquote, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + code, .formatted-text h5 + code,
[class*=__text] h5 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + pre, .formatted-text h5 + pre,
[class*=__text] h5 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + h1, .formatted-text h6 + h1,
[class*=__text] h6 + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + h2, .formatted-text h6 + h2,
[class*=__text] h6 + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + h3, .formatted-text h6 + h3,
[class*=__text] h6 + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + h4, .formatted-text h6 + h4,
[class*=__text] h6 + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + h5, .formatted-text h6 + h5,
[class*=__text] h6 + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + h6, .formatted-text h6 + h6,
[class*=__text] h6 + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + p, .formatted-text h6 + p,
[class*=__text] h6 + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + ul, .formatted-text h6 + ul,
[class*=__text] h6 + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + ol, .formatted-text h6 + ol,
[class*=__text] h6 + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + table, .formatted-text h6 + table,
[class*=__text] h6 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + div, .formatted-text h6 + div,
[class*=__text] h6 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + blockquote, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + code, .formatted-text h6 + code,
[class*=__text] h6 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + pre, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + div, .formatted-text h1 + div,
[class*=__text] h1 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + table, .formatted-text h1 + table,
[class*=__text] h1 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + blockquote, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + code, .formatted-text h1 + code,
[class*=__text] h1 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h1 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h1 + pre, .formatted-text h1 + pre,
[class*=__text] h1 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + div, .formatted-text h2 + div,
[class*=__text] h2 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + table, .formatted-text h2 + table,
[class*=__text] h2 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + blockquote, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + code, .formatted-text h2 + code,
[class*=__text] h2 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h2 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h2 + pre, .formatted-text h2 + pre,
[class*=__text] h2 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + div, .formatted-text h3 + div,
[class*=__text] h3 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + table, .formatted-text h3 + table,
[class*=__text] h3 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + blockquote, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + code, .formatted-text h3 + code,
[class*=__text] h3 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h3 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h3 + pre, .formatted-text h3 + pre,
[class*=__text] h3 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + div, .formatted-text h4 + div,
[class*=__text] h4 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + table, .formatted-text h4 + table,
[class*=__text] h4 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + blockquote, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + code, .formatted-text h4 + code,
[class*=__text] h4 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h4 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h4 + pre, .formatted-text h4 + pre,
[class*=__text] h4 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + div, .formatted-text h5 + div,
[class*=__text] h5 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + table, .formatted-text h5 + table,
[class*=__text] h5 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + blockquote, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + code, .formatted-text h5 + code,
[class*=__text] h5 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h5 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h5 + pre, .formatted-text h5 + pre,
[class*=__text] h5 + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + div, .formatted-text h6 + div,
[class*=__text] h6 + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + table, .formatted-text h6 + table,
[class*=__text] h6 + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + blockquote, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + code, .formatted-text h6 + code,
[class*=__text] h6 + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label h6 + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label h6 + pre, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 1em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li, .formatted-text ul li,
[class*=__text] ul li {
  list-style-type: disc;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li ul li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li ul li, .formatted-text ul li ul li,
[class*=__text] ul li ul li {
  list-style-type: circle;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li ul li ul li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li ul li ul li, .formatted-text ul li ul li ul li,
[class*=__text] ul li ul li ul li {
  list-style-type: square;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ol li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol li, .formatted-text ol li,
[class*=__text] ol li {
  list-style-type: decimal;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul, .formatted-text ul,
[class*=__text] ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol, .formatted-text ol,
[class*=__text] ol {
  list-style: outside;
  margin: 0 0 1em 2.25em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li, .formatted-text ul li,
[class*=__text] ul li, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol li,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol li, .formatted-text ol li,
[class*=__text] ol li {
  line-height: 1.35em;
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li:first-of-type,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li:first-of-type, .formatted-text ul li:first-of-type,
[class*=__text] ul li:first-of-type, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol li:first-of-type,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol li:first-of-type, .formatted-text ol li:first-of-type,
[class*=__text] ol li:first-of-type {
  margin-top: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li ul, .formatted-text ul li ul,
[class*=__text] ul li ul,
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul li ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul li ol,
.formatted-text ul li ol,
[class*=__text] ul li ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol li ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol li ul, .formatted-text ol li ul,
[class*=__text] ol li ul,
#Form_RegistrationForm_AcceptDataProcessing_Holder label ol li ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol li ol,
.formatted-text ol li ol,
[class*=__text] ol li ol {
  margin-top: 0.75em;
  margin-bottom: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label ul + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + p, .formatted-text ul + p,
[class*=__text] ul + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + h1, .formatted-text ul + h1,
[class*=__text] ul + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + h2, .formatted-text ul + h2,
[class*=__text] ul + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + h3, .formatted-text ul + h3,
[class*=__text] ul + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + h4, .formatted-text ul + h4,
[class*=__text] ul + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + h5, .formatted-text ul + h5,
[class*=__text] ul + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + h6, .formatted-text ul + h6,
[class*=__text] ul + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + table, .formatted-text ul + table,
[class*=__text] ul + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + div, .formatted-text ul + div,
[class*=__text] ul + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + blockquote, .formatted-text ul + blockquote,
[class*=__text] ul + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + code, .formatted-text ul + code,
[class*=__text] ul + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label ul + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ul + pre, .formatted-text ul + pre,
[class*=__text] ul + pre, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + p, .formatted-text ol + p,
[class*=__text] ol + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + h1, .formatted-text ol + h1,
[class*=__text] ol + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + h2, .formatted-text ol + h2,
[class*=__text] ol + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + h3, .formatted-text ol + h3,
[class*=__text] ol + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + h4, .formatted-text ol + h4,
[class*=__text] ol + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + h5, .formatted-text ol + h5,
[class*=__text] ol + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + h6, .formatted-text ol + h6,
[class*=__text] ol + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + table, .formatted-text ol + table,
[class*=__text] ol + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + div, .formatted-text ol + div,
[class*=__text] ol + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + blockquote, .formatted-text ol + blockquote,
[class*=__text] ol + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + code, .formatted-text ol + code,
[class*=__text] ol + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label ol + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label ol + pre, .formatted-text ol + pre,
[class*=__text] ol + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label div > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div > *:first-child, .formatted-text div > *:first-child,
[class*=__text] div > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label div + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + p, .formatted-text div + p,
[class*=__text] div + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + ul, .formatted-text div + ul,
[class*=__text] div + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + ol, .formatted-text div + ol,
[class*=__text] div + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + h1, .formatted-text div + h1,
[class*=__text] div + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + h2, .formatted-text div + h2,
[class*=__text] div + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + h3, .formatted-text div + h3,
[class*=__text] div + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + h4, .formatted-text div + h4,
[class*=__text] div + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + h5, .formatted-text div + h5,
[class*=__text] div + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + h6, .formatted-text div + h6,
[class*=__text] div + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + table, .formatted-text div + table,
[class*=__text] div + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + div, .formatted-text div + div,
[class*=__text] div + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + blockquote, .formatted-text div + blockquote,
[class*=__text] div + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + code, .formatted-text div + code,
[class*=__text] div + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label div + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label div + pre, .formatted-text div + pre,
[class*=__text] div + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table > *:first-child, .formatted-text table > *:first-child,
[class*=__text] table > *:first-child {
  margin-top: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table + p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + p, .formatted-text table + p,
[class*=__text] table + p, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + ul,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + ul, .formatted-text table + ul,
[class*=__text] table + ul, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + ol,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + ol, .formatted-text table + ol,
[class*=__text] table + ol, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + h1,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + h1, .formatted-text table + h1,
[class*=__text] table + h1, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + h2,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + h2, .formatted-text table + h2,
[class*=__text] table + h2, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + h3,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + h3, .formatted-text table + h3,
[class*=__text] table + h3, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + h4,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + h4, .formatted-text table + h4,
[class*=__text] table + h4, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + h5,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + h5, .formatted-text table + h5,
[class*=__text] table + h5, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + h6,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + h6, .formatted-text table + h6,
[class*=__text] table + h6, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + table, .formatted-text table + table,
[class*=__text] table + table, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + div,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + div, .formatted-text table + div,
[class*=__text] table + div, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + blockquote, .formatted-text table + blockquote,
[class*=__text] table + blockquote, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + code,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + code, .formatted-text table + code,
[class*=__text] table + code, #Form_RegistrationForm_AcceptDataProcessing_Holder label table + pre,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table + pre, .formatted-text table + pre,
[class*=__text] table + pre {
  margin-top: 0.75em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label hr,
#Form_CheckoutForm_AcceptDataProcessing_Holder label hr, .formatted-text hr,
[class*=__text] hr {
  outline: 0;
  border: 0;
  display: block;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
  background: #aaaaaa;
}
@media screen and (max-width: 768px) {
  #Form_RegistrationForm_AcceptDataProcessing_Holder label hr,
#Form_CheckoutForm_AcceptDataProcessing_Holder label hr, .formatted-text hr,
[class*=__text] hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote, .formatted-text blockquote,
[class*=__text] blockquote {
  position: relative;
  min-height: 1.884375rem;
  padding-left: calc(1.875rem + 20px);
  font-style: italic;
  color: #aaaaaa;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label blockquote:before,
#Form_CheckoutForm_AcceptDataProcessing_Holder label blockquote:before, .formatted-text blockquote:before,
[class*=__text] blockquote:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f10d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 1.875rem;
  position: absolute;
  left: 0;
  top: 0.15rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table, .formatted-text table,
[class*=__text] table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table thead th,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table thead th, .formatted-text table thead th,
[class*=__text] table thead th {
  border-bottom: 2px solid #aaaaaa !important;
  border-right: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table thead + tbody tr th:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table thead + tbody tr th:first-child, .formatted-text table thead + tbody tr th:first-child,
[class*=__text] table thead + tbody tr th:first-child {
  border-right: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table tfoot th,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table tfoot th, .formatted-text table tfoot th,
[class*=__text] table tfoot th {
  border-top: 2px solid #aaaaaa;
  border-right: 0 !important;
  border-bottom: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table td,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table td, .formatted-text table td,
[class*=__text] table td {
  border-bottom: 1px solid #aaaaaa;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table th,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table th, .formatted-text table th,
[class*=__text] table th,
#Form_RegistrationForm_AcceptDataProcessing_Holder label table td,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table td,
.formatted-text table td,
[class*=__text] table td {
  text-align: left;
  padding: 0.5rem 0.75rem;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table th > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table th > *:first-child, .formatted-text table th > *:first-child,
[class*=__text] table th > *:first-child,
#Form_RegistrationForm_AcceptDataProcessing_Holder label table td > *:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table td > *:first-child,
.formatted-text table td > *:first-child,
[class*=__text] table td > *:first-child {
  margin-top: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label table tr th:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label table tr th:first-child, .formatted-text table tr th:first-child,
[class*=__text] table tr th:first-child {
  border-right: 2px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .table--two-heads table tbody tr th:first-child,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .table--two-heads table tbody tr th:first-child, .formatted-text .table--two-heads table tbody tr th:first-child,
[class*=__text] .table--two-heads table tbody tr th:first-child {
  border-right: 2px solid #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .table--striped tbody tr:nth-child(odd),
#Form_CheckoutForm_AcceptDataProcessing_Holder label .table--striped tbody tr:nth-child(odd), .formatted-text .table--striped tbody tr:nth-child(odd),
[class*=__text] .table--striped tbody tr:nth-child(odd) {
  background-color: #e9e9e9;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label .table--responsive,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .table--responsive, .formatted-text .table--responsive,
[class*=__text] .table--responsive {
  overflow-x: auto;
}
@media screen and (max-width: 768px) {
  #Form_RegistrationForm_AcceptDataProcessing_Holder label .table--responsive,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .table--responsive, .formatted-text .table--responsive,
[class*=__text] .table--responsive {
    width: 100%;
  }
  #Form_RegistrationForm_AcceptDataProcessing_Holder label .table--responsive th,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .table--responsive th, .formatted-text .table--responsive th,
[class*=__text] .table--responsive th,
#Form_RegistrationForm_AcceptDataProcessing_Holder label .table--responsive td,
#Form_CheckoutForm_AcceptDataProcessing_Holder label .table--responsive td,
.formatted-text .table--responsive td,
[class*=__text] .table--responsive td {
    white-space: nowrap;
  }
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label a:not(.button):not(.btn):not(.text--button):not(.text--button-2),
#Form_CheckoutForm_AcceptDataProcessing_Holder label a:not(.button):not(.btn):not(.text--button):not(.text--button-2), .formatted-text a:not(.button):not(.btn):not(.text--button):not(.text--button-2),
[class*=__text] a:not(.button):not(.btn):not(.text--button):not(.text--button-2) {
  color: #e2001a;
  text-decoration: underline;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover,
#Form_CheckoutForm_AcceptDataProcessing_Holder label a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover, .formatted-text a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover,
[class*=__text] a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover {
  background: #e2001a;
  color: #ffffff;
  text-decoration: none;
}

.responsive-iframe-container .responsive-iframe--1_1 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 100% !important;
}
.responsive-iframe-container .responsive-iframe--1_1 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--4_3 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 75% !important;
}
.responsive-iframe-container .responsive-iframe--4_3 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--3_2 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 66.6666666667% !important;
}
.responsive-iframe-container .responsive-iframe--3_2 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--5_3 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 60% !important;
}
.responsive-iframe-container .responsive-iframe--5_3 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--16_9 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 56.25% !important;
}
.responsive-iframe-container .responsive-iframe--16_9 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--3_1 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 33.3333333333% !important;
}
.responsive-iframe-container .responsive-iframe--3_1 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--1920_850 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 44.2708333333% !important;
}
.responsive-iframe-container .responsive-iframe--1920_850 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--560_690 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 123.2142857143% !important;
}
.responsive-iframe-container .responsive-iframe--560_690 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--560_375 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 66.9642857143% !important;
}
.responsive-iframe-container .responsive-iframe--560_375 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container .responsive-iframe--1160_690 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 59.4827586207% !important;
}
.responsive-iframe-container .responsive-iframe--1160_690 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.responsive-iframe-container iframe {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  html {
    font-size: 16px-2px;
  }
}

body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 0.9375rem;
  color: #303239;
  text-size-adjust: none;
}

strong, b, th {
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

small {
  font-size: 0.75rem;
}

h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, li {
  line-height: 1.35em;
}

i.fa,
i.fal,
i.far,
i.fab {
  display: inline-block;
  min-width: 1em;
  text-align: center;
  font-style: normal;
}

a {
  color: #303239;
  text-decoration: none;
}
a:hover {
  color: #e2001a;
}

::selection {
  color: #ffffff;
  background: #303239;
}

::-moz-selection {
  color: #ffffff;
  background: #303239;
}

hr {
  border: none;
  height: 1px;
  background: #aaaaaa;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

em, i {
  font-style: italic;
}

.system-message + *, .message:not([style*="display: none"]) + * {
  margin-top: 40px !important;
}
@media screen and (max-width: 768px) {
  .system-message + *, .message:not([style*="display: none"]) + * {
    margin-top: 20px !important;
  }
}
.system-message + .system-message, .message:not([style*="display: none"]) + .system-message, .system-message + .message:not([style*="display: none"]), .message:not([style*="display: none"]) + .message:not([style*="display: none"]) {
  margin-top: 6.6666666667px !important;
}
.system-message, .message:not([style*="display: none"]), .system-message--neutral {
  cursor: pointer;
  position: relative;
  border-left: 5px solid #aaaaaa;
  padding: 20px 40px 20px 20px;
  background: rgba(170, 170, 170, 0.1);
}
.system-message:after, .message:not([style*="display: none"]):after, .system-message--neutral:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.5;
  transition: all 0.2s linear;
}
.system-message:hover:after, .message:hover:not([style*="display: none"]):after, .system-message--neutral:hover:after {
  opacity: 1;
}
.system-message--good, .message:not([style*="display: none"]).good, .message:not([style*="display: none"]).success {
  border-color: #9edd3c;
  background: rgba(158, 221, 60, 0.1);
}
.system-message--warning, .message:not([style*="display: none"]).warning {
  border-color: #eabe17;
  background: rgba(234, 190, 23, 0.1);
}
.system-message--bad, .message:not([style*="display: none"]).bad, .message:not([style*="display: none"]).error, .message:not([style*="display: none"]).required {
  border-color: #dd3c3c;
  background: rgba(221, 60, 60, 0.1);
}
.system-message--permanent, .message:not([style*="display: none"]) {
  cursor: default;
}
.system-message--permanent:after, .message:not([style*="display: none"]):after {
  display: none;
}

.dist-right {
  margin-right: 0.25em;
}

.dist-left {
  margin-left: 0.25em;
}

.dist-top {
  margin-top: 0.25em;
}

.dist-bottom {
  margin-bottom: 0.25em;
}

br {
  line-height: 1;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

textarea {
  resize: vertical;
}

.global-sc-dist-right {
  margin-right: 0.5em;
}

.global-sc-dist-left {
  margin-left: 0.5em;
}

.global-sc-dist-top {
  margin-top: 0.5em;
}

.global-sc-dist-bottom {
  margin-bottom: 0.5em;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img,
video {
  vertical-align: top;
}

*:focus {
  outline: 0;
}

main,
.main {
  display: block;
}

html {
  background: #303239;
}

body {
  background: #ffffff;
}

.main__text + * {
  margin-top: 30.0751879699px;
}

.ytvideo-popup {
  background: #303239 !important;
}
@media screen and (min-width: 769px) {
  .ytvideo-popup {
    padding: 65px !important;
  }
}

.audio {
  display: none !important;
}

.lb-caption {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.line-item,
.cart-form__product {
  vertical-align: middle;
}
.line-item img,
.cart-form__product img {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin-right: 0.5em;
  vertical-align: middle;
}

.badge {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.125em 0.35em;
  color: #ffffff;
  background: #303239;
  display: inline-block;
}
.badge--absolute {
  position: absolute;
  top: 10px;
  right: 1px;
  z-index: 1;
}
.badge--new {
  background: #e2001a;
}

html .basic-holder__children .basic-holder__child--app_productpage > h3,
html .basic-holder__children .basic-holder__child--app_carpage > h3,
html .basic-holder__children .basic-holder__child--grasenhiller_basicholder > h3 {
  margin-bottom: 0;
}

.basic-holder__child {
  position: relative;
}

.basic-holder__grand-children {
  margin-top: 10px;
}
.basic-holder__grand-children li i {
  color: #e2001a;
}

.basic-holder__child-preview-image img {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
}
.basic-holder__child-preview-image a > span {
  display: block;
  position: relative !important;
  overflow: hidden !important;
  padding-top: 53.5842293907% !important;
}
.basic-holder__child-preview-image a > span > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.basic-holder__child-preview-image a > span > span {
  display: block;
  color: #aaaaaa;
}

html .basic-holder__child-preview-image a > span > span:first-of-type {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: auto !important;
  height: auto !important;
  top: 50% !important;
  left: 50% !important;
}
html .basic-holder__child-preview-image a > span > span:first-of-type:after, html .basic-holder__child-preview-image a > span > span:first-of-type:before {
  display: none;
}

#Form_FilterForm {
  float: right;
  width: 240px;
  margin-top: -22.8571428571px;
}
@media screen and (max-width: 480px) {
  #Form_FilterForm {
    float: none;
    margin-top: 20px;
  }
}
#Form_FilterForm #Form_FilterForm_brand_Holder {
  margin-bottom: 0;
}
#Form_FilterForm #Form_FilterForm_brand_Holder .middleColumn {
  width: 100%;
}
#Form_FilterForm #Form_FilterForm_brand_Holder .middleColumn select {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: #303239;
}
#Form_FilterForm .btn-toolbar {
  display: none;
}

.cookie-confirm {
  position: fixed;
  z-index: 999999999999;
  background: #ffffff;
  width: 100%;
  max-width: calc(100% - 20px);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  box-shadow: 5px 5px 20px -10px #000000;
  right: 10px;
  bottom: 10px;
  max-height: 90%;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-confirm {
    max-height: 75% !important;
  }
}
@media screen and (min-width: 769px) {
  .cookie-confirm {
    max-width: 50%;
    right: 20px;
    bottom: 20px;
  }
}
@media screen and (max-height: 750px) {
  .cookie-confirm {
    right: 5px;
    bottom: 5px;
    max-width: calc(100% - 10px);
    padding: 10px;
  }
}
.cookie-confirm__top > span {
  display: block;
  font-family: "Raleway", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1;
}
@media screen and (max-height: 750px) {
  .cookie-confirm__top > span {
    font-size: 0.9375rem;
  }
}
.cookie-confirm__top > p {
  line-height: 1.35em;
}
@media screen and (max-height: 750px) {
  .cookie-confirm__top > p {
    display: none !important;
  }
}
.cookie-confirm__top > b {
  display: block;
  margin-top: 20px;
}
.cookie-confirm__top > b + p {
  margin-top: 0.5em;
}
.cookie-confirm__bottom {
  margin-top: 1rem;
}
.cookie-confirm__checkboxes {
  position: relative;
}
.cookie-confirm__checkboxes > * {
  position: relative;
  font-size: 0.9375rem;
  display: block;
  margin-top: 1rem;
}
@media screen and (max-height: 750px) {
  .cookie-confirm__checkboxes > * {
    margin-top: 0.5rem;
  }
}
.cookie-confirm__checkboxes > *:first-of-type {
  margin-top: 0 !important;
}
.cookie-confirm__checkboxes > * span {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.5em;
  color: #acacac;
  padding-left: 2em;
}
.cookie-confirm #cookie-confirm__submit,
.cookie-confirm #cookie-confirm__cancel {
  margin-top: 1rem;
}
@media screen and (min-width: 769px) {
  .cookie-confirm #cookie-confirm__submit,
.cookie-confirm #cookie-confirm__cancel {
    margin-top: 1.25rem;
  }
}
#cookie-revoke {
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  padding: 10px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 20px -10px #000000;
  box-shadow: 0px 0px 20px -10px #000000;
  cursor: pointer;
}

#cookie-revoke:hover {
  color: #e2001a;
}

html > body {
  /* handling phones and small screens */
}
html > body .featherlight {
  background: #2c2e36;
  background: rgba(44, 46, 54, 0);
}
html > body .featherlight:last-of-type {
  background: rgba(44, 46, 54, 0.9);
}
html > body .featherlight .featherlight-content {
  padding: 0;
  border-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  max-height: 95%;
  background: transparent;
  overflow-x: hidden;
}
@media screen and (min-width: 769px) {
  html > body .featherlight .featherlight-content {
    max-width: 1460px;
  }
}
@media screen and (max-width: 768px) {
  html > body .featherlight .featherlight-content {
    width: 100%;
  }
}
html > body .featherlight-inner {
  background: #FFFFFF;
}
html > body .featherlight .featherlight-close-icon {
  font-size: 0;
  line-height: 0;
  background: transparent;
  top: 20px;
  right: 20px;
  text-align: right;
}
html > body .featherlight .featherlight-close-icon:before {
  transition: color 0.2s linear;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #e2001a;
  font-size: 1.875rem;
  position: relative;
  right: -0.225em;
}
html > body .featherlight .featherlight-close-icon:hover:before {
  color: #af0014;
}
@media only screen and (max-width: 1520px) {
  html > body .featherlight .featherlight-content {
    /* dimensions: maximize lightbox with for small screens */
    margin-left: 0;
    margin-right: 0;
    max-height: 98%;
    padding: 10px 10px 0;
    border-bottom: 10px solid transparent;
  }
}

.footer {
  margin-top: 40px;
  background: #303239;
}
.footer__top {
  background: #e9e9e9;
  padding-bottom: 30.0751879699px;
}
@media screen and (max-width: 480px) {
  .footer__top {
    padding-bottom: 10px;
  }
}
.footer__bottom {
  padding: 10px 0;
  color: #ffffff;
  text-align: right;
}
.footer__cars * {
  font-size: 0.75rem;
}
@media screen and (max-width: 480px) {
  .footer__cars > ul {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .footer__cars > ul:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .footer__cars > ul:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .footer__cars > ul:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .footer__cars > ul {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .footer__cars > ul:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .footer__cars > ul:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .footer__cars > ul:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .footer__cars > ul {
    float: left;
    width: 23.2954545455%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .footer__cars > ul:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .footer__cars > ul:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .footer__cars > ul:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .footer__cars > ul {
    float: left;
    width: 14.7727272727%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .footer__cars > ul:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .footer__cars > ul:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .footer__cars > ul:nth-of-type(6n+7) {
    clear: both;
  }
}
.footer__cars > ul > li {
  margin-bottom: 20px;
  margin-top: -0.25em;
}
.footer__cars > ul > li:first-of-type {
  margin-top: 0;
}
.footer__cars > ul > li:first-of-type span {
  display: inline-block;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  background: #303239;
  padding: 0 0.25em;
}
.footer__cars > ul > li:first-of-type span a {
  color: #FFFFFF;
}
.footer__cars > ul > li:first-of-type span a:hover {
  color: #e2001a;
}
.footer__cars > ul > li li {
  font-size: 0.78125rem;
}
.footer__cars > ul > li li a {
  font-size: 0.78125rem;
}
.footer__cars + strong {
  display: block;
  text-align: right;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .footer__cars + strong {
    text-align: left;
    margin-top: 20px;
  }
}

.main__text + form {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .main__text + form {
    margin-top: 20px;
  }
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  color: inherit;
  line-height: 1.35em;
  outline: 0;
  border-radius: 0px;
  border: 0;
  box-shadow: none;
}

label {
  cursor: pointer;
}

input,
textarea,
select {
  border: 1px solid #aaaaaa;
  background: transparent;
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]) {
  display: block;
  padding: 10px 13.3333333333px;
  width: 100%;
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled],
textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled],
select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}
input:focus,
textarea:focus,
select:focus {
  outline: 0;
  border: 1px solid #e2001a;
}

input[type=submit],
input[type=reset] {
  width: auto;
}

input[disabled], input[readonly] {
  color: #aaaaaa;
  border-color: #aaaaaa;
  cursor: not-allowed;
}
input[disabled]:hover, input[readonly]:hover {
  color: #aaaaaa;
  border-color: #aaaaaa;
}

form .field {
  margin-bottom: 20px;
}
form .field:last-child {
  margin-bottom: 0;
}
form .field.checkbox {
  padding-left: 171px;
}
form .field.checkbox .right-title {
  padding-left: 0;
  clear: both;
  display: block;
}
@media screen and (max-width: 1036px) {
  form .field.checkbox {
    padding-left: 1.5em;
  }
}
form .field .middleColumn {
  float: left;
  width: calc(100% - 150px);
}
@media screen and (max-width: 1036px) {
  form .field .middleColumn {
    width: 100%;
  }
}
form .field.optionset > .middleColumn > div {
  margin-top: 10px;
}
form .field.optionset > .middleColumn > div:first-of-type {
  margin-top: 0;
}
form .field .message {
  display: block;
  margin-top: 20px;
}
form .field .description {
  display: block;
  margin-top: 10px;
  font-size: 0.75rem;
}
form .field .right-title, form .field > label.right {
  display: block;
  font-size: 0.75rem;
  margin-top: 10px;
  color: #aaaaaa;
  padding-left: 150px;
}
form .field > label.left, form .field > legend.left {
  display: block;
  font-weight: 400;
  float: left;
  width: 150px;
  padding: 10px 0;
  line-height: 1.35em;
}
@media screen and (max-width: 1036px) {
  form .field > label.left, form .field > legend.left {
    float: none;
    width: auto;
    padding-top: 0;
    margin-bottom: 0;
  }
}
form .field.requiredField > label.left:after {
  content: " *";
  color: #dd3c3c;
}
form .Actions {
  margin-top: 20px;
  padding-left: 150px;
}
@media screen and (max-width: 1036px) {
  form .Actions {
    padding-left: 0;
  }
}
form .field.nocaptcha {
  padding-left: 150px;
}
@media screen and (max-width: 1036px) {
  form .field.nocaptcha {
    padding-left: 0;
  }
}
form .userformsstep {
  margin-bottom: 0;
}

input[type=checkbox] {
  display: none;
  cursor: pointer;
}
input[type=checkbox] + label:before {
  margin-right: 0.5em;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f0c8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #303239;
}
input[type=checkbox]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f14a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
input[type=checkbox][disabled] {
  cursor: not-allowed;
}
input[type=checkbox][disabled] + label:before {
  cursor: not-allowed;
  color: #aaaaaa;
}
input[type=radio] {
  display: none;
  cursor: pointer;
}
input[type=radio] + label:before {
  margin-right: 0.5em;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f111";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #303239;
}
input[type=radio]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f192";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
input[type=radio][disabled] {
  cursor: not-allowed;
}
input[type=radio][disabled] + label:before {
  cursor: not-allowed;
  color: #aaaaaa;
}

@media screen and (min-width: 769px) {
  #MemberLoginForm_LoginForm,
#MemberLoginForm_LostPasswordForm {
    width: 50%;
  }
}

#MemberLoginForm_LoginForm #ForgotPassword {
  margin-top: 10px;
}

.header {
  position: relative;
  font-size: 0;
  padding: 20px;
  background: #ffffff;
}
@media screen and (max-width: 350px) {
  .header {
    padding: 20px 13.3333333333px;
  }
}
.header .header__left {
  position: absolute;
  top: 20px;
  left: 20px;
  white-space: nowrap;
}
@media screen and (max-width: 480px) {
  .header .header__left {
    top: 15px;
  }
}
@media screen and (max-width: 350px) {
  .header .header__left {
    left: 13.3333333333px;
  }
}
.header .header__left small {
  display: block;
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 40px;
  margin-left: 20px;
}
@media screen and (max-width: 480px) {
  .header .header__left small {
    display: none;
  }
}
.header .header__logo {
  display: block;
  width: 206px;
  margin: 0 auto;
  text-decoration: none;
}
.header .header__logo img {
  image-rendering: auto;
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .header .header__logo {
    transform: translateX(-134px);
  }
}
@media screen and (min-width: 581px) and (max-width: 768px) {
  .header .header__logo {
    transform: translateX(-67px);
  }
}
@media screen and (max-width: 480px) {
  .header .header__logo {
    width: 140px;
  }
  .header .header__logo img {
    width: 100%;
    height: auto;
  }
}
.header .header__logo .header__title {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  font-size: 1.875rem;
  color: #303239;
  line-height: 1;
}
.header .header__logo .header__slogan {
  position: relative;
  display: block;
  line-height: 1;
  font-size: 0;
  margin-top: 10px;
}
.header .header__logo .header__slogan:after {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -1px;
  display: block;
  content: "";
  height: 2px;
  background: #aaaaaa;
}
.header .header__logo .header__slogan span {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding-right: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  color: #303239;
  background: #ffffff;
  line-height: 1;
}
.header .header__right {
  position: absolute;
  top: 20px;
  right: 20px;
}
@media screen and (max-width: 480px) {
  .header .header__right {
    top: 14px;
  }
}
@media screen and (max-width: 350px) {
  .header .header__right {
    right: 13.3333333333px;
  }
}
.header .header__right-shop {
  width: 134px;
  float: left;
  margin: 0 30.0751879699px 0 0;
  font-size: 0.9375rem;
}
@media screen and (max-width: 768px) {
  .header .header__right-shop {
    margin: 0;
  }
}
@media screen and (max-width: 580px) {
  .header .header__right-shop {
    display: none;
  }
}
.header .social-media-icons {
  float: left;
}
@media screen and (max-width: 768px) {
  .header .social-media-icons {
    display: none;
  }
}
.header .language-switcher {
  float: left;
  margin-left: 30.0751879699px;
}

.page--no-slider .header {
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .page--no-slider .header {
    margin-bottom: 20px;
  }
}

.page-slider .homepage-teaser {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.homepage-teaser {
  position: relative;
  margin-top: 80px;
  margin-bottom: -80px;
}
@media screen and (max-width: 768px) {
  .homepage-teaser {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .homepage-teaser {
    margin-bottom: -40px;
  }
}
@media screen and (max-width: 768px) {
  .homepage-teaser {
    margin-bottom: 40px;
  }
}
.homepage-teaser__overlay--clickable {
  cursor: pointer;
}
.homepage-teaser__overlay img {
  width: 100%;
  height: auto;
}
.homepage-teaser__content {
  position: absolute;
  left: 80px;
  bottom: 140px;
  color: #ffffff;
  z-index: 500;
}
@media screen and (max-width: 1036px) {
  .homepage-teaser__content {
    left: 40px;
    bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .homepage-teaser__content {
    position: static;
    text-align: left;
    margin: 0 10px;
  }
}
.homepage-teaser__labels {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .homepage-teaser__labels {
    margin-bottom: 0;
  }
}
.homepage-teaser__labels .badge:last-of-type {
  background: #e2001a;
  margin-left: 5px;
}
.homepage-teaser__title {
  font-size: 3.125rem;
  text-transform: uppercase;
}
.homepage-teaser__title--color-dark span {
  color: #303239;
}
.homepage-teaser__title--color-light span {
  color: #ffffff;
}
.homepage-teaser__title span {
  font-weight: 300;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .homepage-teaser__title span {
    color: #303239;
    font-size: 1.875rem;
    display: block;
    margin-bottom: 13.3333333333px;
  }
}
@media screen and (max-width: 350px) {
  .homepage-teaser__title span {
    font-size: 1.125rem;
    margin-top: 5px;
  }
}
.homepage-teaser__link {
  position: absolute;
  left: 80px;
  bottom: 80px;
  z-index: 500;
}
@media screen and (max-width: 1036px) {
  .homepage-teaser__link {
    bottom: 40px;
    left: 40px;
  }
}
@media screen and (max-width: 768px) {
  .homepage-teaser__link {
    position: static;
    margin: 13.3333333333px 10px 0 10px;
  }
}
.language-switcher__link a {
  font-size: 0.75rem;
  display: block;
  line-height: 20px;
  transition: color 0.2s linear;
  font-weight: 700;
}
.language-switcher__link--current a, .language-switcher__link_hover a {
  color: #e2001a;
}

.latest-news > h2 {
  margin-bottom: 32px;
  font-size: 1.875rem;
  text-transform: uppercase;
  line-height: 1;
}
@media screen and (max-width: 480px) {
  .latest-news > h2 {
    margin-bottom: 20px;
  }
}
.latest-news .latest-news__archive-link {
  background: #e9e9e9;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -60px;
  width: 40px;
  height: 300px;
  line-height: 300px;
  text-align: center;
}
@media screen and (min-width: 1037px) and (max-width: 1840px) {
  .latest-news .latest-news__archive-link {
    height: 100%;
    line-height: 450px;
  }
}
@media screen and (max-width: 1036px) {
  .latest-news .latest-news__archive-link {
    height: auto;
    line-height: 1.35em;
    position: static;
    float: left;
    width: 100%;
    text-align: right;
    padding: 6.6666666667px;
    margin-top: 40px;
    font-size: 1.125rem;
  }
}
.latest-news__children {
  position: relative;
}
@media screen and (min-width: 1037px) and (max-width: 1900px) {
  .latest-news__children {
    width: calc(100% - (40px * 1.5));
  }
}
@media screen and (max-width: 480px) {
  .latest-news__child {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 4.5454545455%;
  }
  .latest-news__child:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .latest-news__child {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .latest-news__child:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .latest-news__child {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .latest-news__child:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(3n+4) {
    clear: both;
  }
}
.latest-news__child-image {
  position: relative;
  display: block;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  margin-bottom: 3px;
}
.latest-news__child-image:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.latest-news__child-image:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.latest-news__child-image:hover:before, .latest-news__child-image:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.latest-news__child-image:hover:before {
  left: -100px;
}
.latest-news__child-more {
  text-align: right;
  margin-top: 30.0751879699px;
}
@media screen and (max-width: 768px) {
  .latest-news__child-more {
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .latest-news__child-more {
    text-align: left;
    margin-top: 13.3333333333px;
  }
}
.latest-news__child img {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
}
.latest-news__child h3 {
  margin-top: 30.0751879699px;
  margin-bottom: 30.0751879699px;
  font-size: 1.125rem;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .latest-news__child h3 {
    margin-top: 13.3333333333px;
    margin-bottom: 13.3333333333px;
  }
}
.latest-news__child p {
  margin-top: -0.25em;
}

.loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: rgba(255, 255, 255, 0.8);
}
.loader .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -25px;
  display: block !important;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.loader .spinner > div {
  display: inline-block;
  height: 100%;
  width: 4px;
  background: #303239;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.masonry {
  border-top: 1px solid #aaaaaa;
  padding-top: 60px;
}
@media screen and (max-width: 768px) {
  .masonry {
    padding-top: 32px;
  }
}
@media screen and (max-width: 480px) {
  .masonry {
    padding-top: 20px;
  }
}
.masonry li {
  display: block;
  float: left;
  width: 31.8181818182%;
}
.masonry li a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  display: block;
}
.masonry li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.masonry li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.masonry li a:hover:before, .masonry li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.masonry li a:hover:before {
  left: -100px;
}
.masonry li img {
  display: block;
  width: 100%;
  height: auto;
}
.masonry li:nth-of-type(10n+1) {
  width: 65.9090909091%;
  margin-bottom: 40px;
  margin-right: 2.2727272727%;
}
.masonry li:nth-of-type(10n+3) {
  margin-bottom: 40px;
}
.masonry li:nth-of-type(10n+7) {
  width: 65.9090909091%;
  margin-bottom: 40px;
}
.masonry li:nth-of-type(10n+5) {
  margin-top: -17.9%;
}
.masonry li:nth-of-type(10n+8) {
  margin-top: -17.9%;
  margin-right: 2.2727272727%;
  margin-bottom: 40px;
}
.masonry li:nth-of-type(10n+3), .masonry li:nth-of-type(10n+6) {
  clear: both;
  margin-right: 2.2727272727%;
}
.masonry li:nth-of-type(10n+4), .masonry li:nth-of-type(10n+9) {
  margin-right: 2.2727272727%;
}
@media screen and (min-width: 901px) and (max-width: 1340px) {
  .masonry li:nth-of-type(10n+1), .masonry li:nth-of-type(10n+3), .masonry li:nth-of-type(10n+7), .masonry li:nth-of-type(10n+8) {
    margin-bottom: 30.0751879699px;
  }
}
@media screen and (min-width: 561px) and (max-width: 900px) {
  .masonry li:nth-of-type(10n+1), .masonry li:nth-of-type(10n+3), .masonry li:nth-of-type(10n+7), .masonry li:nth-of-type(10n+8) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 560px) {
  .masonry li:nth-of-type(10n+1), .masonry li:nth-of-type(10n+6), .masonry li:nth-of-type(10n+3), .masonry li:nth-of-type(10n+7), .masonry li:nth-of-type(10n+8), .masonry li:nth-of-type(10n+2), .masonry li:nth-of-type(10n+5), .masonry li:nth-of-type(10n+9), .masonry li:nth-of-type(10n+10) {
    margin-bottom: 10px;
  }
  .masonry li:nth-of-type(10n+3), .masonry li:nth-of-type(10n+4), .masonry li:nth-of-type(10n+9), .masonry li:nth-of-type(10n+10) {
    width: 48.8636363636%;
  }
  .masonry li:nth-of-type(10n+4) {
    margin-right: 0;
  }
  .masonry li:nth-of-type(10n+5), .masonry li:nth-of-type(10n+8) {
    margin-top: 0;
  }
  .masonry li:nth-of-type(10n+1), .masonry li:nth-of-type(10n+5), .masonry li:nth-of-type(10n+2), .masonry li:nth-of-type(10n+7), .masonry li:nth-of-type(10n+6), .masonry li:nth-of-type(10n+8) {
    width: 100%;
  }
}

.mobile-navigation {
  position: fixed;
  overflow-y: scroll;
  z-index: 9998;
  top: 0;
  bottom: 0;
  width: 290px;
  transition: left 0.17s linear;
  left: -290px;
  background: #303239;
  padding: 30.0751879699px;
  padding-right: 15.037593985px;
}
.mobile-navigation--active, .mobile-navigation--force-active {
  left: 0;
  margin-left: 0;
}
.mobile-navigation--active + .mobile-navigation-pusher, .mobile-navigation--force-active + .mobile-navigation-pusher {
  transform: translateX(290px);
}
.mobile-navigation--active + .mobile-navigation-pusher .mobile-navigation-button i:before, .mobile-navigation--force-active + .mobile-navigation-pusher .mobile-navigation-button i:before {
  content: "\f00d";
}
.mobile-navigation:not(.mobile-navigation--active) {
  left: -290px !important;
}
.mobile-navigation__top {
  margin-bottom: 40px;
}
.mobile-navigation__top a {
  color: #ffffff;
}
.mobile-navigation__top a:hover {
  color: #e2001a;
}
.mobile-navigation__top .shop-account-button {
  margin-bottom: 0.25em;
}

.mobile-navigation-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  opacity: 0;
  visibility: hidden;
  background: #000000;
  transition: all 0.2s ease;
}

.mobile-navigation-button {
  cursor: pointer;
  font-size: 1.875rem;
  color: #303239;
  transition: color 0.2s linear;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  float: left;
}
.mobile-navigation-button:hover {
  color: #e2001a;
}

.mobile-navigation-pusher {
  transition: transform 0.15s linear;
}

html.mobile-navigation-active {
  overflow-x: hidden;
}
html.mobile-navigation-active .mobile-navigation-pusher {
  transition: transform 0.2s linear;
}
html.mobile-navigation-active .mobile-navigation {
  transition: all 0.15s linear;
}

.mobile-navigation::-webkit-scrollbar {
  display: none;
}

.mobile-navigation {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  scrollbar-width: none;
}
.mobile-navigation .navigation--mobile {
  width: 100%;
}
.mobile-navigation__bottom {
  width: 100%;
  margin-top: auto;
}
.mobile-navigation__bottom-mobile {
  margin-top: 30.0751879699px;
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-navigation__bottom-mobile {
    display: block;
  }
}
.mobile-navigation__bottom-mobile .social-media-icons {
  float: left;
}
.mobile-navigation__bottom-mobile .social-media-icons ul li a {
  color: #aaaaaa;
  border-color: #aaaaaa;
}
.mobile-navigation__bottom-mobile .social-media-icons ul li a:hover {
  color: #e2001a;
  border-color: #e2001a;
}
.mobile-navigation__bottom-mobile .language-switcher {
  float: right;
  padding-right: 15.037593985px;
}
.mobile-navigation__bottom-mobile .language-switcher ul li a {
  color: #ffffff;
}
.mobile-navigation__bottom-mobile .language-switcher ul li a:hover {
  color: #e2001a;
}

.mobile-navigation-scrollbarhider {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  transition: all 0.2s linear;
}

@media (max-resolution: 1dppx) {
  html.mobile-navigation-active .mobile-navigation-scrollbarhider {
    content: "";
    display: block;
    top: -10px;
    bottom: -10px;
    left: 273px;
    width: 17px;
    background: #303239;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
  }
}
.navigation.navigation--footer {
  padding: 40px 0;
}
@media screen and (max-width: 768px) {
  .navigation.navigation--footer {
    padding: 26.6666666667px 0;
  }
}
@media screen and (max-width: 480px) {
  .navigation.navigation--footer {
    padding: 20px 0;
  }
}
.navigation.navigation--footer ul {
  justify-content: normal;
  display: inline-flex;
}
@media screen and (max-width: 480px) {
  .navigation.navigation--footer ul {
    display: block !important;
  }
}
.navigation.navigation--footer ul li {
  margin-right: 40px;
}
@media screen and (max-width: 480px) {
  .navigation.navigation--footer ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .navigation.navigation--footer ul li:last-of-type {
    margin-bottom: 0;
  }
}
.navigation.navigation--footer ul li:last-of-type {
  margin-right: 0;
}
.navigation.navigation--footer ul li a {
  text-transform: uppercase;
}

.navigation.navigation--main {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .navigation.navigation--main {
    margin-bottom: 20px;
  }
}
.navigation.navigation--main .navigation__top-link {
  font-size: 1.125rem;
  font-weight: 700;
}
.navigation.navigation--main.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  top: 1.125rem;
}

.navigation.navigation--mobile ul li {
  margin-bottom: 30.0751879699px;
}
.navigation.navigation--mobile ul li:hover > .navigation__sub {
  display: none;
  transform: translateX(0);
}
.navigation.navigation--mobile ul li a {
  color: #ffffff;
  text-transform: uppercase;
  transition: color 0.2s linear;
}
.navigation.navigation--mobile .navigation__sub {
  clear: both;
  position: static !important;
  display: none;
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  padding-top: 30.0751879699px;
  padding-left: 30.0751879699px;
}
.navigation.navigation--mobile .navigation__sub--active {
  display: block !important;
}
.navigation.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 20px);
}
.navigation.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > .navigation__sub-toggle {
  padding: 10px;
  width: calc(1em + 20px);
}
.navigation.navigation--mobile .navigation__sub .navigation__sub-link:last-of-type {
  margin-bottom: 0;
}
.navigation.navigation--mobile .navigation__sub-toggle {
  display: block;
  float: left;
  cursor: pointer;
  color: #aaaaaa;
  line-height: 1;
  text-align: right;
  transition: color 0.2s linear;
}
.navigation.navigation--mobile .navigation__sub-toggle:hover {
  color: #e2001a;
}
.navigation.navigation--mobile .navigation__sub-toggle--active i:before {
  content: "\f068";
}
.navigation.navigation--mobile .navigation__top-link > a {
  position: relative;
  font-size: 1.125rem;
}
.navigation.navigation--mobile .navigation__top-link--current > a:before, .navigation.navigation--mobile .navigation__top-link--section > a:before {
  position: absolute;
  top: 50%;
  left: -30.0751879699px;
  display: block;
  content: "";
  margin-top: -1px;
  width: 20px;
  height: 2px;
  background: #e2001a;
}
.navigation.navigation--mobile .navigation__top-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 20px);
}
.navigation.navigation--mobile .navigation__top-link > .navigation__sub-toggle {
  width: calc(1em + 20px);
}
.navigation.navigation--mobile .navigation__top-link > .navigation__sub a {
  font-size: 0.9375rem;
  font-weight: 700;
}
.navigation.navigation--mobile-footer ul li {
  margin-bottom: 15.037593985px;
}
.navigation.navigation--mobile-footer ul li:last-of-type {
  margin-bottom: 0;
}
.navigation.navigation--mobile-footer ul li a {
  color: #aaaaaa;
}

.navigation {
  position: relative;
  z-index: 500;
}
.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation ul li {
  position: relative;
  line-height: 1;
  font-size: 0.9375rem;
}
.navigation ul li[class*="--current"] > a, .navigation ul li[class*="--section"] > a {
  color: #e2001a;
}
.navigation ul li a {
  display: block;
  color: #303239;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  transition: color 0.2s linear;
}
.navigation ul li a:hover {
  color: #e2001a;
}
.navigation .navigation__sub {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s linear, visibility 0.1s linear;
}
.navigation--vertical .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--vertical ul li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top {
  font-size: 0;
}
.navigation--horizontal .navigation__top {
  display: flex;
  justify-content: space-between;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
  top: 0.9375rem;
}
.navigation--horizontal .navigation__top-link > .navigation__sub {
  left: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub {
  min-width: 100%;
}
.navigation--horizontal .navigation__top-link .navigation__sub .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub {
  left: auto;
  right: 0;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub .navigation__sub {
  transform: translateX(-100%);
  right: auto;
  left: 0;
}

.newsletter {
  position: relative;
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  .newsletter {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .newsletter {
    margin-bottom: 40px;
  }
}
.newsletter + .footer {
  margin-top: 0;
}
.newsletter__title, .newsletter__link {
  position: absolute;
  top: 80px;
  right: 80px;
  text-align: right;
}
@media screen and (max-width: 1036px) {
  .newsletter__title, .newsletter__link {
    top: 40px;
    right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .newsletter__title, .newsletter__link {
    position: static;
    text-align: left;
    margin-left: 10px;
  }
}
.newsletter__title span, .newsletter__link span {
  display: block;
  white-space: nowrap;
  font-size: 3.125rem;
  font-weight: 300;
  color: #FFFFFF;
  text-transform: uppercase;
  line-height: 1;
  margin-top: -0.25em;
}
@media screen and (max-width: 768px) {
  .newsletter__title span, .newsletter__link span {
    font-size: 1.875rem;
    color: #303239;
    margin-bottom: 13.3333333333px;
  }
}
@media screen and (max-width: 350px) {
  .newsletter__title span, .newsletter__link span {
    font-size: 1.125rem;
  }
}
.newsletter__link {
  top: 140px;
}
@media screen and (max-width: 1036px) {
  .newsletter__link {
    top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .newsletter__link {
    margin-top: 13.3333333333px;
  }
}
.newsletter img {
  display: block;
  width: 100%;
  height: auto;
}

.page-media {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .page-media {
    margin-top: 32px;
  }
}
@media screen and (max-width: 480px) {
  .page-media {
    margin-top: 20px;
  }
}
.page-media__title {
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .page-media__title {
    margin-bottom: 20px;
  }
}
.page-media__images {
  margin: 0;
  padding: 0;
}
.page-media__images li {
  list-style: none !important;
}
@media screen and (max-width: 768px) {
  .page-media__images li {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .page-media__images li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .page-media__images li {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .page-media__images li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .page-media__images li {
    float: left;
    width: 14.7727272727%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .page-media__images li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(6n+7) {
    clear: both;
  }
}
.page-media__images li a {
  display: block;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.page-media__images li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.page-media__images li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.page-media__images li a:hover:before, .page-media__images li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.page-media__images li a:hover:before {
  left: -100px;
}
.page-media__images li a img {
  display: block;
  width: 100%;
  height: auto;
}
.page-media__images + .page-media__files {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .page-media__images + .page-media__files {
    margin-top: 20px;
  }
}
.page-media__files {
  margin: 0;
  padding: 0;
}
.page-media__files li {
  list-style: none !important;
  display: block;
  border-bottom: 1px dashed #aaaaaa;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.page-media__files li:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.page-media__files li a {
  display: block;
  width: calc(100% - 100px);
  float: left;
  color: #303239;
  text-decoration: none;
}
.page-media__files li a:hover {
  color: #e2001a;
}
.page-media__files li a i {
  margin-right: 0.5em;
  font-size: 1.125rem;
  color: #e2001a;
}
.page-media__files li span {
  display: block;
  width: 100px;
  text-align: right;
  float: right;
  color: #aaaaaa;
}

.page-slider {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 1036px) {
  .page-slider {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .page-slider {
    margin-bottom: 13.3333333333px;
  }
}
.page-slider__arrows {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.page-slider__arrows .outer-wrapper {
  font-size: 0;
  text-align: right;
  margin-bottom: 30.0751879699px;
}
@media screen and (max-width: 480px) {
  .page-slider__arrows .outer-wrapper {
    margin-bottom: 20px;
  }
}
.page-slider--carousel .slick-slide {
  padding-right: 10px;
}
.page-slider--carousel .slick-slide img {
  border: 1px solid #aaaaaa;
}
.page-slider .slick-arrow {
  display: inline-block;
  color: #303239;
  cursor: pointer;
  font-size: 0.9375rem;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  border: 1px solid #aaaaaa;
  transition: all 0.2s linear;
}
.page-slider .slick-arrow:first-of-type {
  margin-right: 26.0751879699px;
}
@media screen and (max-width: 480px) {
  .page-slider .slick-arrow:first-of-type {
    margin-right: 10px;
  }
}
.page-slider .slick-arrow:hover {
  color: #e2001a;
}
.page-slider .slick-dots {
  position: absolute;
  z-index: 20;
  bottom: 20px;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
}
.page-slider .slick-dots li {
  display: inline-block;
  margin-right: 2px;
}
.page-slider .slick-dots li:last-of-type {
  margin-right: 0;
}
.page-slider .slick-dots li:last-of-type:first-of-type {
  display: none;
}
.page-slider .slick-dots li button {
  cursor: pointer;
  display: block;
  width: 13.3333333333px;
  height: 13.3333333333px;
  background: #e9e9e9;
  border-radius: 100%;
  font-size: 0;
  border: 0;
  box-shadow: none;
}
.page-slider .slick-dots li button:hover {
  background: #303239;
}
.page-slider .slick-dots li.slick-active button {
  background: #303239;
}
.page-slider .slick-dots li.slick-active button:hover {
  background: #191a1d;
}
.page-slider__slide {
  position: relative;
  max-height: 600px;
}
.page-slider__slide img {
  display: block;
  width: 100%;
  height: auto;
}
.page-slider__caption {
  z-index: 10;
  left: 0;
  right: 0;
}
.page-slider__caption--position-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.page-slider__caption--position-top-left {
  position: absolute;
  top: 80px;
}
.page-slider__caption--position-top-left h3 {
  margin-top: -0.25em;
}
@media screen and (max-width: 1036px) {
  .page-slider__caption--position-top-left {
    top: 40px;
  }
}
.page-slider__caption-content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1760px;
}
.page-slider__caption-content > h3 {
  font-size: 3.125rem;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .page-slider__caption-content > h3 {
    font-size: 1.875rem;
  }
}
.page-slider__caption-content > h3 span {
  color: #303239;
}
.page-slider__caption-content > p {
  margin-top: 40px;
  font-size: 1.125rem;
  font-weight: 700;
  color: #ffffff;
  max-width: 1173.3333333333px;
}
@media screen and (max-width: 768px) {
  .page-slider__caption-content > p {
    margin-top: 20px;
  }
}
.page-slider__caption-content > p span {
  box-decoration-break: clone;
}
.page-slider__caption-content > a {
  margin-top: 40px;
  display: inline-block;
  color: #e2001a;
}
@media screen and (max-width: 768px) {
  .page-slider__caption-content > a {
    margin-top: 20px;
  }
}
.page-slider__caption-content.page-slider__caption-content--font-color-light > h3, .page-slider__caption-content.page-slider__caption-content--font-color-light > h3 span, .page-slider__caption-content.page-slider__caption-content--font-color-light > p, .page-slider__caption-content.page-slider__caption-content--font-color-light > a {
  color: #ffffff;
}

#app_homepage .page-slider__slide {
  max-height: 100%;
}

.page-title {
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .page-title {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 480px) {
  .page-title {
    margin-bottom: 20px;
  }
}
.page-title h1 {
  line-height: 1;
  font-size: 1.875rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-top: -0.25em;
  float: left;
}
@media screen and (max-width: 480px) {
  .page-title h1 {
    float: none;
  }
}
.page-title .badge {
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .page-title .badge {
    margin-bottom: 13.3333333333px;
  }
}
.page-title .badge + h1 {
  margin-top: 0;
}
.page-title .breadcrumbs {
  margin-bottom: 40px;
  font-size: 0.75rem;
}
@media screen and (max-width: 768px) {
  .page-title .breadcrumbs {
    margin-bottom: 20px;
  }
}
.page-title .breadcrumbs a {
  transition: color 0.2s linear;
  margin-right: 30.0751879699px;
  white-space: nowrap;
}
.page-title .breadcrumbs a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f060";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #e2001a;
  font-size: 0.4375rem;
  position: relative;
  top: -1px;
}
.page-title .breadcrumbs a:first-of-type:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f33e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 0.5625rem;
}
.page-title .breadcrumbs strong {
  white-space: nowrap;
}

.pagination {
  text-align: center;
  padding-top: 40px;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  .pagination {
    padding-top: 20px;
  }
}
.pagination:before {
  display: block;
  content: "";
  border-top: 1px solid #aaaaaa;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .pagination:before {
    margin-bottom: 20px;
  }
}
.pagination > :last-child {
  margin-right: 0;
}
.pagination a,
.pagination span {
  font-size: 0.9375rem;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  border-radius: 100%;
  border: 1px solid #303239;
  transition: all 0.2s linear;
  margin-right: 10px;
}
.pagination strong {
  display: inline-block;
  font-size: 0.9375rem;
  line-height: 40px;
  margin: 0 20px 0 10px;
}
.pagination a {
  color: #303239;
  text-decoration: none;
  transition: all 0.2s;
}
.pagination a:hover {
  border-color: #aaaaaa;
  color: #e2001a;
}
.pagination span.pagination__current {
  background: #303239;
  color: #FFFFFF;
}

.product-price span {
  display: inline-block;
}
.product-price__old {
  color: #aaaaaa;
  text-decoration: line-through;
  margin-right: 10px;
}

.basic-holder__child--app_productpage {
  position: relative;
}
.basic-holder__child--app_productpage .product-soldout {
  position: absolute;
  top: 10px;
  right: 1px;
  z-index: 1;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0.125em 0.35em;
  color: #ffffff;
  background: #303239;
}

.rims {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .rims {
    margin-top: 40px;
  }
}
@media screen and (max-width: 480px) {
  .rims {
    margin-top: 20px;
  }
}
.rims > h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.125rem;
}
.rims__select {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  font-size: 0;
}
@media screen and (max-width: 768px) {
  .rims__select {
    margin-top: 10px;
  }
}
.rims__select:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: "";
  height: 1px;
  background: #aaaaaa;
  z-index: 10;
}
.rims__select li {
  position: relative;
  z-index: 15;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
  text-transform: uppercase;
  transition: border-color 0.2s linear;
  margin-right: 20px;
}
.rims__select li:hover, .rims__select li.active {
  border-color: #e2001a;
}
.rims__select li:hover span, .rims__select li.active span {
  color: #e2001a;
}
.rims__select li span {
  font-size: 0.9375rem;
}
.rims__transparent, .rims__solid {
  display: none;
}
.rims__transparent.active, .rims__solid.active {
  display: block;
}
.rims__transparent .colorColHead, .rims__solid .colorColHead {
  margin-bottom: 20px;
}
.rims__transparent .colorColHeadLeft, .rims__solid .colorColHeadLeft {
  float: left;
  width: 277px;
  margin-right: 20px;
}
.rims__transparent .colorColHeadRight, .rims__solid .colorColHeadRight {
  float: right;
  width: calc(100% - 297px);
}
@media screen and (max-width: 480px) {
  .rims__transparent .colorCol, .rims__solid .colorCol {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .rims__transparent .colorCol:nth-of-type(-n + 3), .rims__solid .colorCol:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .rims__transparent .colorCol:nth-of-type(3n+3), .rims__solid .colorCol:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .rims__transparent .colorCol:nth-of-type(3n+4), .rims__solid .colorCol:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .rims__transparent .colorCol, .rims__solid .colorCol {
    float: left;
    width: 23.2954545455%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .rims__transparent .colorCol:nth-of-type(-n + 4), .rims__solid .colorCol:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .rims__transparent .colorCol:nth-of-type(4n+4), .rims__solid .colorCol:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .rims__transparent .colorCol:nth-of-type(4n+5), .rims__solid .colorCol:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .rims__transparent .colorCol, .rims__solid .colorCol {
    float: left;
    width: 14.7727272727%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .rims__transparent .colorCol:nth-of-type(-n + 6), .rims__solid .colorCol:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .rims__transparent .colorCol:nth-of-type(6n+6), .rims__solid .colorCol:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .rims__transparent .colorCol:nth-of-type(6n+7), .rims__solid .colorCol:nth-of-type(6n+7) {
    clear: both;
  }
}
.rims__transparent .colorImgContainer, .rims__solid .colorImgContainer {
  position: relative;
}
.rims__transparent .colorImgContainer img, .rims__solid .colorImgContainer img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
}
.rims__transparent .colorImgContainer img:last-of-type, .rims__solid .colorImgContainer img:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.rims__transparent .colorImgContainer:hover img:last-of-type, .rims__solid .colorImgContainer:hover img:last-of-type {
  z-index: 15;
}

.has-sidebar .main {
  float: left;
  width: 74.4318181818%;
}
.has-sidebar .sidebar {
  float: right;
  margin-left: 2.2727272727%;
  width: 23.2954545455%;
}
@media screen and (min-width: 1037px) {
  .has-sidebar .sidebar + * {
    padding-top: 40px;
    clear: both;
  }
}
@media screen and (max-width: 1036px) {
  .has-sidebar .main,
.has-sidebar .sidebar {
    float: none;
    width: 100%;
  }
  .has-sidebar .sidebar {
    margin-left: 0;
    margin-top: 40px;
  }
}
@media screen and (max-width: 1036px) and (max-width: 768px) {
  .has-sidebar .sidebar {
    margin-top: 20px;
  }
}
@media screen and (min-width: 609px) and (max-width: 1036px) {
  .has-sidebar .s-widget {
    width: 48.8636363636%;
    float: left;
  }
  .has-sidebar .s-widget:nth-of-type(2n+2) {
    margin-left: 2.2727272727%;
  }
  .has-sidebar .s-widget:nth-of-type(2n+3) {
    clear: both;
  }
}

.s-widget {
  margin-bottom: 40px;
}
.s-widget:last-of-type {
  margin-bottom: 0;
}
.s-widget__inner {
  font-size: 1rem;
}
.s-widget__title strong {
  font-size: 0.9375rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}
.s-widget__nav ul li {
  padding-left: 1.25em;
}
.s-widget__nav ul li:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-left: -1.25em;
  color: #aaaaaa;
}
.s-widget__nav ul li a {
  color: #303239;
  text-decoration: none;
}
.s-widget__nav ul li a:hover {
  color: #e2001a;
}
.s-widget__text .global-sc-dist-right + a {
  color: #303239;
  text-decoration: none;
}
.s-widget__text .global-sc-dist-right + a:hover {
  color: #e2001a;
}
.s-widget__images li {
  margin-bottom: 40px;
}
.s-widget__images li img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 270px;
  margin: 0 auto;
}
.s-widget__images li a {
  display: block;
  max-width: 270px;
  margin: 0 auto;
}

.social-media-icons ul li {
  display: inline-block;
  margin-right: 10px;
}
.social-media-icons ul li:last-of-type {
  margin-right: 0;
}
.social-media-icons ul li a {
  font-size: 0.9375rem;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #303239;
  border-radius: 100%;
  transition: border-color 0.2s linear, color 0.2s linear;
}
.social-media-icons ul li a:hover {
  border-color: #aaaaaa;
}

.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 20px;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background: #e2001a;
  color: #ffffff !important;
  border-radius: 0px;
}
.back-to-top:hover {
  background: #af0014;
}
.back-to-top i:before {
  position: relative;
  top: -1px;
}

.tuning-parts-categories {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .tuning-parts-categories {
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  .tuning-parts-categories {
    margin-top: 26.6666666667px;
  }
}
.tuning-parts-categories .sticky-wrapper {
  margin-bottom: 40px;
  background: #ffffff;
}
@media screen and (max-width: 768px) {
  .tuning-parts-categories .sticky-wrapper {
    display: none !important;
  }
}
.tuning-parts-categories .sticky-wrapper .tuning-parts-categories__tabs {
  margin-bottom: 0;
  background: #ffffff;
  z-index: 50 !important;
}
.tuning-parts-categories__tabs {
  margin-bottom: 40px;
  background: #ffffff;
}
@media screen and (max-width: 768px) {
  .tuning-parts-categories__tabs {
    display: none !important;
  }
}
.tuning-parts-categories__tabs ul {
  position: relative;
  font-size: 0;
  margin-top: -20px;
  margin-left: -40px;
}
.tuning-parts-categories__tabs ul:after {
  position: absolute;
  left: 40px;
  right: 0;
  display: block;
  content: "";
  height: 1px;
  background: #aaaaaa;
}
.tuning-parts-categories__tabs ul li {
  cursor: pointer;
  position: relative;
  z-index: 10;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.125rem;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: -1px;
  margin-left: 40px;
  margin-top: 20px;
  transition: border-color 0.2s linear;
}
@media screen and (max-width: 1036px) {
  .tuning-parts-categories__tabs ul li {
    width: calc(50% - 40px);
  }
}
@media screen and (max-width: 768px) {
  .tuning-parts-categories__tabs ul li {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 480px) {
  .tuning-parts-categories__tabs ul li {
    font-size: 0.9375rem;
    padding-bottom: 10px;
  }
}
.tuning-parts-categories__tabs ul li.active {
  border-color: #e2001a;
  font-weight: 700;
}
.tuning-parts-categories__tabs ul li:hover {
  border-color: #e2001a;
}
.tuning-parts-categories__content > div {
  display: block;
  margin-bottom: 80px;
  min-height: 400px;
}
@media screen and (max-width: 768px) {
  .tuning-parts-categories__content > div {
    margin-bottom: 40px;
  }
}
.tuning-parts-categories__content > div > h3 {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 1.875rem;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .tuning-parts-categories__content > div > h3 {
    margin-bottom: 20px;
  }
}
.tuning-parts-categories__content > div ul li {
  font-size: 1.125rem;
}
@media screen and (max-width: 480px) {
  .tuning-parts-categories__content > div ul li {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 480px) {
  .tuning-parts-categories__content > div ul li {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 1036px) {
  .tuning-parts-categories__content > div ul li {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .tuning-parts-categories__content > div ul li {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .tuning-parts-categories__content > div ul li:nth-of-type(3n+4) {
    clear: both;
  }
}
.tuning-parts-categories__content > div ul li img {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
}
.tuning-parts-categories__content > div ul li a:first-of-type {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  border: 1px solid #aaaaaa;
}
.tuning-parts-categories__content > div ul li a:first-of-type:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.tuning-parts-categories__content > div ul li a:first-of-type:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.tuning-parts-categories__content > div ul li a:first-of-type:hover:before, .tuning-parts-categories__content > div ul li a:first-of-type:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.tuning-parts-categories__content > div ul li a:first-of-type:hover:before {
  left: -100px;
}
.tuning-parts-categories__content > div ul li a:last-of-type {
  display: block;
  margin-top: 20px;
  font-weight: 700;
  transition: color 0.2s linear;
}
.tuning-parts-categories__content > div ul li a:last-of-type:hover {
  color: #e2001a;
}
.tuning-parts-categories__content > div ul li a:last-of-type:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-left: 0.25em;
  color: #e2001a;
}
.tuning-parts-categories__content > div ul li span {
  display: block;
  color: #aaaaaa;
  margin-top: 6.6666666667px;
}

.tuning-part-popup--prev, .tuning-part-popup--next {
  position: absolute;
  top: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #aaaaaa;
  font-size: 1.125rem;
  transition: color 0.2s linear;
}
.tuning-part-popup--prev:hover, .tuning-part-popup--next:hover {
  color: #e2001a;
}
.tuning-part-popup--prev {
  left: -100px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup--prev {
    left: -6.6666666667px;
  }
}
@media screen and (max-width: 350px) {
  .tuning-part-popup--prev {
    left: -10px;
  }
}
.tuning-part-popup--next {
  right: -100px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup--next {
    right: -6.6666666667px;
  }
}
@media screen and (max-width: 350px) {
  .tuning-part-popup--next {
    right: -10px;
  }
}
.tuning-part-popup__header {
  margin-bottom: 40px;
}
@media screen and (max-width: 350px) {
  .tuning-part-popup__header {
    margin-bottom: 20px;
  }
}
.tuning-part-popup__header li {
  display: block;
  float: right;
}
@media screen and (min-width: 769px) {
  .tuning-part-popup__header li {
    max-width: 730px;
  }
}
.tuning-part-popup__header li img {
  display: block;
  width: 100%;
  height: auto;
}
.tuning-part-popup__first-img {
  border-bottom: 1px solid #aaaaaa;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__first-img {
    width: 100%;
  }
}
.tuning-part-popup__second-img {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__second-img {
    width: 100%;
  }
}
.tuning-part-popup__content {
  margin: 0 120px;
  padding-bottom: 60px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__content {
    padding-bottom: 26.6666666667px;
    margin: 0 26.6666666667px;
  }
}
@media screen and (max-width: 350px) {
  .tuning-part-popup__content {
    margin: 0 20px;
  }
}
.tuning-part-popup__content > h2 {
  font-size: 1.875rem;
  text-transform: uppercase;
}
@media screen and (max-width: 350px) {
  .tuning-part-popup__content > h2 {
    font-size: 1.125rem;
  }
}
.tuning-part-popup__content > span {
  display: block;
  font-size: 1.125rem;
  color: #aaaaaa;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__content > span {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 350px) {
  .tuning-part-popup__content > span {
    font-size: 0.9375rem;
  }
}
.tuning-part-popup__colors {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__colors {
    margin-top: 40px;
  }
}
@media screen and (max-width: 480px) {
  .tuning-part-popup__colors {
    margin-top: 20px;
  }
}
.tuning-part-popup__colors li {
  display: block;
  float: left;
  width: 49.5%;
  margin-right: 1%;
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__colors li {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__colors li {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__colors li:first-of-type {
    margin-top: 0 !important;
  }
}
.tuning-part-popup__colors li:nth-of-type(-n+2) {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup__colors li:nth-of-type(-n+2) {
    margin-top: 60px;
  }
}
.tuning-part-popup__colors li:nth-of-type(2n+2) {
  margin-right: 0;
}
.tuning-part-popup__colors li:nth-of-type(2n+3) {
  clear: both;
}
.tuning-part-popup__colors li img {
  display: block;
  float: left;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}
@media screen and (max-width: 350px) {
  .tuning-part-popup__colors li img {
    width: 100px;
    height: 100px;
  }
}
.tuning-part-popup__colors li > span {
  display: block;
  float: left;
  margin-left: 20px;
  width: calc(100% - 190px);
  color: #aaaaaa;
  line-height: 150px;
}
@media screen and (max-width: 350px) {
  .tuning-part-popup__colors li > span {
    width: calc(100% - (100px + (40px / 4)));
    line-height: 100px;
    margin-left: 10px;
  }
}
.tuning-part-popup__colors li > span span {
  display: inline-block;
}
.tuning-part-popup__colors li > span strong {
  display: block;
  text-transform: uppercase;
  color: #303239;
}
.tuning-part-popup--small {
  width: 730px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup--small {
    width: 100%;
  }
}
.tuning-part-popup--small .tuning-part-popup__header .tuning-part-popup__first-img {
  width: 100% !important;
}
.tuning-part-popup--small .tuning-part-popup__content {
  margin: 0 80px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup--small .tuning-part-popup__content {
    margin: 0 40px;
  }
}
@media screen and (max-width: 480px) {
  .tuning-part-popup--small .tuning-part-popup__content {
    margin: 0 20px;
  }
}
.tuning-part-popup--small .tuning-part-popup--prev {
  left: -60px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup--small .tuning-part-popup--prev {
    left: -20px;
  }
}
@media screen and (max-width: 350px) {
  .tuning-part-popup--small .tuning-part-popup--prev {
    left: -10px;
  }
}
.tuning-part-popup--small .tuning-part-popup--next {
  right: -60px;
}
@media screen and (max-width: 768px) {
  .tuning-part-popup--small .tuning-part-popup--next {
    right: -20px;
  }
}
@media screen and (max-width: 350px) {
  .tuning-part-popup--small .tuning-part-popup--next {
    right: -10px;
  }
}
.tuning-part-popup--small .tuning-part-popup__colors li {
  width: 100%;
  margin-right: 0;
  margin-top: 40px;
}
.tuning-part-popup--small .tuning-part-popup__colors li:first-of-type {
  margin-top: 0;
}
.tuning-part-popup--small .tuning-part-popup__colors li:nth-of-type(2) {
  margin-top: 40px;
}

html .userform-fields {
  margin-top: 0 !important;
}
html .userform-progress {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  html .userform-progress {
    margin-bottom: 20px;
  }
}
html .userform-progress > p {
  display: none;
}
html .userform-progress .progress {
  background: #e9e9e9;
  height: 26.6666666667px;
}
html .userform-progress .progress-bar {
  background: #303239;
  height: 26.6666666667px;
}
html .userform-progress > nav {
  margin-top: -26.6666666667px;
}
html .userform-progress > nav ul {
  font-size: 0;
}
html .userform-progress > nav ul li {
  font-size: 0.9375rem;
  height: 26.6666666667px;
}
html .userform-progress > nav ul li button {
  display: block;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 26.6666666667px;
  min-width: 40px;
  text-align: center;
  background: #ffffff;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  color: #303239;
}
html .userform-progress > nav ul li:first-of-type button {
  border-left: 0 !important;
}
html .userform-progress > nav ul li:last-of-type button {
  border-right: 0 !important;
}
html .userform-progress > nav ul li.viewed button {
  cursor: pointer;
}
html .userform .progress-title {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  html .userform .progress-title {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
html .userform .FormHeading {
  margin-bottom: 20px;
}
html .userform .step-navigation ul {
  font-size: 0;
}
html .userform .step-navigation ul li {
  font-size: 0.9375rem;
}
html .userform .step-navigation ul li button.step-button-prev:not([style="display: none;"]) {
  margin-right: 10px;
}
html .userform .userformsgroup {
  margin-top: 20px;
  border-top: 1px solid #aaaaaa;
  border-bottom: 1px solid #aaaaaa;
  padding-top: 20px;
  padding-bottom: 20px;
}
html .userform .userformsgroup > .middleColumn {
  width: 100%;
}
html .userform .userformsgroup > legend {
  padding-left: 0;
  padding-right: 10px;
  border: 0;
  width: auto;
  font-weight: 700;
}
html .userform .step-button-wrapper.Actions {
  margin-top: 0;
  float: right;
}

#Form_AddToCartForm .field .message {
  margin-top: 13.3333333333px;
}
#Form_AddToCartForm .system-message + *,
#Form_AddToCartForm .message:not([style*="display: none"]) + * {
  margin-top: 26.6666666667px !important;
}
#Form_AddToCartForm #Form_ItemEditForm_StockNotice {
  margin-bottom: 26.6666666667px !important;
}
#Form_AddToCartForm .btn-toolbar > span {
  display: inline-block;
  font-size: 0.75rem;
  color: #aaaaaa;
  margin-left: 0.5em;
  position: relative;
  top: -0.125em;
}

.address--default-invoice, .address--default-shipping {
  background: #e9e9e9;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0px;
  padding: 6.6666666667px 10px;
}
.address__defaults {
  padding-bottom: 10px;
}
.address__name:before, .address__phone:before, .address__company:before, .address__line1:before {
  width: auto;
  min-width: 1em;
  margin-right: 0.5em;
}
.address__name {
  font-weight: 700;
}
.address__name:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f007";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
.address__phone:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f095";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
.address__company:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f1ad";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
.address__line1:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f041";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
.address__line2, .address__zip-city, .address__state, .address__country {
  padding-left: 1.5em;
}
.address__actions {
  margin-top: 10px;
}

.address__actions--default-invoice.button:before,
.address--default-invoice:before,
.address__actions--default-shipping.button:before,
.address--default-shipping:before,
.address__actions--delete.button:before {
  width: auto;
  margin-right: 0.5em;
}

.address__actions--default-invoice.button:before,
.address--default-invoice:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f570";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-right: 0.5em;
}

.address__actions--default-shipping.button:before,
.address--default-shipping:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f48b";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}

.address__actions--delete.button:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f2ed";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}

#Form_UpdateCartForm_action_doUpdateCart {
  float: left;
}

#Form_UpdateCartForm_action_goToCheckout {
  float: right;
}

#Form_UpdateCartForm .btn-toolbar {
  margin-top: 40px;
}
#Form_UpdateCartForm > fieldset > ul li {
  display: block;
  float: left;
  font-weight: 700;
  line-height: 1;
  margin-right: 20px;
}
#Form_UpdateCartForm > fieldset > ul li:last-child {
  margin-right: 0;
}
#Form_UpdateCartForm > fieldset > ul:first-of-type {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #aaaaaa;
}
#Form_UpdateCartForm > fieldset > ul:first-of-type li:first-child {
  width: calc(100% - 420px);
}
#Form_UpdateCartForm > fieldset > ul:first-of-type li:nth-child(2) {
  width: 100px;
}
#Form_UpdateCartForm > fieldset > ul:first-of-type li:nth-child(3) {
  width: 100px;
}
#Form_UpdateCartForm > fieldset > ul:first-of-type li:nth-child(4) {
  width: 100px;
}
#Form_UpdateCartForm > fieldset > ul:first-of-type li:nth-child(5) {
  width: 40px;
  text-align: center;
}
#Form_UpdateCartForm > fieldset > ul:last-of-type {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #aaaaaa;
}
#Form_UpdateCartForm > fieldset > ul:last-of-type li:first-of-type {
  width: calc(100% - 420px);
}
#Form_UpdateCartForm > fieldset > ul:last-of-type li:last-of-type {
  float: right;
  width: 400px;
}
#Form_UpdateCartForm > fieldset > div:nth-last-of-type(2) {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
#Form_UpdateCartForm > fieldset .cart-form__item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #aaaaaa;
}
#Form_UpdateCartForm > fieldset .cart-form__item > * {
  float: left;
  margin-right: 20px;
  margin-bottom: 0;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:not(.numeric) {
  line-height: 1.35em;
  padding-top: 11px;
  padding-bottom: 11px;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *.numeric {
  padding-right: 20px;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *.checkbox label {
  margin: 0;
  padding: 0;
  text-align: center;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *.checkbox label:before {
  margin-right: 0;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:first-child {
  width: calc(100% - 420px);
  font-weight: 700;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:nth-child(2) {
  width: 100px;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:nth-child(3) {
  width: 100px;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:nth-child(4) {
  width: 100px;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:nth-child(5) {
  width: 40px;
  text-align: center;
}
#Form_UpdateCartForm > fieldset .cart-form__item > *:last-child {
  margin-right: 0;
}

@media screen and (max-width: 350px) {
  body:not(.has-sidebar) .shop-categories .shop-category {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 4.5454545455%;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  body:not(.has-sidebar) .shop-categories .shop-category {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  body:not(.has-sidebar) .shop-categories .shop-category {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body:not(.has-sidebar) .shop-categories .shop-category:nth-of-type(3n+4) {
    clear: both;
  }
}

@media screen and (max-width: 350px) {
  body.has-sidebar .shop-categories .shop-category {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 4.5454545455%;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  body.has-sidebar .shop-categories .shop-category {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  body.has-sidebar .shop-categories .shop-category {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  body.has-sidebar .shop-categories .shop-category {
    float: left;
    width: 31.2977099237%;
    margin-right: 3.0534351145%;
    margin-top: 3.0534351145%;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-categories .shop-category:nth-of-type(3n+4) {
    clear: both;
  }
}

.shop-category > a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.shop-category > a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.shop-category > a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.shop-category > a:hover:before, .shop-category > a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.shop-category > a:hover:before {
  left: -100px;
}
.shop-category > a img {
  display: block;
  width: 100%;
  height: auto;
}
.shop-category > h3 {
  display: block;
  margin-top: 20px;
  font-size: 1.125rem;
}
@media screen and (max-width: 350px) {
  .shop-category > h3 {
    font-size: 0.9375rem;
  }
}
.shop-category > h3 a {
  color: #303239;
  text-transform: uppercase;
  transition: color 0.2s linear;
  font-weight: 700;
}
.shop-category > h3 a:hover {
  color: #e2001a;
}
.shop-category > h3 a i {
  color: #e2001a;
}

#Form_CheckoutForm #Form_CheckoutForm_action_goBackToEdit {
  float: left;
}
#Form_CheckoutForm #Form_CheckoutForm_action_doCheckout {
  float: right;
}

#Form_RegistrationForm_AcceptDataProcessing_Holder,
#Form_CheckoutForm_AcceptDataProcessing_Holder {
  position: relative;
  border-top: 1px solid #aaaaaa;
  padding-top: 40px;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder:after,
#Form_CheckoutForm_AcceptDataProcessing_Holder:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f14a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 1px;
  width: 1px;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder input,
#Form_CheckoutForm_AcceptDataProcessing_Holder input {
  position: absolute;
  top: 40px;
  left: -0.05em;
  display: block;
  opacity: 0 !important;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label,
#Form_CheckoutForm_AcceptDataProcessing_Holder label {
  margin-top: 0;
  color: #303239;
  text-transform: none;
  font-size: 0.9375rem;
  padding-left: 1.5em;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p {
  margin-top: 0;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label p, #Form_RegistrationForm_AcceptDataProcessing_Holder label p *,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p,
#Form_CheckoutForm_AcceptDataProcessing_Holder label p * {
  line-height: 1;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder label:before,
#Form_CheckoutForm_AcceptDataProcessing_Holder label:before {
  color: #e2001a;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0c8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-left: -1.5em;
  margin-right: 0;
  display: block;
  float: left;
}
#Form_RegistrationForm_AcceptDataProcessing_Holder input:checked + label:before,
#Form_CheckoutForm_AcceptDataProcessing_Holder input:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f14a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}

#Form_RegistrationForm_AcceptDataProcessing_Holder {
  position: relative;
  border-top: 0;
  padding-top: 0;
}

#Form_OrderSummaryForm_ShippingMethodID option[disabled] {
  display: none;
}

.two-fields-in-line > .middleColumn {
  width: 100% !important;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field {
  float: left;
  width: calc(50% - 10px);
  margin-top: 40px;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field:nth-of-type(-n+2) {
  margin-top: 0;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field:nth-of-type(2n+2) {
  margin-left: 20px;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field:nth-of-type(2n+2) .fieldholder-small label {
  width: 100px;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field:nth-of-type(2n+2) .fieldholder-small input {
  width: calc(100% - 100px);
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field:nth-of-type(2n+3) {
  clear: both;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field .fieldholder-small label {
  float: left;
  width: 150px;
  font-weight: 400;
  padding: 10px 0;
  line-height: 1.35em;
}
.two-fields-in-line > .fieldgroup > .fieldgroup-field .fieldholder-small input {
  float: left;
  width: calc(100% - 150px);
}

.fieldgroup .fieldholder-small-label {
  display: block;
  font-weight: 700;
  margin-bottom: 20px;
}

#Form_UpdateCartForm .middleColumn {
  width: 100%;
}
#Form_UpdateCartForm .field.checkbox {
  padding-left: 0;
}

#Form_RegistrationForm_Password_Holder > .middleColumn {
  width: 100%;
}

#Form_OrderSummaryForm .field > label.left,
#Form_OrderSummaryForm .field > legend.left {
  width: 180px;
}
#Form_OrderSummaryForm .middleColumn {
  width: calc(100% - 180px);
}
#Form_OrderSummaryForm .description {
  text-align: right;
}
#Form_OrderSummaryForm .description .button {
  margin-top: 0.5rem;
  font-weight: 700;
}

#MemberLoginForm_LoginForm_Remember_Holder,
#MemberLoginForm_LoginForm_Remember_Holder label,
#Form_CheckoutForm_AcceptDataProcessing_Holder,
#Form_CheckoutForm_AcceptDataProcessing_Holder label {
  padding-left: 0;
}

#Form_CheckoutForm_AcceptDataProcessing_Holder label {
  padding-left: 1.5em;
}

.main__text + *, .main__text + * + #Form_OrderSummaryForm {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .main__text + *, .main__text + * + #Form_OrderSummaryForm {
    margin-top: 20px;
  }
}

.shop-product-instock span {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0px;
  line-height: 1;
  padding: 6.6666666667px 10px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.shop-product-instock--soon span {
  background: #eabe17;
}
.shop-product-instock--in span {
  background: #9edd3c;
}
.shop-product-instock--soon2 span {
  background: #e2001a !important;
}
.shop-product-instock--out span {
  background: #303239;
}

.shop-one-time-messages + * {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .shop-one-time-messages + * {
    margin-top: 20px;
  }
}
.shop-one-time-messages .session-message:after {
  content: "";
  display: none;
}

.order-summary h3 {
  display: block;
  font-size: 1.125rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}
.order-summary__status {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #aaaaaa;
}
.order-summary__status ul {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  background: #e9e9e9;
}
@media screen and (max-width: 768px) {
  .order-summary__status ul {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .order-summary__status ul {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.order-summary__status ul li {
  float: left;
  width: 48.8636363636%;
  margin-right: 2.2727272727%;
  margin-top: 10px;
}
.order-summary__status ul li:nth-of-type(2n+2) {
  margin-right: 0;
}
.order-summary__status ul li:nth-of-type(-n+2) {
  margin-top: 0;
}
.order-summary__status ul li i {
  margin-right: 0.5em;
}
.order-summary__status ul li span:first-of-type {
  font-weight: 700;
}
.order-summary__addresses, .order-summary__payment-shipping {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #aaaaaa;
}
.order-summary__addresses > *, .order-summary__payment-shipping > * {
  float: left;
  width: 48.8636363636%;
}
.order-summary__addresses > *:first-of-type:not(:last-of-type), .order-summary__payment-shipping > *:first-of-type:not(:last-of-type) {
  margin-right: 2.2727272727%;
}
.order-summary__invoice-address--same-as-shipping {
  width: 100%;
}
.order-summary__paypal-link {
  display: inline-block;
  background-image: linear-gradient(100deg, #0070ba, #003087);
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0px;
  padding: 6.6666666667px 10px;
  color: #FFFFFF;
  line-height: 1;
}
.order-summary__paypal-link:before {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
  content: "\f1ed";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-right: 0.25em;
}
.order-summary__paypal-link:hover {
  background-image: linear-gradient(10deg, #0070ba, #003087);
  color: #FFFFFF;
}
.order-summary__member-note {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #aaaaaa;
}
.order-summary__coupon-code {
  color: #aaaaaa;
}
.order-summary__line-items {
  margin-bottom: 40px;
}
.order-summary__line-items > ul:first-of-type {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #aaaaaa;
}
.order-summary__line-items > ul:first-of-type li {
  display: block;
  float: left;
  font-weight: 700;
  margin-right: 20px;
}
.order-summary__line-items > ul:first-of-type li:first-of-type {
  width: calc(100% - 360px);
}
.order-summary__line-items > ul:first-of-type li:nth-of-type(2) {
  width: 125px;
}
.order-summary__line-items > ul:first-of-type li:nth-of-type(3) {
  width: 50px;
}
.order-summary__line-items > ul:first-of-type li:nth-of-type(4) {
  width: 125px;
  margin-right: 0;
  text-align: right;
}
.order-summary__line-items > ul:last-of-type {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px solid #aaaaaa;
}
.order-summary__line-items > ul:last-of-type li > strong {
  display: block;
  float: left;
}
.order-summary__line-items > ul:last-of-type li > span {
  display: block;
  float: right;
}
.order-summary__line-items > ul:last-of-type li > span > span {
  display: block;
  text-align: right;
}
.order-summary__line-items > ul:last-of-type li > span > span > span {
  display: block;
  float: left;
  text-align: right;
}
.order-summary__line-items > ul:last-of-type li > span > span > span:first-of-type {
  width: 5em;
  margin-right: 10px;
}
.order-summary__line-items > ul:last-of-type li > span > span > span:last-of-type {
  width: 125px;
}
.order-summary__line-items > ul:last-of-type li:nth-last-of-type(2),
.order-summary__line-items > ul:last-of-type li:nth-last-of-type(2) strong {
  font-weight: 700;
}
.order-summary__line-items > ul:last-of-type li:last-of-type {
  color: #aaaaaa;
  font-size: 0.75rem;
}
.order-summary__line-items .line-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #aaaaaa;
}
.order-summary__line-items .line-item:nth-last-of-type(2) {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.order-summary__line-items .line-item li {
  display: block;
  margin-right: 20px;
  float: left;
}
.order-summary__line-items .line-item li:first-of-type {
  width: calc(100% - 360px);
  font-weight: 700;
}
.order-summary__line-items .line-item li:nth-of-type(2) {
  width: 125px;
}
.order-summary__line-items .line-item li:nth-of-type(3) {
  width: 50px;
}
.order-summary__line-items .line-item li:nth-of-type(4) {
  width: 125px;
  margin-right: 0;
  text-align: right;
}

.placed-orders > ul:first-of-type {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #aaaaaa;
}
.placed-orders > ul:first-of-type li {
  font-weight: 700;
}
.placed-orders > ul:not(:first-of-type) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #aaaaaa;
}
.placed-orders > ul:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.placed-orders > ul li {
  display: block;
  float: left;
  margin-right: 20px;
  text-align: right;
}
.placed-orders > ul li:last-of-type {
  margin-right: 0;
}
.placed-orders__nr {
  width: calc(100% - 570px);
  text-align: left !important;
}
.placed-orders__items {
  width: 100px;
  text-align: center !important;
}
.placed-orders__total {
  width: 100px;
}
.placed-orders__date {
  width: 170px;
  padding-right: 20px;
}
.placed-orders__payed {
  width: 50px;
  text-align: center !important;
}
.placed-orders__payed--unpaid {
  color: #dd3c3c;
}
.placed-orders__dispatched {
  width: 50px;
  text-align: center !important;
  margin-right: 0;
}

.shop-product > a {
  border: 1px solid #aaaaaa;
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none !important;
}
.shop-product > a:not([href="javascript:void(0);"]) {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.shop-product > a:not([href="javascript:void(0);"]):after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.shop-product > a:not([href="javascript:void(0);"]):before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.shop-product > a:not([href="javascript:void(0);"]):hover:before, .shop-product > a:not([href="javascript:void(0);"]):hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.shop-product > a:not([href="javascript:void(0);"]):hover:before {
  left: -100px;
}
.shop-product > a[href="javascript:void(0);"] {
  cursor: default;
}
.shop-product > a img {
  display: block;
  width: 100%;
  height: auto;
}
.shop-product > h3 {
  display: block;
  margin-top: 20px;
  font-size: 1.125rem;
}
@media screen and (max-width: 350px) {
  .shop-product > h3 {
    font-size: 0.9375rem;
  }
}
.shop-product > h3 a {
  color: #303239;
  text-transform: uppercase;
  transition: color 0.2s linear;
  font-weight: 700;
}
.shop-product > h3 a:hover {
  color: #e2001a;
}
.shop-product > h3 a i {
  color: #e2001a;
}
.shop-product > h3 a[href="javascript:void(0);"] {
  cursor: default;
}
.shop-product > h3 a[href="javascript:void(0);"]:hover {
  color: #303239;
}
.shop-product .shop-product-instock {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 5;
}

.shop-products__headline {
  font-family: "Raleway", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  color: #303239;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .shop-products__headline {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 350px) {
  body:not(.has-sidebar) .shop-products .shop-product {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  body:not(.has-sidebar) .shop-products .shop-product {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  body:not(.has-sidebar) .shop-products .shop-product {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body:not(.has-sidebar) .shop-products .shop-product:nth-of-type(3n+4) {
    clear: both;
  }
}

@media screen and (max-width: 350px) {
  body.has-sidebar .shop-products .shop-product {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  body.has-sidebar .shop-products .shop-product {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  body.has-sidebar .shop-products .shop-product {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  body.has-sidebar .shop-products .shop-product {
    float: left;
    width: 31.2977099237%;
    margin-right: 3.0534351145%;
    margin-top: 3.0534351145%;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  body.has-sidebar .shop-products .shop-product:nth-of-type(3n+4) {
    clear: both;
  }
}

.shop-account-button nav {
  display: none !important;
}
.shop-account-button i, .shop-account-button + a i {
  margin-right: 0.5em;
}

.shop-global-notice + * {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .shop-global-notice + * {
    margin-top: 20px;
  }
}

.shop-tabs__menu {
  float: left;
}
.shop-tabs__menu ul li {
  border-bottom: 1px solid #aaaaaa;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.2s linear;
}
.shop-tabs__menu ul li:hover {
  background: #e9e9e9;
  padding-left: 10px;
}
.shop-tabs__menu ul li:first-of-type {
  border-top: 1px solid #aaaaaa;
}
.shop-tabs__menu ul li.shop-tabs--active {
  font-weight: 700;
}
.shop-tabs__content {
  float: left;
  padding-left: 40px;
  border-left: 1px solid #aaaaaa;
}
.shop-tabs__content > div {
  display: none;
}
.shop-tabs__content > div.shop-tabs--active {
  display: block;
}

@media screen and (min-width: 1037px) {
  body.has-sidebar .shop-tabs__menu {
    width: 31.2977099237%;
    margin-right: 3.0534351145%;
  }
  body.has-sidebar .shop-tabs__content {
    width: 65.6488549618%;
  }
}
@media screen and (max-width: 1036px) {
  body.has-sidebar .shop-tabs__menu {
    width: 23.2954545455%;
    margin-right: 2.2727272727%;
  }
  body.has-sidebar .shop-tabs__content {
    width: 74.4318181818%;
  }
}

body:not(.has-sidebar) .shop-tabs__menu {
  width: 23.2954545455%;
  margin-right: 2.2727272727%;
}
body:not(.has-sidebar) .shop-tabs__content {
  width: 74.4318181818%;
}

#grasenhiller_accountpage #Form_ProfileEditForm {
  margin-bottom: 40px;
}
#grasenhiller_accountpage .account > h3, #grasenhiller_accountpage .account__addresses > h3 {
  font-size: 1.125rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}
#grasenhiller_accountpage .account__addresses {
  margin-bottom: 40px;
}
#grasenhiller_accountpage .account__addresses .address {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #aaaaaa;
}
#grasenhiller_accountpage .account__addresses .address:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
html .basic-holder .basic-holder__text + .basic-holder__children {
  margin-top: 20px;
  margin-top: calc(20px + 0.25em);
}
@media screen and (max-width: 480px) {
  html .basic-holder__child {
    float: left;
    width: 100%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  html .basic-holder__child:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  html .basic-holder__child:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  html .basic-holder__child:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 1036px) {
  html .basic-holder__child {
    float: left;
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  html .basic-holder__child:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  html .basic-holder__child:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  html .basic-holder__child:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  html .basic-holder__child {
    float: left;
    width: 31.8181818182%;
    margin-right: 2.2727272727%;
    margin-top: 2.2727272727%;
  }
  html .basic-holder__child:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  html .basic-holder__child:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  html .basic-holder__child:nth-of-type(3n+4) {
    clear: both;
  }
}
html .basic-holder__child:not(.basic-holder__child--no-link) .basic-holder__child-preview-image a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
html .basic-holder__child:not(.basic-holder__child--no-link) .basic-holder__child-preview-image a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
html .basic-holder__child:not(.basic-holder__child--no-link) .basic-holder__child-preview-image a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
html .basic-holder__child:not(.basic-holder__child--no-link) .basic-holder__child-preview-image a:hover:before, html .basic-holder__child:not(.basic-holder__child--no-link) .basic-holder__child-preview-image a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
html .basic-holder__child:not(.basic-holder__child--no-link) .basic-holder__child-preview-image a:hover:before {
  left: -100px;
}
html .basic-holder__child--no-link div a,
html .basic-holder__child--no-link h3 a {
  cursor: default;
}
html .basic-holder__child--no-link div a .fa-arrow-right,
html .basic-holder__child--no-link h3 a .fa-arrow-right {
  display: none;
}
html .basic-holder__child--no-link div a:hover,
html .basic-holder__child--no-link h3 a:hover {
  color: #303239 !important;
}
html .basic-holder__child-preview-image {
  border: 1px solid #aaaaaa;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child-preview-image {
    margin-bottom: 10px;
  }
}
html .basic-holder__child > h3 {
  display: block;
  margin-bottom: 20px;
  font-size: 1.125rem;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child > h3 {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 350px) {
  html .basic-holder__child > h3 {
    font-size: 0.9375rem;
  }
}
html .basic-holder__child > h3 a {
  color: #303239;
  text-transform: uppercase;
  transition: color 0.2s linear;
  font-weight: 700;
}
html .basic-holder__child > h3 a:hover {
  color: #e2001a;
}
html .basic-holder__child > h3 a i {
  color: #e2001a;
}
html .basic-holder__child > p {
  margin: 0;
}

#app_carpage .request-button {
  color: #e2001a;
  border-color: #e2001a;
  float: right;
  margin-top: -22.8571428571px;
}
#app_carpage .request-button:hover {
  color: #af0014;
  border-color: #af0014;
}
@media screen and (max-width: 480px) {
  #app_carpage .request-button {
    float: none;
    margin-top: 20px;
  }
}
#app_carpage .main__text p {
  column-count: 2;
  column-gap: 40px;
}
@media screen and (max-width: 480px) {
  #app_carpage .main__text p {
    column-count: 1;
    column-gap: 0;
  }
}

#grasenhiller_categorypage .shop-categories + .shop-products {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_categorypage .shop-categories + .shop-products {
    margin-top: 20px;
  }
}

#grasenhiller_checkoutpage #Form_UpdateCartForm {
  float: left;
  width: 48.8636363636%;
  margin-right: 2.2727272727%;
}
#grasenhiller_checkoutpage #Form_OrderSummaryForm {
  float: left;
  width: 48.8636363636%;
}

.page--3 .main__text {
  padding-left: 120px !important;
}
@media screen and (max-width: 1840px) {
  .page--3 .main__text {
    padding-left: 80px !important;
  }
}
@media screen and (max-width: 480px) {
  .page--3 .main__text {
    padding-left: 40px !important;
  }
}
.page--3 .main__text p {
  overflow: visible;
}
.page--3 .main__text .text--big {
  position: relative;
}
@media screen and (max-width: 480px) {
  .page--3 .main__text .text--big {
    font-size: 1.125rem;
    font-weight: 400;
  }
}
.page--3 .main__text .text--big:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f3c5";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  position: absolute;
  top: 0.125em;
  left: -40px;
  transform: translateX(-100%);
  width: auto !important;
}
@media screen and (max-width: 1840px) {
  .page--3 .main__text .text--big:before {
    left: -20px;
  }
}
@media screen and (max-width: 480px) {
  .page--3 .main__text .text--big:before {
    position: static;
    transform: none;
    margin-right: 10px;
  }
}

.video {
  background: #000000;
}
.video i.fa-youtube {
  font-size: 3.125rem;
  color: #ffffff;
  transition: 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.video:hover i.fa-youtube {
  color: #e2001a;
}
.video video {
  width: 100%;
  height: auto;
  max-height: 850px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .video video {
    max-height: 100%;
  }
}

.login-page h3 {
  font-size: 1.125rem;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin-bottom: 40px;
}
.login-page form {
  width: 100% !important;
}
.login-page__login {
  float: left;
  width: 31.8181818182%;
  margin-right: 2.2727272727%;
}
.login-page__registration {
  float: left;
  width: 65.9090909091%;
}

.news-preview {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .news-preview {
    margin-bottom: 20px;
  }
}
.news-preview:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .news-preview__image {
    width: 31.8181818182%;
    float: left;
    margin-right: 2.2727272727%;
  }
  .news-preview__text {
    width: 65.9090909091%;
    float: left;
  }
}
@media screen and (max-width: 768px) {
  .news-preview__image {
    margin-bottom: 20px;
  }
}
.news-preview__image a {
  display: block;
}
.news-preview__image a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.news-preview__image img {
  display: block;
  width: 100%;
  height: auto;
}
.news-preview__text > h2 {
  margin-bottom: 0;
}
.news-preview__text > h2 a {
  color: #303239;
}
.news-preview__text > h2 a:hover {
  color: #e2001a;
}
.news-preview__text > small {
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .news-preview__text > small {
    margin-bottom: 10px;
  }
}
.news-preview__text > p:last-of-type {
  margin-bottom: 0;
}
.news-preview__text > a {
  display: inline-block;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .news-preview__text > a {
    margin-top: 10px;
  }
}

#grasenhiller_newspage .page-slider {
  margin-bottom: 20px;
}
#grasenhiller_newspage .page-title {
  margin-bottom: 0;
}
#grasenhiller_newspage .page-title h1 {
  float: none;
}
#grasenhiller_newspage main .page-title {
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_newspage main .page-title {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 480px) {
  #grasenhiller_newspage main .page-title {
    margin-bottom: 20px;
  }
}

.news-page__inner {
  margin: 0 auto;
  max-width: 1160px;
}
.news-page__inner .page-title {
  margin-bottom: 40px;
}
@media screen and (max-width: 480px) {
  .news-page__inner .page-title {
    margin-bottom: 20px;
  }
}
.news-page__preview-text {
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .news-page__preview-text {
    margin-bottom: 20px;
  }
}
.news-page__preview-text p {
  font-size: 1.125rem;
}
.news-page__text .news-page__image {
  display: block;
  width: 31.8181818182%;
  float: right;
  margin-left: 20px;
  margin-bottom: 10px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.news-page__text .news-page__image:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.news-page__text .news-page__image:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.news-page__text .news-page__image:hover:before, .news-page__text .news-page__image:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.news-page__text .news-page__image:hover:before {
  left: -100px;
}
.news-page__text .news-page__image img {
  display: block;
  width: 100%;
  height: auto;
}
.news-page__meta {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #aaaaaa;
  color: #aaaaaa;
  font-size: 0.75rem;
}
.news-page__meta a {
  color: #aaaaaa;
  text-decoration: none;
}
.news-page__meta a:hover {
  color: #e2001a;
}
.news-page__meta ul {
  margin: 10px 0 0 0;
  padding: 0;
}
.news-page__meta ul li {
  display: block;
}
.news-page__meta ul li.news-page__prev {
  float: left;
}
.news-page__meta ul li.news-page__prev a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f104";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  position: relative;
  bottom: -1px;
}
.news-page__meta ul li.news-page__next {
  float: right;
}
.news-page__meta ul li.news-page__next a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  position: relative;
  bottom: -1px;
}

.page-inner {
  margin: 0 auto;
  max-width: 1160px;
}
.page-inner .page-title {
  margin-bottom: 40px;
}

#app_productpage .product-price {
  font-weight: 700;
  font-size: 1.125rem;
  margin-top: -0.25em;
  margin-bottom: 10px;
}
#app_productpage .userform {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  #app_productpage .userform {
    max-width: 1160px;
  }
}
#app_productpage .userform .checkbox label {
  font-weight: 700;
}

.product-page__variant-imgs {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
.product-page__bottom {
  margin-top: 40px;
}
@media screen and (max-width: 768px) {
  .product-page__bottom {
    margin-top: 20px;
  }
}
.product-page__left {
  float: left;
}
.product-page__left a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
  display: block;
}
.product-page__left a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -80px;
  bottom: 0;
  z-index: 10;
  color: #e2001a;
  background: rgba(0, 0, 0, 0.75);
  text-align: right;
  padding: 20px 24px 0 0;
  font-size: 1.125rem;
  width: 100%;
  transition: all 0.2s linear;
}
.product-page__left a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 230px;
  position: absolute;
  top: 0;
  left: -280px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s linear;
  z-index: 20;
  transform: skewX(-15deg);
}
.product-page__left a:hover:before, .product-page__left a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.product-page__left a:hover:before {
  left: -100px;
}
.product-page__left a img {
  display: block;
  width: 100%;
  height: auto;
}
.product-page__left > a {
  border: 1px solid #aaaaaa;
}
.product-page__left ul li {
  border: 1px solid #aaaaaa;
  display: block;
  float: left;
  margin-top: 20px;
}
.product-page__left ul li:nth-of-type(4n+4) {
  margin-right: 0 !important;
  float: right;
}
.product-page__right {
  float: right;
}
.product-page__price .product-price__value {
  font-size: 1.125rem;
  font-weight: 700;
}
.product-page__price .product-price__explanation {
  font-style: italic;
  font-size: 0.75rem;
  color: #aaaaaa;
}
.product-page__vat {
  font-style: italic;
  font-size: 0.75rem;
  color: #aaaaaa;
}
.product-page .shop-product-instock {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .product-page .shop-product-instock {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.product-page .shop-product-instock--in span {
  background: #9edd3c;
}
.product-page .shop-product-instock--soon span {
  background: #eabe17;
}
.product-page .shop-product-instock--out span {
  background: #dd3c3c;
}
.product-page .shop-tabs {
  margin-top: 40px;
}

@media screen and (min-width: 1037px) {
  body.has-sidebar .product-page__left {
    width: 31.2977099237%;
    margin-right: 3.0534351145%;
  }
  body.has-sidebar .product-page__left ul li {
    width: 30.081300813%;
    margin-right: 4.8780487805%;
  }
  body.has-sidebar .product-page__right {
    width: 65.6488549618%;
  }
}
@media screen and (max-width: 1036px) {
  body.has-sidebar .product-page__left {
    width: 48.8636363636%;
    margin-right: 2.2727272727%;
  }
  body.has-sidebar .product-page__left ul li {
    width: 31.7829457364%;
    margin-right: 2.3255813953%;
  }
  body.has-sidebar .product-page__right {
    width: 48.8636363636%;
  }
}

body:not(.has-sidebar) .product-page__left {
  width: 31.8181818182%;
  margin-right: 2.2727272727%;
}
body:not(.has-sidebar) .product-page__left ul li {
  width: 22.2290640394%;
  margin-right: 3.4482758621%;
}
body:not(.has-sidebar) .product-page__right {
  width: 65.9090909091%;
}
@media screen and (max-width: 768px) {
  body:not(.has-sidebar) .product-page__left {
    width: 100%;
  }
  body:not(.has-sidebar) .product-page__right {
    width: 100%;
    margin-top: 40px;
  }
}

#silverstripe_userdefinedform .main__text {
  padding: 40px 80px;
  background: #e9e9e9;
}
#silverstripe_userdefinedform .main__text:not(.main__text--fullscreen) {
  float: right;
  width: 48.8636363636%;
  margin-left: 2.2727272727%;
}
@media screen and (max-width: 768px) {
  #silverstripe_userdefinedform .main__text:not(.main__text--fullscreen) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 350px) {
  #silverstripe_userdefinedform .main__text:not(.main__text--fullscreen) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1840px) {
  #silverstripe_userdefinedform .main__text {
    padding-right: 40px;
  }
}
#silverstripe_userdefinedform .main .userform {
  float: right;
  width: 48.8636363636%;
  clear: none;
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  #silverstripe_userdefinedform .main .userform {
    width: 100%;
    float: none;
  }
}