// - Susy Grid
$max-width: 1760px;
$inner-max-width: 1160px;
$popup-width: 1460px;
$column-width: 110px;
$gutter-width: 40px;
$columns: 12;

// - Sizes
$dist: $gutter-width;
$mobilenav-width: 290px;
$radius: 0px;
$duration: 0.2s;

// - Text & Highlight Colors
$fc: #303239;
$fc-light: #aaaaaa;
$hc: #303239;
$hc-hover: darken($hc, 10%);
$ac: #e2001a;
$ac-hover: darken($ac, 10%);
$ac-sec: #303239;
$ac-sec-hover: darken($ac-sec, 10%);

// - Border & Box Colors
$border-color: #aaaaaa;
$border-color-dark: darken($border-color, 10%);
$box-color: #e9e9e9;
$box-color-dark: #303239;
$page-bg: #ffffff;

// - Misc
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-hover: rgba(0, 0, 0, 0.35);
$valid-color: #9edd3c;
$warning-color: #eabe17;
$invalid-color: #dd3c3c;

// - Font Family
$ff: 'Raleway', sans-serif;
$ff-h: $ff;

// - Font Sizes
$line-height-int: 1.35;
$line-height-big-int: 1.5;
$fs-root-int: 16;
$fs-int: 15;
$fs-small-int: 12;
$fs-smaller-int: 9;
$fs-h1-int: 50;
$fs-h2-int: 30;
$fs-h3-int: 18;
$fs-h4-int: 18;
$fs-h5-int: 18;
$fs-h6-int: 15;

// - Font Weights
$fw-light: 300;
$fw: 400;
$fw-medium: 700;
$fw-bold: 700;
$fw-h-light: 300;
$fw-h: 400;
$fw-h-medium: 700;
$fw-h-bold: 700;

// - Breakpoints
$bp-xs: 350px;
$bp-s: 480px;
$bp-m: 768px;
$bp-l: 1036px;
$bp-xl: $max-width + $dist*2;
$bp-nav: 768px;

// ------
// - You don't need to touch those variables below.
// - They are automatically configured with the values above
// ------

$susy: (
	'columns': susy-repeat($columns),
	'gutters': $gutter-width/$column-width,
	'spread': 'narrow',
	'container-spread': 'narrow',
);

$line-height: $line-height-int + em;
$line-height-big: $line-height-big-int + em;
$fs-root: $fs-root-int + px;
$fs-small: $fs-small-int / $fs-root-int + rem;
$fs-smaller: $fs-smaller-int / $fs-root-int + rem;
$fs: $fs-int / $fs-root-int + rem;
$fs-h1: $fs-h1-int / $fs-root-int + rem;
$fs-h2: $fs-h2-int / $fs-root-int + rem;
$fs-h3: $fs-h3-int / $fs-root-int + rem;
$fs-h4: $fs-h4-int / $fs-root-int + rem;
$fs-h5: $fs-h5-int / $fs-root-int + rem;
$fs-h6: $fs-h6-int / $fs-root-int + rem;
