.shop-category {
	& > a {
		@include image-hover($fa-var-arrow-right);

		img {
			@include resp-img;
		}
	}

	& > h3 {
		display: block;
		margin-top: $dist/2;
		font-size: $fs-h3;

		@include breakpoint(null, $bp-xs) {
			font-size: $fs-h6;
		}

		a {
			color: $fc;
			text-transform: uppercase;
			transition: color $duration linear;
			font-weight: $fw-medium;

			&:hover {
				color: $ac;
			}

			i {
				color: $ac;
			}
		}
	}
}