#Form_FilterForm {
	float: right;
	width: $dist*6;
	margin-top: -$dist/1.75;

	@include breakpoint(null, $bp-s) {
		float: none;
		margin-top: $dist/2;
	}

	#Form_FilterForm_brand_Holder {
		margin-bottom: 0;

		.middleColumn {
			width: 100%;

			select {
				border-radius: $dist/2;
				padding-left: $dist/2;
				padding-right: $dist/2;
				border-color: $fc;
			}
		}
	}

	.btn-toolbar {
		display: none;
	}
}