#grasenhiller_checkoutpage {
	.main {
		@extend %cf;
	}

	#Form_UpdateCartForm {
		float: left;
		width: span(6 of 12);
		margin-right: gutter(12);
	}
	
	#Form_OrderSummaryForm {
		float: left;
		width: span(6 of 12);
	}
}