.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: $dist/2;
  bottom: $dist/2;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background: $ac;
  color: #ffffff !important;
  border-radius: $radius;

  &:hover {
    background: $ac-hover;
  }

  i {
    &:before {
      position: relative;
      top: -1px;
    }
  }
}